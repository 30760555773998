import React from 'react';
import moment from 'moment';
import logo from '../../images/wealth-club-portal-logo.svg';

function Footer() {
  return (
    <div
      className="w-full min-h-[85px] h-auto bg-tuxedo bottom-0 flex flex-col pb-6"
      data-testid="footer">
      <div className="flex flex-row text-center align-middle items-center content-center ml-5 p-3">
        <img src={logo} alt="WealthClub logo" className="h-6" />
      </div>
      <div>
        <div className="flex flex-row ml-9 align-middle items-center justify-between w-[90%]">
          <div className="text-white font-normal text-xs w-[87%] md:w-[70%]">
            Copyright © Wealth Club {moment().year()}. Wealth Club Limited is authorised and regulated by the Financial Conduct Authority, register number 725176. Registered Office: 20 Richmond Hill, Bristol, BS8 1BA. United Kingdom. Registered in England and Wales, number 09831162
          </div>
          <div className="text-white font-normal text-xs underline hover:cursor-pointer md:ml-7" onClick={event => {
            const newWindow = window.open('https://www.wealthclub.co.uk/documents/terms-and-conditions.pdf', '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
          }}>
            Terms and Conditions
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
