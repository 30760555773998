import React from 'react';
import Slider from '../Inputs/Slider';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import LoadingBlock from '../Loading/LoadingBlock';
import ErrorBanner from '../Error/ErrorBanner';
import useToken from '../../hooks/useToken';
import { isDisabledForViewAs } from '../../utils/HelperUtils';


export interface InterestsVal {
  name: string;
  value: boolean;
}


export interface InterestsFormProps {
  data: Map<string, boolean> | undefined,
  isLoading: boolean;
  isError: boolean;
  mutation: UseMutationResult<Map<string, boolean>, unknown, InterestsVal, unknown>
}

function InterestsForm(props:InterestsFormProps) {

  const auth = useToken()

  const getSlidersToRender = (categories: Map<string, boolean>) => {
    const sliders = [] as React.ReactElement[];

    // we need to sort the keys to maintain order
    let keys = [] as string[];
    categories.forEach((value, key) => {
      keys.push(key);
    });

    keys = keys.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    if (keys) {
      keys.forEach((key) => {
        const mapVal = categories.get(key);
        sliders.push(
          <div key={key}>
            <Slider
              disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
              active={mapVal!}
              title={key}
              onclick={() => {
                const val = { name: key, value: !mapVal } as InterestsVal;
                props.mutation.mutate(val)
              }}
            />
          </div>,
        );

      });
    }
    return sliders;
  };

  if (props.isLoading) {
    return <LoadingBlock />;
  }

  if (props.isError) {
    return <ErrorBanner text={'An error occurred. Please try again.'} severe={true} />;
  }

  if (!props.data) {
    return null;
  }


  return (
    <div className="w-full h-full flex flex-col">
      <h5 className="text-sm">Which subjects would you like to hear about?</h5>
      {getSlidersToRender(props.data).map(value => {
        return value;
      })}
    </div>


  );
}


export default InterestsForm;
