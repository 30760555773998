import api from './api';
import { getApiBaseUrl } from '../utils/HelperUtils';
import { ContactFrequencyPreferences, ContactPreferences } from '../dto/ContactPreferences';
import moment from 'moment/moment';
import { OptOutSnooze } from '../components/ContactDetails/Mut';

interface IPreferenceService {


  // new services
  getProductInterests(): Promise<Map<string, boolean>>;

  updateInterests(data: Map<string, boolean>): Promise<Map<string, boolean>>;

  getContactPreferences(): Promise<ContactPreferences>;

  updateContactPreferences(contactPreferences: ContactPreferences): Promise<ContactPreferences>;

  getContactFrequency(): Promise<ContactFrequencyPreferences>;

  updateContactFrequency(data: ContactFrequencyPreferences): Promise<ContactFrequencyPreferences>;

  getTempOptOut(): Promise<OptOutSnooze>;

  updateTempOptOut(date: Date): Promise<void>;

}

export class PreferenceService implements IPreferenceService {
  getProductInterests(): Promise<Map<string, boolean>> {
    return new Promise((resolve, reject) => {
      api
        .get('/prefs/interests')
        .then((res) => {
          const results = new Map(Object.entries(res.data)) as Map<string, boolean>;
          resolve(results);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateInterests(data: Map<string, boolean>): Promise<Map<string, boolean>> {
    const requestData = JSON.stringify(Object.fromEntries(data));
    return new Promise((resolve, reject) => {
      api
        .put(getApiBaseUrl() + '/prefs/interests', requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          const results = new Map(Object.entries(res.data)) as Map<string, boolean>;
          resolve(results);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getContactPreferences(): Promise<ContactPreferences> {
    return new Promise((resolve, reject) => {
      api
        .get('/prefs/contact')
        .then((res) => {
          const result = res.data as ContactPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateContactPreferences(contactPreferences: ContactPreferences): Promise<ContactPreferences> {
    return new Promise((resolve, reject) => {
      api
        .patch(getApiBaseUrl() + '/prefs/contact', contactPreferences, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          const result = res.data as ContactPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getContactFrequency(): Promise<ContactFrequencyPreferences> {
    return new Promise((resolve, reject) => {
      api
        .get('/prefs/contact/freq')
        .then((res) => {
          const result = res.data as ContactFrequencyPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateContactFrequency(data: ContactFrequencyPreferences): Promise<ContactFrequencyPreferences> {
    return new Promise((resolve, reject) => {
      api
        .patch(getApiBaseUrl() + '/prefs/contact/freq', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          const result = res.data as ContactFrequencyPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getTempOptOut(): Promise<OptOutSnooze> {
    return new Promise((resolve, reject) => {
      api
        .get('/prefs/opt')
        .then((res) => {
          const mut = { snooze: null } as OptOutSnooze;
          if (res.data['tempOptOut'] === null) {
            resolve(mut);
            return
          }
          const date = moment(res.data['tempOptOut'], 'YYYY-MM-DD').toDate();
          mut.snooze = date;
          resolve(mut);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateTempOptOut(date: Date | null): Promise<void> {
    return new Promise((resolve, reject) => {
      api
        .post(getApiBaseUrl() + '/prefs/opt', { 'tempOptOut': date ? moment(date).format('YYYY-MM-DD') : null }, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          resolve();
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}
