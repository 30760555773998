import React, { useState } from 'react';
import LoginInput from '../Inputs/Input';
import ButtonComponent from '../Buttons/Button';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useFormik } from 'formik';
import ErrorMessage from '../../errors/ErrorMessage';
import { passwordRegexValidator } from '../../utils/HelperUtils';
import * as yup from 'yup';
import useToken from '../../hooks/useToken';
import { Button } from 'private-wc-ui';
import PasswordRequirement from '../Passwords/PasswordRequirement';
import { passwordRules } from '../../constants/Constants';
import PasswordInput from '../Inputs/PasswordInput';

interface PasswordNotification {
  message: string;
  isError: boolean;
  isDisplayed: boolean;
}

interface FormProps {
  setNotification: (prevState: PasswordNotification) => void;
}

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}


function PasswordForm(props: FormProps) {
  const { authService } = useDependencyInjector();
  const [isLoading, setLoading] = useState<boolean>(false);

  const auth = useToken();

  const isDisabledForViewAs = (): boolean => {
    return (auth.token != null && auth.token.as != undefined);
  };


  const initialValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  function isErrorMessage(obj: any): obj is ErrorMessage {
    return (
      typeof obj.status === 'number' &&
      typeof obj.error === 'string' &&
      typeof obj.timeStamp === 'string'
    );
  }


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      oldPassword: yup.string().required('Your old password is required'),
      newPassword: yup.string().matches(passwordRules),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const { oldPassword, newPassword } = values as FormValues;
      try {
        props.setNotification({
          isDisplayed: false,
          isError: false,
          message: '',
        } as PasswordNotification);
        await authService.changePassword(oldPassword, newPassword);
        props.setNotification({
          isDisplayed: true,
          isError: false,
          message: 'Your password has been successfully updated.',
        } as PasswordNotification);
        formik.resetForm();
      } catch (e) {
        if (isErrorMessage(e)) {
          const err = e as ErrorMessage;
          formik.setFieldError('oldPassword', err.error);
          props.setNotification({
            isDisplayed: true,
            isError: true,
            message: err.error,
          } as PasswordNotification);

        } else {
          props.setNotification({
            isDisplayed: true,
            isError: true,
            message:
              'An error occurred while updating your password. Please try again.',
          } as PasswordNotification);
        }
      }
      setLoading(false);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col md:w-[45%] w-full mt-6 md:mt-10">
        <label
          className="font-sans font-bold text-tuxedo text-[14px]"
          htmlFor="oldPassword">
          Current password
        </label>
        <PasswordInput
          type={'password'}
          name={'oldPassword'}
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs()}
          error={
            formik.errors.oldPassword != undefined &&
            formik.errors.oldPassword.length != 0 &&
            formik.touched.oldPassword
          }
          id={'username'}></PasswordInput>
        {formik.errors.oldPassword && formik.touched.oldPassword ? (
          <div style={{ color: 'red' }}>{formik.errors.oldPassword}</div>
        ) : null}
      </div>

      <div className="mt-5 flex flex-col md:w-[45%] w-full">
        <label
          className="font-sans font-bold text-tuxedo text-[14px]"
          htmlFor="newPassword">
          New password
        </label>
        <PasswordInput
          type={'password'}
          name={'newPassword'}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs()}
          error={
            formik.errors.newPassword != undefined &&
            formik.errors.newPassword.length != 0 &&
            formik.touched.newPassword
          }
          id={'username'}></PasswordInput>
        {formik.errors.newPassword && formik.touched.newPassword ? (
          <div style={{ color: 'red' }}>{formik.errors.newPassword}</div>
        ) : null}
        <PasswordRequirement password={formik.values.newPassword} htmlFor={'newPassword'} />
      </div>
      <div className="mt-5 flex flex-col md:w-[45%] w-full">
        <label
          className="font-sans font-bold text-tuxedo text-[14px]"
          htmlFor="newPassword2">
          Confirm password
        </label>
        <PasswordInput
          type={'password'}
          name={'confirmPassword'}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs()}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.errors.confirmPassword != undefined &&
            formik.errors.confirmPassword.length != 0 &&
            formik.touched.confirmPassword
          }
          id={'username'}></PasswordInput>
        {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
          <div style={{ color: 'red' }}>{formik.errors.confirmPassword}</div>
        ) : null}
      </div>
      <div className="mt-5 md:w-[45%] w-full">
        <Button
          style={{ width: '100%' }}
          label="confirm change"
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs() || isLoading}
          theme={'primary'}
          type={'submit'}
        />
      </div>
    </form>
  );
}

export default PasswordForm;
