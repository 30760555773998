import {ClientSearch} from "../dto/ClientSearch";
import api from "./api";

interface IAdminService {
    searchClients(search: string): Promise<Array<ClientSearch>>
}

export class AdminService implements IAdminService {
    searchClients(search: string): Promise<Array<ClientSearch>> {
        return new Promise((resolve, reject) => {
            api.get("/admin/search?clientName=" + encodeURI(search)).then(res => {
                const response = res.data as Array<ClientSearch>
                resolve(response)
            }).catch(reason => {
                reject(reason)
            })
        })
    }
}