import api from './api';
import { Transaction } from '../dto/Transaction';
import BankDetails, { BankType } from '../dto/BankDetails';
import { Address, AddressUpdate } from '../dto/Address';
import { ClientSearch } from '../dto/ClientSearch';
import axios, { AxiosError } from 'axios';
import { ContactDetails } from '../dto/ContactDetails';
import moment from 'moment';
import { date, string } from 'yup';
import { reject } from 'lodash';
import { getApiBaseUrl } from '../utils/HelperUtils';

interface IClientService {
  getTransactionMinDate(): Promise<Date>;

  getAllTransactions(
    startDate: string,
    endDate: string,
  ): Promise<Array<Transaction>>;

  getBankDetails(bankType: BankType): Promise<BankDetails>;

  updateBankDetails(
    bankDetails: BankDetails,
    bankType: BankType,
  ): Promise<BankDetails>;

  updateAddress(address: AddressUpdate): Promise<void>;

  getAddress(): Promise<Address>;

  getViewableClients(): Promise<Array<ClientSearch>>;

  getContactDetails(): Promise<ContactDetails>;

  updateContactDetails(contactDetails: ContactDetails): Promise<void>;

  getClientNum(): Promise<string>;

  getClientFormalName(wcid: string): Promise<string>;
}

class ClientService implements IClientService {
  getAllTransactions(
    startDate: string,
    endDate: string,
  ): Promise<Array<Transaction>> {
    return new Promise((resolve, reject) => {
      api
        .get(
          '/client/transactions?startDate=' + startDate + '&endDate=' + endDate,
        )
        .then((value) => {
          resolve(value.data as Array<Transaction>);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getTransactionMinDate(): Promise<Date> {
    return new Promise((resolve, reject) => {
      api
        .get('/client/transactions/minDate')
        .then((value) => {
          if (value.data['minDate']) {
            const date = moment(
              value.data['minDate'],
              'YYYY-MM-dd HH:MM:SS',
            ).toDate();
            resolve(date);
          }
          resolve(new Date());
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getBankDetails(bankType: BankType): Promise<BankDetails> {
    return new Promise((resolve, reject) => {
      api
        .get('/client/bank/' + bankType)
        .then((value) => {
          resolve(value.data as BankDetails);
        })
        .catch((reason) => {
          reject(reason as AxiosError);
        });
    });
  }

  updateBankDetails(
    bankDetails: BankDetails,
    bankType: BankType,
  ): Promise<BankDetails> {
    return new Promise<BankDetails>((resolve, reject) => {
      api
        .post('/client/bank/' + bankType, bankDetails)
        .then((value) => {
          resolve(value.data as BankDetails);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getAddress(): Promise<Address> {
    return new Promise((resolve, reject) => {
      api
        .get('/client/address')
        .then((value) => {
          resolve(value.data as Address);
        })
        .catch((reason) => {
          reject(reason as AxiosError);
        });
    });
  }

  getViewableClients(): Promise<Array<ClientSearch>> {
    return new Promise((resolve, reject) => {
      api
        .get('/client/associated')
        .then((value) => {
          resolve(value.data as Array<ClientSearch>);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateAddress(address: AddressUpdate): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      api
        .post('/client/address', address)
        .then((value) => {
          resolve();
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getContactDetails(): Promise<ContactDetails> {
    return new Promise((resolve, reject) => {
      api
        .get('/client/contact-details')
        .then((value) => {
          resolve(value.data as ContactDetails);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateContactDetails(contactDetails: ContactDetails): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      api
        .post('/client/contact-details', contactDetails)
        .then((value) => {
          resolve();
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getClientNum(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      api
        .get('/client')
        .then((value) => {
          resolve(value.data['clientNum']);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getClientFormalName(wcid: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(getApiBaseUrl() + '/client/name?id=' + wcid)
        .then((value) => {
          const formalName = value.data['salutation'] + " " + value.data['lastName'];
          resolve(formalName);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default ClientService;
