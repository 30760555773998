import React, { useEffect, useState } from 'react';
import { SecureMessageAttachment } from '../../dto/SecureMessage';
import { ReactComponent as DocIcon } from '../../images/icons/icon-document.svg';
import { useDependencyInjector } from '../../context/DependencyInjector';
import fileDownload from 'js-file-download';

interface AttachmentProps {
  doc: SecureMessageAttachment;
}

function AttachmentLink(props: AttachmentProps) {
  const {documentService} = useDependencyInjector();

  const downloadFile = async (fileDesc: SecureMessageAttachment) => {
    const response = await documentService.getDocument(fileDesc.fileId);
    fileDownload(response.media as Blob, response.fileName);
  };

  return (
    <div
      className="flex flex-row ml-4"
      onClick={async () => {
        await downloadFile(props.doc)
      }}>
      <div>
        <DocIcon></DocIcon>
      </div>
      <div className="flex flex-col ml-2">
        <div>
          <p className="text-sm text-bond underline cursor-pointer">
            {props.doc.fileName}
          </p>
        </div>
        <div>
          <p className="text-sm">Certificate</p>
        </div>
        <div>
          <p className="text-sm text-bond">PDF 1.2mb</p>
        </div>
      </div>
    </div>
  );
}

export default AttachmentLink;
