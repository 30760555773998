import React from 'react';
import { SecureMessage } from '../../dto/SecureMessage';
import DeleteButton from '../Inbox/DeleteButton';
import { ReactComponent as AttachmentIcon } from '../../images/icons/icon-paperclip.svg';
import { guard } from '../../utils/HelperUtils';
import useToken from '../../hooks/useToken';

interface TableProps {
  messages: Array<SecureMessage>;
  folder: string;
  page: number;
  handleSecureMessageNav: (secureMessage: SecureMessage) => void;
  deleteSecureMessage: (secureMessageId: string) => void;
}

function MobileTable(props: TableProps) {

  const auth = useToken()
  return (
    <table className={'w-full'}>
      {props.messages.map((value, index) => {
        return (
          <tr
            className={`border-2 w-[90vw] border-vistaWhite ${
              !value.read && props.folder == 'inbox' ? 'bg-[#fff7e7]' : ''
            }`}
            key={index}>
            <div className="flex flex-col pl-4 pr-4 pt-2 pb-2">
              <div className="flex flex-row">
                <div
                  className={`text-bond mt-1 ${value.read ? '' : 'font-bold'}`}>
                  {value.sentTs}
                </div>
              </div>
              <div className="flex flex-row w-full mt-2">
                <div
                  className="w-[85%] "
                  onClick={() => {
                    guard(auth.token.as === undefined, ()=> {
                      props.handleSecureMessageNav(value);
                      scrollTo(0, 0);
                    })
                  }}>
                  <div
                    className={`text-bond flex flex-row ${
                      !value.read && props.folder == 'inbox' ? 'font-bold' : ''
                    }`}>
                    {value.subject}
                    {value.attachmentCount > 0 && (
                        <AttachmentIcon className="max-h-[15px] ml-0 m-1 mr-4 cursor-pointer fill-bond"></AttachmentIcon>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    props.folder == 'deleted' ? 'hidden' : ''
                  }`}>
                  <DeleteButton
                    secMessageId={value.id}
                    deleteSecureMessage={props.deleteSecureMessage}
                    page={props.page}
                  />
                </div>
              </div>
            </div>
          </tr>
        );
      })}
    </table>
  );
}

export default MobileTable;
