import React, { useEffect, useState } from 'react';
import logo from '../images/wealth-club-portal-logo.svg';
import bgImage from '../images/login_backround.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SpinnerCircularSplit } from 'spinners-react';
import { useDependencyInjector } from '../context/DependencyInjector';
import useToken from '../hooks/useToken';
import ButtonComponent from '../components/Buttons/Button';
import { Button } from 'private-wc-ui';


function MagicLogin() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { setToken } = useToken();

  const navigate = useNavigate();

  const { authService, securityService } = useDependencyInjector();

  useEffect(() => {
    const id = searchParams.get('id');
    const redirect = searchParams.get('redirect');
    if (id) {
      authService.performMagicLinkLogin(id).then(user => {
        setToken(user);

        if (redirect) {
          securityService.validateUrlForRedirect(redirect).then(
            (value) => {
              if (value) {
                window.location.href = redirect + '?id=' + user.token;
              }
            },
          );
          return;
        }
        setSearchParams((prev) => {
          prev.delete('id');
          return prev;
        });

        if (user.group == 'Company') {
          scrollTo(0, 0);
          navigate('/search');
          return;
        }
        scrollTo(0, 0);
        navigate('/dashboard');

        setLoading(false);
      }).catch(reason => {
        setLoading(false);

      });
    } else {
      setLoading(false);
    }

  }, []);


  return (
    <div
      className="flex justify-center align-middle items-center h-screen fade-in bg-center md:bg-cover"
      style={{ backgroundImage: `url(${bgImage})` }}>
      <div
        className="h-2/5 md:w-[75%] lg:w-[60vw] xl:w-[35vw] text-center md:mt-32 -mt-36 xl:-mt-36 flex flex-col justify-center align-middle items-center w-full">
        <img
          src={logo}
          className="lg:w-[328px] h-auto w-[75%] m-12 md:mb-10 md:m-0"
          alt=""></img>

        <div className="flex flex-col bg-white p-5 text-left w-[90vw] lg:w-[65vw] xl:w-[35vw]">

          {loading &&
            <div className={'w-full justify-center flex items-center flex-col gap-3'}><SpinnerCircularSplit
              color={'#BBAC79'}
              size={50}
              thickness={150}
            />
              <p>Validating Magic login token</p>
            </div>}
          {
            !loading && <p className={'text-center '}> Unfortunately the magic link is no longer valid. Please try
              again.<br /><br />
              <Button
                label={'Get another magic link'}
                theme={'primary'}
                onClick={() => {
                  {
                    navigate('/login?forgot=true');
                  }
                }}
              /></p>
          }
        </div>

      </div>
    </div>
  );
}

export default MagicLogin;
