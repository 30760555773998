import axios from 'axios';
import { getApiBaseUrl } from '../utils/HelperUtils';

export interface ISecurityService {
  validateUrlForRedirect(url: string): Promise<boolean>;
}


export class SecurityService implements ISecurityService {
  validateUrlForRedirect(url: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .post(getApiBaseUrl() + '/security/domain', {
          url: url,
        }).then(value => {
        resolve(value.data['result']);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}