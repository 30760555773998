import React, { useEffect, useRef } from 'react';

import UnderlyingInvestments from './UnderlyingInvestments';
import { NonCustodyHolding } from '../../../../dto/Holding';
import TimeLine from '../../../TimeLine/TimeLine';
import { useParams } from 'react-router-dom';

interface LoaderProps {
  holding: NonCustodyHolding;
  data: NonCustodyHolding[];
}

function UnderlyingLoader(props: LoaderProps) {

  const { accountId, parent, cat, child, wrapper } = useParams();

  const [history, setHistory] = React.useState<string | null>(props.holding.id);

  const timeLineRef = useRef<null | HTMLDivElement>(null);

  const getTitleText = () => {
    if (parent) {
      return parent.replaceAll('_', ' ');
    }
    if (cat) {
      return cat.replaceAll('_', ' ');
    }
    return '';
  };

  const getSubtitleText = () => {
    if (child) {
      return child.replaceAll('_', ' ');
    }
    if (wrapper) {
      return wrapper;
    }
    return '';
  };

  const findHoldingName = (id: string | null) => {
    if (!id) {
      return getTitleText() + ' ' + getSubtitleText();
    }
    const res = props.data.filter((f) => f.id == id);
    if (res.length > 0) {
      return res[0].investment.name;
    }
    return props.holding.investment.name;
  };

  useEffect(() => {
    setHistory(props.holding.id); // change it when props change
  }, [props.holding]);

  return (
    <>
      <div className={`fade-in`}>
        <UnderlyingInvestments
          currentHistoryId={history}
          topLevelID={props.holding.id}
          data={props.data}
          setHoldingHistoryId={id => {
            if (history === id) {
              setHistory(props.holding.id);
            } else {
              setHistory(null);
              const { current } = timeLineRef;
              if (current != null) {
                current.scrollIntoView(false);
              }
              setHistory(id);

            }
          }}
        />

        <div ref={timeLineRef} id={'idofDiv'}>{(history != null || accountId != null) &&
          <TimeLine holdingId={history} accountId={accountId} holdingName={findHoldingName(history)} />}</div>
      </div>
    </>
  );
}

export default UnderlyingLoader;
