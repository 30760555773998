import React from 'react';

interface LoginProps {
  type: string;
  name: string;
  value: string | undefined | number;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  error?: boolean;
  id?: string;
  ref?: React.Ref<HTMLInputElement>;
  placeholder?:string;
  className?:React.ComponentProps<'input'>['className']
  disabled?:boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?:number
  minLength?:number
}

function LoginInput(props: LoginProps) {
  return (
    <input
      disabled={props.disabled}
      data-testid={'login-input'}
      type={props.type}
      name={props.name}
      id={props.id}
      maxLength={props.maxLength}
      minLength={props.minLength}
      ref={props.ref}
      value={props.value}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      onChange={props.onChange}
      className={`${props.className} h-[40px] p-3 border-2 focus:outline-midas focus:bg-sand focus:shadow-2xl transition-all duration-500 ${
        props.error ? 'border-[#ff0000]' : 'border-midasLight'
      }`}></input>
  );
}

export default LoginInput;
