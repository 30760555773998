import api from './api';
import FileDescriptor from '../dto/FileDescriptor';
import axios, {AxiosHeaders, AxiosResponse, RawAxiosResponseHeaders} from "axios";

interface IDocumentService {
    getAllDocs(): Promise<Array<FileDescriptor>>;

    getDocument(id: string): Promise<FileDownload>;
}

interface FileDownload {
    fileName: string;
    media: Blob | MediaSource
}

export class DocumentService implements IDocumentService {
    getDocument(id: string): Promise<FileDownload> {
        return new Promise((resolve, reject) => {

            api.get('/documents/' + id, {responseType: 'blob'})
                .then((res) => {
                    let fileName = ""
                    const headers = res.headers as AxiosHeaders
                    if (headers.has('content-disposition')) {
                        fileName = headers["content-disposition"].split('filename=')[1]
                    }
                    const result = {fileName: fileName, media: res.data} as FileDownload
                    resolve(result);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    getAllDocs(): Promise<Array<FileDescriptor>> {
        return new Promise((resolve, reject) => {
            api
                .get('/documents')
                .then((res: { data: FileDescriptor[]; }) => {
                    const response = res.data as Array<FileDescriptor>;
                    resolve(response);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
