import React from 'react';
import { ClientSearch } from '../../dto/ClientSearch';
import { UserToken } from '../../hooks/useToken';
import { ReactComponent as Dropdown } from '../../images/icons/icon-chevron-down.svg';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

interface TextProps {
  data: ClientSearch[] | undefined;
  token: UserToken | null;
  setAccountDropdown: (value: React.SetStateAction<boolean>) => void;
  setToken: (userToken: UserToken) => void;
  accountDropdown: boolean;
}

function SwitchText(props: TextProps) {
  const nav = useNavigate();


  const onClick = () => {
    if (props.token) {
      if (props.token.group == 'Company') {
        if (confirm(`Are you sure you want to leave this client's portal?`)) {
          const cloneAuth = { ...props.token };
          delete cloneAuth['as']; // remove as token
          props.setToken(cloneAuth);
          nav('/Search');
        }
      } else {
        props.setAccountDropdown(!props.accountDropdown);
      }
    }
  };

  return (
    <div
      className={`flex flex-row justify-between cursor-pointer z-[500]`}
      onClick={onClick}>
      <div className={`text-xs md:text-tuxedo select-none ${props.accountDropdown ? 'text-black':'text-white'}`}>
        {props.token && props.token.group == 'Company' ? (
          <div className="mt-2">Return to search</div>
        ) : (
          props.data?.length != 0 && (
            <div className="mt-2 md:mt-1">Change account view</div>
          )
        )}
      </div>
      {props.token && props.token.group == 'Company'
        ? ''
        : props.data?.length != 0 && (
            <Dropdown
              className="inline fill-bond w-7 ml-3 hidden lg:block"
              title="Switch account"
            />
          )}
    </div>
  );
}

export default SwitchText;
