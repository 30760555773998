import React from 'react';
import {useFormik} from 'formik';
import {Button} from 'private-wc-ui';
import useRedirect from '../../hooks/useRedirect';

interface NewUserProps {
    newUserParaText: string | null | undefined;
}

function NewUserForm(props: NewUserProps) {
    const newUserParaText = props.newUserParaText;
    const redirect = useRedirect();

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            handleNewClient();
        }
    });

    const handleNewClient = () => {
        if (redirect.redirect) {
            const url = redirect.redirect;
            redirect.removeRedirect();
            parent.window.location.href = url;
            return;
        }
    }

    return (
        <div className="w-full h-full fade-in">
            <h2>First investment through Wealth Club?</h2>
            <hr className="border-1 border-midas mt-3"></hr>
            <form onSubmit={formik.handleSubmit}
                  className="flex flex-col bg-white md:p-3 pt-3 pb-3 gap-3 text-left w-full">
                <p className={"text-sm"}>
                    As part of your application, we will create a new account for you, with username and password. You will be able to use those details to make any future application faster.
                </p>


                <div className="mt-2 mb-2 flex flex-col md:flex-row w-full">
                    <div className="w-full mb-2 md:w-[30%]">
                        <Button label={'START YOUR FIRST APPLICATION'} theme={'primary'} type={'submit'}
                                style={{width: '100%'}}/>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NewUserForm;
