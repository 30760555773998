export default interface BankDetails {
    accountNumber: string | null;

    sortCode: string | null;

    rollNumber: string | null;

    validationStatus: string | null;

    amlStatus: string | null;
}



export enum BankType {
    REBATE = 'Rebate',
    Main = 'Main',
}