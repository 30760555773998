import React from 'react';



function LoadingBlock() {
  return (
    <div className="w-full h-full border-2 border-vistaWhite bg-[#f0f2f5] animate-pulse mt-1 flex justify-center align-middle items-center">
      <span className="circle animate-loader w-[25px] h-[25px] bg-vistaWhite rounded-full"></span>
      <span className="circle animate-loader animation-delay-200 w-[25px] h-[25px] bg-vistaWhite ml-4 rounded-full"></span>
      <span className="circle animate-loader animation-delay-400 w-[25px] h-[25px] bg-vistaWhite ml-4 rounded-full"></span>
    </div>
  );
}

export default LoadingBlock;
