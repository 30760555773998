import React from 'react';
import { formatMoney } from '../../utils/FormattingUtils';

interface HeaderProps {
  value: number;
}

function PortfolioHeader(props: HeaderProps) {
  return (
    <div className="p-4 pb-0 flex md:flex-row md:justify-between w-full ml-0 flex-col justify-center items-center content-center">
      <h3 className="w-auto float-left text-left">My portfolio</h3>
      <div className="flex-row text-right items-center flex">
        <h3 className="lg:ml-10 md:block lg:text-base xl:text-lg">
          Total value:
        </h3>
        <h2 className="text-bond ml-1 md:ml-3 md:block">
          {props.value ? formatMoney(props.value) : formatMoney(0)}
        </h2>
      </div>
    </div>
  );
}

export default PortfolioHeader;
