import BankDetails, { BankType } from '../dto/BankDetails';
import { ChangeBankDetailForm, ChangeBankDetailFormErrors } from '../components/BankDetails/BankDetailsTypes';
import ClientService from '../services/ClientService';
import { validateAccountNumber, validateSortCodeWithHyphen } from './ValidationUtils';

/**
 * Takes a sort code and applies a mask e.g. 22-22-22
 * @param code
 */
const maskSortCode = (code: string) => {
  const result = code.match(/.{1,2}/g);
  if (result) {
    return result.join('-');
  }
  return '';
};

/**
 * Submits new bank details for verification via AML
 * @param data
 * @param clientService
 * @param bankType
 */
const submitNewBankDetails = async (data: ChangeBankDetailForm, clientService: ClientService, bankType: BankType): Promise<BankDetails> => {
  return new Promise((resolve, reject) => {
    const bankDetails = { ...data } as BankDetails;
    // remove masking from sort code field
    const strippedAccount = bankDetails.sortCode?.replaceAll('-', '');

    if (strippedAccount) {
      bankDetails.sortCode = strippedAccount;
    }
    // API call to update the account based on its type
    clientService.updateBankDetails(bankDetails, bankType).then(
      value => resolve(value),
    ).catch(reason =>
      reject(reason),
    );
  });
};

/**
 * validates form inputs for bank account number and sort code and returns the errors.
 * @param changeBankDetailForm
 */
const validateBankFormInputs = (changeBankDetailForm: ChangeBankDetailForm) => {
  const errors: ChangeBankDetailFormErrors = {};
  if (!validateAccountNumber(changeBankDetailForm.accountNumber)) {
    errors.accountNumber = 'Invalid account number.';
  }
  if (!validateSortCodeWithHyphen(changeBankDetailForm.sortCode)) {
    errors.sortCode = 'Invalid sort code.';
  }
  return errors;
}

export { maskSortCode, submitNewBankDetails, validateBankFormInputs};