import React, { CSSProperties } from 'react';
import { SecureMessage } from '../../dto/SecureMessage';
import DeleteButton from '../Inbox/DeleteButton';
import { ReactComponent as AttachmentIcon } from '../../images/icons/icon-paperclip.svg';
import { ReactComponent as ReplyIcon } from '../../images/icons/icon-reply.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ViewIcon } from '../../images/icons/icon-preview.svg';
import { ReactComponent as PencilIcon } from '../../images/icons/icon-pencil.svg';
import useToken from '../../hooks/useToken';
import { guard } from '../../utils/HelperUtils';
import { IColumnType, Table } from 'private-wc-ui';

interface TableProps {
  messages: Array<SecureMessage>;
  folder: string;
  page: number;
  handleSecureMessageNav: (secureMessage: SecureMessage) => void;
  deleteSecureMessage: (secureMessageId: string) => void;
  setViewSecureMessage: (
    value: React.SetStateAction<SecureMessage | undefined>,
  ) => void;
  setSidebar: (value: React.SetStateAction<boolean>) => void;
}

function DesktopTable(props: TableProps) {
  const nav = useNavigate();
  const auth = useToken();

  interface TableData extends  SecureMessage {
    bgColour?: CSSProperties["color"];
  }

  const columns: IColumnType<TableData>[] = [
    {
      key: 'delete',
      title: '',
      hide: props.folder === 'deleted',
      render: (_, tableData) => {
        return <DeleteButton
          secMessageId={tableData.id}
          deleteSecureMessage={props.deleteSecureMessage}
          page={props.page}
        />;
      },
    },
    {
      hide: !(props.folder === 'inbox' || props.folder === 'sent'),
      key: 'sentTs',
      title: 'Sent',
      render:(_, tableData) => {
        return <span className={`font-normal whitespace-nowrap overflow-ellipsis overflow-hidden ${
          !tableData.read && props.folder === 'inbox' ? 'font-bold' :  ''
        }`}>{tableData.sentTs}</span>
      }
    },
    {
      key: 'subject',
      title: 'Subject',
      render: (_, tableData) => {
        return <div className={'flex flex-row lg:max-w-[100%] md:max-w-[50px]  gap-4'} onClick={() => {
          guard(auth.token.as === undefined, ()=> {
            props.handleSecureMessageNav(tableData);
            scrollTo(0, 0);
          })
        }}>
          <div className={`whitespace-nowrap overflow-hidden overflow-ellipsis hover:cursor-pointer ${
              !tableData.read && props.folder == 'inbox' ? 'font-bold' : 'font-normal'
              }`}>{tableData.subject}</div>
            {tableData.attachmentCount > 0 && (
                <div className={"w-[20px] h-[20px]"}>
                  <AttachmentIcon className="h-[20px] w-[20px] block fill-bond" title="Attachment"></AttachmentIcon>
                </div>
            )}
        </div>;
      },
    },
    {
      title: '',
      key: 'reply',
      hide: props.folder != 'inbox',
      render: (_, tableData) => {
        return <div className="flex flex-row items-center" onClick={event => {
          guard(auth.token.as === undefined, () => {
            nav('/reply/' + tableData.id);
          });

        }}>
          <ReplyIcon
            className="h-[20px] fill-bond"
            title="Reply"></ReplyIcon>
          <h5
            className={`text-bond cursor-pointer ${
              !tableData.read && props.folder == 'inbox' ? '' : 'font-normal'
            }`}>
            Reply
          </h5>
        </div>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, tableData) => {
        return <div onClick={() => {
          guard(auth.token.as === undefined, () => {
            props.handleSecureMessageNav(tableData);
          });
        }}>

          {props.folder == 'drafts' && (
            <div className="flex flex-row items-center cursor-pointer">
              <PencilIcon
                className="h-[20px] fill-bond"
                title="Continue Writing"></PencilIcon>
              <h5 className={`text-bond cursor-pointer font-normal`}>
                Continue Writing
              </h5>
            </div>
          )}
          {props.folder != 'drafts' && (
            <div className="flex flex-row items-center cursor-pointer">
              <ViewIcon
                className="h-[20px] fill-bond"
                title="View"></ViewIcon>
              <h5
                className={`text-bond cursor-pointer ${
                  !tableData.read && props.folder == 'inbox'
                    ? ''
                    : 'font-normal'
                } mr-0 md:mr-4`}>
                View
              </h5>
            </div>
          )}

        </div>;
      },
    },
  ];


  /**
   * When a secure message is not read we need to add the background colour to the data so the table knows how to render
   * @param data
   */
  const addBackgroundToUnreadMessages = (data: SecureMessage[]): TableData[] => {
    const tableData: TableData[] = [];
    data.map(value => {
      if (value.read) {
        tableData.push(value);
      } else {
        tableData.push({ ...value, bgColour: '#fff8e8' } as TableData);
      }
    });

    return tableData;
  };


  return (
    <Table data={addBackgroundToUnreadMessages(props.messages)} columns={columns}  style={{maxWidth:'100%', width:"100%"}}/>
  );
}

export default DesktopTable;
