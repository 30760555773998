import React from 'react';
import {ReactComponent as WarningIcon} from '../../images/icons/icon-warning.svg';

interface WarningMessageProps {
    message?: string |  React.ReactNode
}

const WarningMessage = (props: WarningMessageProps) => {

    return (
        <div
            className={`w-full bg-sand flex flex-row fade-in mt-4 mb-4 p-2 text-sm text-tuxedo gap-2`}>
            <span><WarningIcon className="h-[25px] fill-tuxedo"/></span>
            <span>{props.message}</span>
        </div>)
};


export default WarningMessage;

