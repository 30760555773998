import React from 'react';
import { SecureMessageAttachment } from '../../dto/SecureMessage';
import AttachmentLink from './AttachmentLink';
import { ReactComponent as ClipIcon } from '../../images/icons/icon-paperclip.svg';

interface AttachmentBoxProps {
  attachments: SecureMessageAttachment[];
}

function AttachmentBox(props: AttachmentBoxProps) {
  return (
    <div className="bg-sand p-4">
      <div className="flex flex-col flex-wrap gap-4 md:flex-row">
        {props.attachments &&
          props.attachments.map((value, index) => {
            return <AttachmentLink doc={value} key={index} />;
          })}
      </div>
    </div>
  );
}

export default AttachmentBox;
