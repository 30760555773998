import React, { useEffect, useState } from 'react';
import ButtonComponent from '../Buttons/Button';
import LoginInput from '../Inputs/Input';
import { FormikTouched, useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { AxiosError } from 'axios';
import { Address, AddressUpdate } from '../../dto/Address';
import ErrorBanner from '../Error/ErrorBanner';
import * as yup from 'yup';
import PostcodeLookupComponent from '../Address/PostcodeLookupComponent';
import useToken from '../../hooks/useToken';
import { isDisabledForViewAs } from '../../utils/HelperUtils';
import { Button } from 'private-wc-ui';

interface AddressFormError {
  message: string;
}


function AddressForm() {
  const { clientService } = useDependencyInjector();

  const auth = useToken()

  const [addressFormError, setAddressFormError] = useState<AddressFormError | null>();


  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (addressFormError != null) {
      timer = setTimeout(() => setAddressFormError(null), 5000);

    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [addressFormError]);


  const {  data, refetch } = useQuery({
    queryKey: ['address'],
    queryFn:
      async () => {
        try {
          return await clientService.getAddress();
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              return {
                addressLineOne: null,
                addressLineTwo: null,
                city: null,
                county: null,
                postcode: null,
                country: null,
                pendingApproval: null,
              } as Address;
            }
          }
          throw error;
        }
      },
  });

  const isFormDisabled = () =>{
    let pend = false
    if (data?.pendingApproval != null){
      pend = data.pendingApproval;
    }
    return pend || (auth.token != null && auth.token.group === "Company") ||  isDisabledForViewAs(auth)
  }


  const initialValues: AddressUpdate = {
    addressLineOne: data?.addressLineOne !== undefined ? data?.addressLineOne : '',
    addressLineTwo: data?.addressLineTwo !== undefined ? data?.addressLineTwo : '',
    city: data?.city !== undefined ? data?.city : '',
    county: data?.county !== undefined ? data?.county : '',
    postcode: data?.postcode !== undefined ? data?.postcode : '',
    country: data?.country !== undefined ? data?.country : '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      addressLineOne: yup.string().required('Your address line one is required'),
      addressLineTwo: yup.string().notRequired(),
      city: yup.string().required('Your city is required'),
      postcode: yup.string().required('Your postcode is required'),
      county: yup.string().notRequired(),
      country: yup.string().required('Your country is required'),
    }),
    onSubmit: async (values) => {
      try {
        await clientService.updateAddress(values as Address);
        await refetch();
      } catch (e) {
        const err = { message: 'An error occurred updating the address please try again later.' } as AddressFormError;
        setAddressFormError(err);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="bg-white text-left  w-full mb-10 xl:mb-0 md:ml-0 rounded-xl shadow-lg p-8">

      <div className="flex flex-col gap-3 w-full">
        <h4 className="mb-4">Address</h4>

        {!isFormDisabled() &&  <><PostcodeLookupComponent onAddressSelected={(address: any) => {
          formik.setFieldValue('addressLineOne', address.line_1);
          formik.setFieldValue('addressLineTwo', address.line_2);
          formik.setFieldValue('postcode', address.postcode);
          formik.setFieldValue('country', address.country);
          formik.setFieldValue('city', address.post_town);
          formik.setFieldValue('county', address.county);
        }} />
          <hr className="border-1 border-midasLight mt-4 mb-4" />
        </>
        }
        <label
          className="font-sans font-bold text-tuxedo text-[14px]"
          htmlFor="addressLineOne">
          Address line one <span className="text-[#FF0000]">*</span>
        </label>
        <LoginInput
          type={'text'}
          name={'addressLineOne'}
          disabled={isFormDisabled() || isDisabledForViewAs(auth)}
          value={formik.values.addressLineOne == null ? '' : formik.values.addressLineOne}
          onChange={formik.handleChange}
          error={
            formik.errors.addressLineOne != undefined &&
            formik.errors.addressLineOne.length != 0 &&
            formik.touched.addressLineOne
          }
          id={'addressLineOne'}></LoginInput>
        {formik.errors.addressLineOne && formik.touched.addressLineOne ? (
          <div style={{ color: 'red' }}>{formik.errors.addressLineOne}</div>
        ) : null}
        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="addressLineTwo">
          Address line two <span>(Optional)</span>
        </label>
        <LoginInput
          type={'text'}
          name={'addressLineTwo'}
          disabled={isFormDisabled() || isDisabledForViewAs(auth)}
          value={formik.values.addressLineTwo == null ? '' : formik.values.addressLineTwo}
          onChange={formik.handleChange}
          error={
            formik.errors.addressLineTwo != undefined &&
            formik.errors.addressLineTwo.length != 0 &&
            formik.touched.addressLineTwo
          }
          id={'addressLineTwo'}></LoginInput>
        {formik.errors.addressLineTwo && formik.touched.addressLineTwo ? (
          <div style={{ color: 'red' }}>{formik.errors.addressLineTwo}</div>
        ) : null}

        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="city">
          Town/City <span className="text-[#FF0000]">*</span>
        </label>
        <LoginInput
          type={'text'}
          name={'city'}
          disabled={isFormDisabled() || isDisabledForViewAs(auth)}
          value={formik.values.city == null ? '' : formik.values.city}
          onChange={formik.handleChange}
          error={
            formik.errors.city != undefined &&
            formik.errors.city.length != 0 &&
            formik.touched.city
          }
          id={'city'}></LoginInput>
        {formik.errors.city && formik.touched.city ? (
          <div style={{ color: 'red' }}>{formik.errors.city}</div>
        ) : null}

        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="county">
          County <span>(Optional)</span>
        </label>
        <LoginInput
          type={'text'}
          name={'county'}
          disabled={isFormDisabled() || isDisabledForViewAs(auth)}
          value={formik.values.county == null ? '' : formik.values.county}
          onChange={formik.handleChange}
          error={
            formik.errors.county != undefined &&
            formik.errors.county.length != 0 &&
            formik.touched.county
          }
          id={'county'}></LoginInput>
        {formik.errors.county && formik.touched.county ? (
          <div style={{ color: 'red' }}>{formik.errors.county}</div>
        ) : null}

        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="postcode">
          Postcode <span className="text-[#FF0000]">*</span>
        </label>
        <LoginInput
          type={'text'}
          name={'postcode'}
          disabled={isFormDisabled() || isDisabledForViewAs(auth)}
          value={formik.values.postcode == null ? '' : formik.values.postcode}
          onChange={formik.handleChange}
          error={
            formik.errors.postcode != undefined &&
            formik.errors.postcode.length != 0 &&
            formik.touched.postcode
          }
          id={'postcode'}></LoginInput>
        {formik.errors.postcode && formik.touched.postcode ? (
          <div style={{ color: 'red' }}>{formik.errors.postcode}</div>
        ) : null}

        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="Country">
          Country <span className="text-[#FF0000]">*</span>
        </label>
        <LoginInput
          type={'text'}
          name={'country'}
          disabled={isFormDisabled() || isDisabledForViewAs(auth)}
          value={formik.values.country == null ? '' : formik.values.country}
          onChange={formik.handleChange}
          error={
            formik.errors.country != undefined &&
            formik.errors.country.length != 0 &&
            formik.touched.country
          }
          id={'country'}></LoginInput>
        {formik.errors.country && formik.touched.country ? (
          <div style={{ color: 'red' }}>{formik.errors.country}</div>
        ) : null}

        {data?.pendingApproval && (
          <ErrorBanner text={'Address update is pending approval.'} severe={false} />
        )}
        {addressFormError != null && (
          <ErrorBanner text={addressFormError.message} severe={true} />
        )}

      </div>
      {!data?.pendingApproval &&
          <Button
              disabled={isFormDisabled() || isDisabledForViewAs(auth) || formik.isSubmitting}
              style={{ width: 'auto', marginTop: '4%' }}
              label="Confirm Changes"
              theme={'primary'}
              type={'submit'}
          />
      }
    </form>
  );
}

export default AddressForm;
