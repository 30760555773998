import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import { NavItemsDto } from '../../dto/NavItemsDto';


function NavDropDownLinks(props: {navItem: NavItemsDto}) {

  const [open, setOpen] = React.useState(false);


  return (
    <div className={"group "}>
      <a className={`text-sm pl-10 hover:cursor-pointer group-hover:text-sand transition-all duration-500`}
         onClick={event => {
           setOpen(!open);
         }}>{props.navItem.parent.title}</a>
      <ul className={'ml-11 mt-2'} hidden={!open}>
        <li className={'relative flex gap-6 overflow-hidden'}>
          <div
            className="before:absolute before:left-[1px] before:h-full before:w-[1px] before:bg-midasLight ml-3 flex flex-col gap-2">
            {props.navItem.children.map((value, index) => {
              return <Link className={"whitespace-nowrap text-sm hover:cursor-pointer font-extralight hover:text-sand transition-all duration-500"} key={index} to={value.url}>{value.title}</Link>;
            })}
          </div>
        </li>
      </ul>
    </div>
  );
}

export default NavDropDownLinks;




