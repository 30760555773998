import React, {MouseEventHandler, useEffect, useState} from 'react';
import logo from '../images/wealth-club-portal-logo.svg';
import bgImage from '../images/login_backround.png';
import { useNavigate, useSearchParams} from "react-router-dom";


function PasswordResetConfirmation() {

    const [searchParams, setSearchParams] = useSearchParams();

    const [msg, setMessage] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        const msgType = searchParams.get("msg_type")
        if (msgType) {
            setMessage(msgType)
        }

    }, [searchParams.get("msg_type")])

    const navToLogin = (e: React.MouseEvent<HTMLAnchorElement> | undefined) => {
        //some reason you got to remove search params before nav
        if (searchParams.has('msg_type')) {
            setSearchParams((prev) => {
                prev.delete("msg_type");
                return prev
            })
        }
          navigate("/login")
    }
    return (
        <div
            className="flex justify-center align-middle items-center h-screen fade-in bg-center md:bg-cover"
            style={{backgroundImage: `url(${bgImage})`}}>
            <div
                className="h-2/5 md:w-[75%] lg:w-[60vw] xl:w-[35vw] text-center md:mt-32 -mt-36 xl:-mt-36 flex flex-col justify-center align-middle items-center w-full">
                <img
                    src={logo}
                    className="lg:w-[328px] h-auto w-[75%] m-12 md:mb-10 md:m-0"
                    alt=""></img>

                <div className="flex flex-col bg-white p-5 text-left w-[90vw] lg:w-[65vw] xl:w-[35vw]">

                    {msg && msg === "pwd" &&
                        <div className={"p-4 flex flex-col gap-4"}>
                            <h2>Password Reset email sent</h2>
                            <p>Thank you for initiating the password reset process for your WealthClub account. We{"'"}ve
                                just sent a password reset email to the address associated with your account.</p>
                            <p>If we were unable to locate the account no email will be sent.</p>

                            <a onClick={navToLogin} className={"text-sm text-bond underline hover:cursor-pointer"}>Return
                                to Login</a>
                        </div>
                    }

                    {msg && msg === "pwd-complete" &&
                        <div className={"p-4 flex flex-col gap-4"}>
                            <h2>Password Reset complete</h2>
                            <p>Thank you for resetting your password for your WealthClub account.</p>
                            <a onClick={navToLogin} className={"text-sm text-bond underline hover:cursor-pointer"}>Return
                                to Login</a>
                        </div>
                    }

                    {msg && msg === "magic" &&
                        <div className={"p-4 flex flex-col gap-4"}>
                            <h2>Magic Link email sent</h2>
                            <p>Thank you for requesting a Magic Link – we{"'"}ve sent it to the email provided.
                                The link will expire in 1 hour. </p>
                            <p>If we were unable to locate the account no email will be sent.</p>
                            <a onClick={navToLogin} className={"text-sm text-bond underline"}>Return to Login</a>
                        </div>
                    }
                    {msg === "" &&
                        <div className={"p-4 flex flex-col gap-4"}>
                            <h2>An error has occurred</h2>
                            <p>Please try again later</p>
                            <a onClick={navToLogin} className={"text-sm text-bond underline"}>Return to Login</a>
                        </div>
                    }


                </div>

            </div>
        </div>
    );
}

export default PasswordResetConfirmation;
