import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import PageTitle from '../PageTitles/PageTitle';
import moment from 'moment';
import LoadingBlock from '../Loading/LoadingBlock';
import ErrorBanner from '../Error/ErrorBanner';
import { HoldingHistory, HoldingHistoryCount } from '../../dto/HoldingHistory';
import { CursorWrapper } from '../../dto/CursorWrapper';
import { formatStringToDate } from '../../utils/DateUtils';
import {formatMoney, formatNum} from "../../utils/FormattingUtils";


export interface TimeLineProps {
  holdingId: string | null;
  holdingName:string;
  accountId: string | undefined;
}


function TimeLine(props: TimeLineProps) {
  const { ref, inView } = useInView();

  const { investmentService } = useDependencyInjector();



  const {  data:countData } = useQuery({
    queryKey: ['history-count', props.holdingId, props.accountId],
    queryFn: async () => {
      if (props.holdingId){
        return await investmentService.getHoldingHistoryCount(props.holdingId);
      }
      if (props.accountId){
        return await investmentService.getHoldingHistoryCountDfm(props.accountId)
      }

      return {holdingHistoryCount:0} as HoldingHistoryCount
    },
  });


  const countOfHistory = countData?.holdingHistoryCount


  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    ['history', props.holdingId, props.accountId],
    async ({ pageParam = 0 }) => {
      if (props.holdingId) {
        return await investmentService.getHoldingHistory(props.holdingId, pageParam);
      }
      if (props.accountId){
        return await investmentService.getHoldingHistoryDfm(props.accountId, pageParam);
      }
      return {} as CursorWrapper<HoldingHistory>
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previousCursor ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
    },

  );

  React.useEffect(() => {
    if (inView && countOfHistory != undefined && countOfHistory > 0) {
      fetchNextPage();
    }
  }, [inView]);


  return (
    <div className={`pt-8  ${countData?.holdingHistoryCount === 0 ? "hidden":"visible"}`}>
      {status === 'loading' ? (
        <LoadingBlock />
      ) : status === 'error' ? (
        <ErrorBanner text={'An error occurred loading status history'} severe={true} />
      ) : (
        <>
          {props.accountId == undefined && <PageTitle title={''} subtitle={'Recent history for ' + props.holdingName} />}

          {countData?.holdingHistoryCount === 0 &&
            <h6 className="title text-sm ">Holding has no history.</h6>
          }
          <section className="flex min-h-full justify-start bg-gray-200 pt-2 md:ml-4">
            <div className="w-100 flex flex-col">
              {data?.pages.map((page, index) => (
                <ul key={index}>
                  {page.data.map((value, index1) => (
                    <li className="relative flex gap-6 overflow-hidden" key={index1}>
                      <div
                        className="before:absolute before:left-[48px] before:h-full before:w-[4px] before:bg-[#bbac79] before:mt-[50px]">
                        <svg height="77" width="77" xmlns="http://www.w3.org/2000/svg" className="-mt-6">
                          <circle r="25" cx="50" cy="50" fill="#eeedeb" stroke="#bbac79" strokeWidth="2" />
                        </svg>
                      </div>
                      <div className={'mt-2'}>
                        <h6 className="text-lg font-semibold text-bond">{value.effectiveStatus} -
                          <time>{value.effectiveDate != null ? ' ' + formatStringToDate(value.effectiveDate) : ''}</time>
                        </h6>
                        <p className="mt-2">
                          <b>Value:</b> {value.value ? formatMoney(value.value, value.currency) : 'N/A'}</p>
                        <p className="mt-2"><b>Unit
                          price:</b> {value.unitPrice ? formatMoney(value.unitPrice, value.currency) : 'N/A'}
                        </p>
                        <p className="mt-2"><b>Quantity:</b> {value.quantity ? formatNum(value.quantity) : 'N/A'}</p>
                      </div>
                    </li>
                  ))}

                </ul>
              ))}
              {(hasNextPage == false && (countData && countData.holdingHistoryCount > 0))  &&
                <li className="relative flex gap-6 overflow-hidden" >
                  <div
                    className="before:absolute before:left-[48px]  before:w-[4px] before:bg-[#bbac79] before:mt-[50px]">
                    <svg height="77" width="77" xmlns="http://www.w3.org/2000/svg" className="-mt-6">
                      <circle r="25" cx="50" cy="50" fill="#eeedeb" stroke="#bbac79" strokeWidth="2" />
                    </svg>
                  </div>
                </li>
              }
            </div>
          </section>


          <div ref={ref}>
            {isFetching && !isFetchingNextPage
              ? 'Loading holding history...'
              : null}
          </div>
        </>
      )}
    </div>
  );
}

export default TimeLine;