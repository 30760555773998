import React from 'react';
import Slider from '../Inputs/Slider';
import { ContactFrequencyPreferences, ContactPreferences } from '../../dto/ContactPreferences';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import LoadingBlock from '../Loading/LoadingBlock';
import ErrorBanner from '../Error/ErrorBanner';
import useToken from '../../hooks/useToken';
import { isDisabledForViewAs } from '../../utils/HelperUtils';
import { InterestsVal } from './InterestsForm';



export interface HowOftenFormProps {
  data:  ContactFrequencyPreferences | undefined
  isLoading: boolean;
  isError: boolean;
  mutation:  UseMutationResult<ContactFrequencyPreferences, unknown, ContactFrequencyPreferences, unknown>
}


function HowOftenForm(props:HowOftenFormProps) {

  const auth = useToken()


  if (props.isLoading) {
    return <LoadingBlock />;
  }

  if (props.isError) {
    return <ErrorBanner text={"An error occurred. Please try again."} severe={true}/>;
  }

  if (!props.data) {
    return null;
  }

  return (
    <div className="w-full h-full flex flex-col">
      <h5 className="text-sm h-[20%]">How often would you like to receive emails from us about news and opportunities?</h5>
      <div className="">
        <Slider
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
          active={props.data.asap}
          onclick={() => {
            const update = { weekly: false, monthly: false, asap: !props.data?.asap } as ContactFrequencyPreferences;
            props.mutation.mutate(update);
          }}
          title="As soon as possible (recommended)"
        />
        <Slider
          active={props.data.weekly}
          onclick={() => {
            const update = { asap: false, monthly: false, weekly: !props.data?.weekly } as ContactFrequencyPreferences;
            props.mutation.mutate(update);
          }}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
          title="Once a week"
        />
        <Slider
          active={props.data.monthly}
          onclick={() => {
            const update = { asap: false, weekly: false, monthly: !props.data?.monthly } as ContactFrequencyPreferences;
            props.mutation.mutate(update);
          }}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
          title="Once a month"
        />
      </div>
    </div>
  );


}

export default HowOftenForm;
