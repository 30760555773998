import React, { useState } from 'react';

interface ButtonProps {
  title: string;
  styleType: 'primary' | 'secondary';
  drops: Array<DropdownItems>;
  style?: React.CSSProperties;
  dataCy?: string
}

type DropdownItems = {
  title: string;
  onClick: () => void;
};

function DropdownButton(props: ButtonProps) {
  const [open, setOpen] = useState<boolean>(false);

  const dropdownOnclick = () => {
    setOpen(!open);
  };

  return (
    <div
      className="w-[100%] md:w-[25%] md:ml-0 md:mt-4"
      aria-haspopup="true">
      <button
        onClick={dropdownOnclick}
        type={'button'}
        className={`${
          props.styleType === 'primary'
            ? 'bg-midasLight hover:bg-sand'
            : 'hover:bg-midasLight bg-sand'
        }  pl-5 pr-5 pt-2 pb-2 uppercase font-sans font-bold text-sm transition-all duration-200 inline w-full`}
      data-cy={props.dataCy}>
        {props.title}
      </button>
      <nav
        className={`${
          open ? '' : 'hidden'
        } w-full shadow-2xl mt-2 text-center fade-in`}>
        <ul>
          {props.drops.map((value, index) => {
            return (
              <li
                key={index}
                className="hover:bg-sand text-sm cursor-pointer p-3 transition-colors duration-300"
                onClick={() => {
                  value.onClick();
                  setOpen(false);
                }}
              data-cy={`exportPdfOption${index}`}>
                <a>{value.title}</a>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

export default DropdownButton;
