import React, { useEffect, useState } from 'react';
import { useDependencyInjector } from '../../context/DependencyInjector';
import LoginInput from '../Inputs/Input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ContactDetails } from '../../dto/ContactDetails';
import { useQuery } from '@tanstack/react-query';
import ButtonComponent from '../Buttons/Button';
import ErrorMessage from '../../errors/ErrorMessage';
import ErrorBanner from '../Error/ErrorBanner';
import axios, { AxiosError } from 'axios';
import useToken from '../../hooks/useToken';
import { isDisabledForViewAs } from '../../utils/HelperUtils';
import { Button } from 'private-wc-ui';


interface ContactFormAlert {
  message: string;
  isError: boolean;
}

function ContactDetailsForm() {
  const { clientService } = useDependencyInjector();
  const [formAlert, setFormAlert] = useState<ContactFormAlert | null>();

  const auth = useToken();


  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (formAlert != null) {
      timer = setTimeout(() => setFormAlert(null), 5000);

    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [formAlert]);


  const { data, refetch } = useQuery({
    queryKey: ['contact-details'],
    queryFn:
      async () => {
        return await clientService.getContactDetails();
      },
  });


  const initialValues: ContactDetails = {
    phoneNumber: data?.phoneNumber !== undefined ? data?.phoneNumber : '',
    email: data?.email !== undefined ? data?.email : '',
  };


  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/;

  const phoneNumberRegex = /^0([1-6][0-9]{9,10}|7[0-9]{9})$/;


  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      email: yup.string().matches(emailRegex, { message: 'Please enter a valid e-mail' }).max(255).required('E-mail is required'),
      phoneNumber: yup.string().matches(phoneNumberRegex, { message: 'Please enter a valid UK phone number.' }).required('Your phone number is required.'),
    }),
    onSubmit: async (values) => {
      try {
        await clientService.updateContactDetails(values as ContactDetails);
        await refetch();
        setFormAlert({ message: 'Contact details updated.', isError: false });
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response) {
            const err = e.response.data as ErrorMessage;
            formik.setFieldError('email', '');
            formik.setFieldError('phoneNumber', '');
            setFormAlert({ message: err.error, isError: true });
          }
        } else {
          setFormAlert({ message: 'An unknown error occurred, please try again later.', isError: true });
        }

      }
    },
  });


  return (
    <form
      onSubmit={formik.handleSubmit}
      className="bg-white text-left w-full mb-10  rounded-xl shadow-lg p-8">

      <div className="flex flex-col gap-3 w-full">
        <h4 className="mb-4">Contact details</h4>
        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="email">
          Email <p className="text-[#FF0000] inline">*</p>
        </label>
        <LoginInput
          type={'text'}
          name={'email'}
          value={formik.values.email == null ? '' : formik.values.email}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
          onChange={formik.handleChange}
          error={
            formik.errors.email != undefined &&
            formik.errors.email.length != 0 &&
            formik.touched.email
          }
          id={'email'}></LoginInput>
        {formik.errors.email && formik.touched.email ? (
          <div style={{ color: 'red' }}>{formik.errors.email}</div>
        ) : null}

        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="phoneNumber">
          Phone number <p className="text-[#FF0000] inline">*</p>
        </label>
        <LoginInput
          type={'text'}
          name={'phoneNumber'}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
          value={formik.values.phoneNumber == null ? '' : formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={
            formik.errors.phoneNumber != undefined &&
            formik.errors.phoneNumber.length != 0 &&
            formik.touched.phoneNumber
          }
          id={'email'}></LoginInput>
        {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
          <div style={{ color: 'red' }}>{formik.errors.phoneNumber}</div>
        ) : null}
      </div>
      <Button
        className="block"
        style={{ width: 'auto', marginTop: '4%' }}
        label="Confirm Changes"
        theme={'primary'}
        type={'submit'}
        disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth) || formik.isSubmitting}
      />
      {formAlert != null && (
        <ErrorBanner text={formAlert.message} severe={formAlert.isError} />
      )}

    </form>
  );

}

export default ContactDetailsForm;