import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { formatMoney, formatNum } from '../../utils/FormattingUtils';
import { NonCustodyHolding } from '../../dto/Holding';
import { ReactComponent as ChevronRight } from '../../images/icons/icon-chevron-right.svg';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { IColumnType, Table } from 'private-wc-ui';
import { guard } from '../../utils/HelperUtils';
import { formatStringToDate } from '../../utils/DateUtils';
import useTableConfig from '../../hooks/useTableConfig';
import { useParams } from 'react-router-dom';
import { TableConfig } from '../../config/Config';
import { Tooltip } from 'react-tooltip';
import HoldingWithDocuments from '../../dto/HoldingWithDocuments';

type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

interface TableProps {
  nonCustodyHoldings: NonCustodyHolding[];
  setSideBar: (value: React.SetStateAction<SideBarContent>) => void;
  childrenCustodyHoldings: NonCustodyHolding[];
  isDfm?: boolean;
}

// IRowConfig could not be exported from component library
export interface TableData extends NonCustodyHolding {
  bgColour?: CSSProperties['color'];
}

function DrilldownTable(props: TableProps) {
  const { investmentService } = useDependencyInjector();

  const useDocumentsForInvestment = (id: string) => {
    const [documents, setDocuments] = useState<HoldingWithDocuments[] | null>(null);

    useEffect(() => {
      const fetchDocuments = async () => {
        try {
          const docs = await investmentService.getDocumentsForInvestment(id);
          setDocuments(docs);
        } catch (error) {
          console.error('Error fetching documents:', error);
          setDocuments(null);
        }
      };

      fetchDocuments();
    }, [id]);

    return documents;
  };

  const config = useTableConfig();
  const { child, cat } = useParams();

  const retrieveConfig = (
    child: string | undefined,
    cat: string | undefined,
  ): TableConfig => {
    return child === undefined
      ? config.getConfig(cat)
      : config.getConfig(child);
  };

  const tableConfig = useMemo(() => retrieveConfig(child, cat), [child, cat]);

  const columns: IColumnType<TableData>[] = [
    {
      key: 'investment.name',
      title: 'Product name',
      hide: !tableConfig.levelOne.investment.name,
      headerClassName: '',
      render: (_, prop) => (
        <div>
          <h5
            className={`text-bond ${
              !props.isDfm ? 'cursor-pointer underline' : ''
            }`}
            onClick={() => {
              guard(!props.isDfm, () => {
                scrollTo(0, 0);
                props.setSideBar({
                  holding: prop,
                  openType: 'INVESTMENT',
                });
              });
            }}>
            {prop.investment.name}
          </h5>

          <div className={'flex flex-col gap-2 font-thin text-xs'}>
            <div
              className={`${
                investmentService.getNumberOfChildren(
                  prop.id,
                  props.childrenCustodyHoldings,
                ) > 0
                  ? 'visible'
                  : 'hidden'
              }`}>
              {investmentService.getNumberOfChildren(
                prop.id,
                props.childrenCustodyHoldings,
              )}{' '}
              Investment(s).
            </div>
            {prop.phase == 10 && (
              <div className={'font-normal'}>Pending allocation.</div>
            )}
            {prop.phase == 30 && <div className={'font-normal'}>Closed.</div>}
            {prop.phase == 40 && <div className={'font-normal'}>Archived.</div>}
          </div>
        </div>
      ),
    },
    {
      key: 'value',
      title: 'Value',
      hide: !tableConfig.levelOne.value,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={'text-right'} data-cy="investmentValue">
            <Tooltip id={`${prop.id}-tooltip-value-tooltip`} />
            {prop.phase == 10 ? '-' : <a  data-tooltip-id={`${prop.id}-tooltip-value-tooltip`} data-tooltip-content={`${prop.value}`}>{formatMoney(prop.value, prop.currency)}</a>}
          </div>
        );
      },
    },
    {
      key: 'growthPercentage',
      title: 'Growth (%)',
      hide: !tableConfig.levelOne.growthPercentage,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={`text-right`}>
            {prop.phase == 10 || prop.investment.name == 'Cash'
              ? '-'
              : formatNum(prop.growthPercentage)}
          </div>
        );
      },
    },
    {
      key: 'growth',
      title: 'Growth',
      hide: !tableConfig.levelOne.growth,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={`text-right`}>
            {prop.phase == 10 || prop.investment.name == 'Cash'
              ? '-'
              : formatMoney(prop.growth)}
          </div>
        );
      },
    },
    {
      key: 'units',
      title: 'Units',
      hide: !tableConfig.levelOne.units,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={`text-right`}>
            <Tooltip id={`${prop.id}-tooltip-units-tooltip`} />
            <a data-tooltip-id={`${prop.id}-tooltip-units-tooltip`} data-tooltip-content={`${prop.units}`}> {formatNum(prop.units)}</a>
          </div>

        );
      },
    },

    {
      key: 'unitsPrice',
      title: 'Unit price',
      hide: !tableConfig.levelOne.unitPrice,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={`text-right`}>
            <Tooltip id={`${prop.id}-tooltip-unit-price-tooltip`} />
            <a data-tooltip-id={`${prop.id}-tooltip-unit-price-tooltip`} data-tooltip-content={`${prop.unitPrice}`}>{formatMoney(prop.unitPrice, prop.currency)}</a>
          </div>
        );
      },
    },

    {
      key: 'cost',
      title: 'Cost',
      hide: !tableConfig.levelOne.cost,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={`text-right`}>
            {prop.investment.name == 'Cash'
              ? '-'
              : formatMoney(prop.cost, prop.currency)}
          </div>
        );
      },
    },
    {
      key: 'invDate',
      title: 'Date invested',
      hide: !tableConfig.levelOne.invDate,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={`text-right`}>
            {prop.phase == 10 || prop.investment.name == 'Cash'
              ? '-'
              : formatStringToDate(prop.invDate)}
          </div>
        );
      },
    },
    {
      key: 'lastValuedAt',
      title: 'Date valued',
      hide: !tableConfig.levelOne.lastValuedAt,
      headerClassName: 'text-right',
      render: (_, prop) => {
        return (
          <div className={`text-right`}>
            {' '}
            {formatStringToDate(prop.lastValuedAt)}
          </div>
        );
      },
    },
    {
      key: 'action',
      title: '',
      render: (_, prop) => {
        const documents = useDocumentsForInvestment(prop.id);
        const hasDocuments = documents && documents.length > 0;

        return (
          <div className={`flex flex-col flex-end float-right mt-2`}>
            <div
              className={`${
                props.isDfm ? 'hidden' : 'flex'
              } flex-row align-middle justify-end`}
              onClick={() => {
                props.setSideBar({
                  holding: prop,
                  openType: 'INVESTMENT',
                });
              }}>
              <h5 className="underline text-bond cursor-pointer mt-1.5">
                View details
              </h5>
              <ChevronRight className="h-6 fill-bond" />
            </div>

            {hasDocuments && (
              <div
                className={`${
                  props.isDfm ? 'hidden' : 'flex'
                } flex-row align-middle justify-end`}
                onClick={() => {
                  props.setSideBar({
                    holding: prop,
                    openType: 'DOCS',
                  });
                }}>
                <h5 className="underline text-bond cursor-pointer mt-1.5">
                  View documents
                </h5>
                <ChevronRight className="h-6 fill-bond" />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  if (props.isDfm) {
    columns.pop(); // remove action column if it's DFM
  }

  function getTableData(holdings: NonCustodyHolding[]): TableData[] {
    const tableData: TableData[] = [];

    const holdingFiltered = investmentService.getTopLevelInvestments(holdings);

    holdingFiltered.map((value) => {
      if (value.phase === 20 || value.phase === null) {
        tableData.push(value);
      } else {
        const row: TableData = { ...value, bgColour: '#eeedeb' };
        tableData.push(row);
      }
    });

    return tableData;
  }

  return (
    <Table
      columns={columns}
      data={getTableData(props.nonCustodyHoldings)}
      className={'w-full'}
      style={{ width: '100%' }}
      data-cy="investmentTable"
    />
  );
}

export default DrilldownTable;