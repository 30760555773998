import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { NonCustodyHolding } from '../../dto/Holding';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import useWindowSize from '../../hooks/useWindowSize';
import SidePanel from '../../components/Investments/SidePanel';
import { renderSideContent } from '../../components/Investments/SidePanelContent';
import ContentComponent from '../../components/ContentContainer/Content';
import LoadingBlock from '../../components/Loading/LoadingBlock';
import TableSort from '../../components/Investments/InvestmentTableSort';
import TrendingOffersBox from '../../components/PageSection/TrendingOffersBox';
import PaginationButtons from '../../components/Pagination/Pagination';
import DrilldownTable from '../../components/Investments/DrilldownTable';
import DrilldownValue from '../../components/Investments/DrilldownValue';
import MobileInvestmentBlock from './MobileInvestmentBlock';
import ErrorBanner from '../../components/Error/ErrorBanner';
import WarningMessage from '../../components/Warning/WarningMessage';
import { DEFAULT_WARN_MSG } from '../../utils/Constants';
import AimIhtWarning from '../../components/Warning/AimIhtWarning';

type SortValues = 'investment' | 'value' | 'growth' | null;

export type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

const InvestmentDrillDown = () => {
  const { parent, child, wrapper, accountId, cat } = useParams();
  const [pager, setPager] = useState(1);
  const [sideBar, setSideBar] = useState<SideBarContent>(null);
  const [desc, setDesc] = useState<boolean>(true);
  const [sort, setSort] = useState<SortValues>('investment');
  const { width } = useWindowSize();

  const { investmentService } = useDependencyInjector();

  const updatePage = (page: number) => {
    setPager(page);
  };

  const closeSideBar = () => {
    setSideBar(null);
  };

  

  const { isLoading, isError, data } = useQuery({
    queryKey: ['holdings', parent, child, wrapper, accountId],
    queryFn: async () => {
      if (wrapper && accountId) {
        return await investmentService.getNonCustodyHoldingsDfm(accountId);
      }
      return await investmentService.getNonCustodyHoldings(parent!, child!);
    },
    enabled:
      (parent !== undefined && child !== undefined) ||
      (wrapper !== undefined && accountId !== undefined),
  });

  const getTitleText = () => {
    if (parent) {
      return parent.replaceAll('_', ' ');
    }
    if (cat) {
      return cat.replaceAll('_', ' ');
    }
    return '';
  };

  const getSubtitleText = () => {
    if (child) {
      return child.replaceAll('_', ' ');
    }
    if (wrapper) {
      return wrapper.replaceAll('_', ' ');
    }
    return '';
  };

  // Compute cashValue without useState
  const cashValue = useMemo(() => {
    if (data) {
      return investmentService.getCashValue(data);
    }
    return 0;
  }, [data, investmentService]);

  // Compute filteredInvestments without useState
  const filteredInvestments = useMemo(() => {
    if (data) {
      const nonCashInvestments = investmentService.removeCashHolding(data);
      const topLevelInvestments = investmentService.getTopLevelInvestments(nonCashInvestments);
      return topLevelInvestments;
    }
    return [];
  }, [data, investmentService]);

  // Compute childrenInvestments without useState
  const childrenInvestments = useMemo(() => {
    if (data) {
      return data.filter(
        (value) =>
          value.parentHolding !== undefined && (value.invDate !== null || value.value > 0)
      );
    }
    return [];
  }, [data]);

  // Memoized sorted investments
  const sortedInvestments = useMemo(() => {
    if (filteredInvestments.length === 0) return [];

    const sortedData = [...filteredInvestments];

    // Define the sorting functions inside useMemo
    const sortingFunc = (a: any, b: any) => {
      if (a > b) {
        return desc ? 1 : -1;
      }
      if (a < b) {
        return desc ? -1 : 1;
      }
      return 0;
    };

    const alphaSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
      return sortingFunc(
        a.investment.name.toLowerCase(),
        b.investment.name.toLowerCase(),
      );
    };

    const growthSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
      return sortingFunc(a.growthPercentage, b.growthPercentage);
    };

    const valueSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
      return sortingFunc(a.value, b.value);
    };

    switch (sort) {
      case 'investment':
        sortedData.sort(alphaSort);
        break;
      case 'value':
        sortedData.sort(valueSort);
        break;
      case 'growth':
        sortedData.sort(growthSort);
        break;
      default:
        break;
    }

    return sortedData;
  }, [filteredInvestments, sort, desc])

  // Memoized paginated investments
  const MAX_PAGE_SIZE = 15;

  const numberOfPages = useMemo(() => {
    if (sortedInvestments.length === 0) return 1;
    return Math.ceil(sortedInvestments.length / MAX_PAGE_SIZE);
  }, [sortedInvestments]);

  const paginatedInvestments = useMemo(() => {
    if (sortedInvestments.length === 0) return [];
  
    // Ensure `pager` is within valid range
    const currentPage = Math.min(Math.max(pager, 1), numberOfPages);
  
    const startIndex = (currentPage - 1) * MAX_PAGE_SIZE;
    const endIndex = currentPage * MAX_PAGE_SIZE;
  
    return sortedInvestments.slice(startIndex, endIndex);
  }, [sortedInvestments, pager, numberOfPages]);
  

  if (isLoading) {
    return (
      <ContentComponent
        title={width > 700 ? 'My portfolio' : getTitleText()}
        subtitle={getSubtitleText()}
        secondaryTitle={width > 700 ? getTitleText() : ''}
      >
        <LoadingBlock />
        <WarningMessage
          message={
            child !== 'AIM_IHT_portfolios' ? DEFAULT_WARN_MSG : <AimIhtWarning />
          }
        />
        <TrendingOffersBox />
      </ContentComponent>
    );
  }

  if (isError && parent) {
    return (
      <ContentComponent
        title={width > 700 ? 'My portfolio' : getTitleText()}
        subtitle={getSubtitleText()}
        secondaryTitle={width > 700 ? getTitleText() : ''}
      >
        <ErrorBanner
          text={'An error occurred while loading holdings. Please try again.'}
          severe={true}
        />
        <WarningMessage
          message={
            child !== 'AIM_IHT_portfolios' ? DEFAULT_WARN_MSG : <AimIhtWarning />
          }
        />
        <TrendingOffersBox />
      </ContentComponent>
    );
  }

  return (
    <div>
      <SidePanel open={sideBar != null} close={closeSideBar}>
        {renderSideContent(sideBar, data)}
      </SidePanel>
      <ContentComponent
        title={width > 700 ? 'My portfolio' : parent || wrapper ? getTitleText() : ''}
        subtitle={getSubtitleText()}
        secondaryTitle={width > 700 ? getTitleText() : ''}
      >
        <div className="w-[100%] h-fit mb-12">
          {data && !isLoading && (
            <>
              {width > 1200 && (
                <>
                  {child === 'AIM_IHT_portfolios' && (
                    <WarningMessage message={<AimIhtWarning />} />
                  )}
                  <DrilldownValue
                    totalValue={investmentService.totalUpHoldings(data)}
                    isDfm={accountId != null || accountId !== undefined}
                    setSideBar={setSideBar}
                    cashValue={cashValue}
                  />
                  <TableSort setSort={setSort} sort={sort} setDesc={setDesc} desc={desc} />
                  <DrilldownTable
                    nonCustodyHoldings={paginatedInvestments}
                    childrenCustodyHoldings={childrenInvestments}
                    setSideBar={setSideBar}
                    isDfm={accountId != null || accountId !== undefined}
                  />
                </>
              )}

              {width < 1200 && (
                <>
                  {child === 'AIM_IHT_portfolios' && (
                    <WarningMessage message={<AimIhtWarning />} />
                  )}
                  <DrilldownValue
                    totalValue={investmentService.totalUpHoldings(data)}
                    isDfm={accountId != null || accountId !== undefined}
                    setSideBar={setSideBar}
                    cashValue={cashValue}
                  />
                  {paginatedInvestments.map((value, index) => (
                    <MobileInvestmentBlock
                      holding={value}
                      setSideBar={setSideBar}
                      key={index}
                      childrenInvestments={childrenInvestments}
                      isDfm={accountId != null || accountId !== undefined}
                    />
                  ))}
                </>
              )}
              <div className="w-full">
                {numberOfPages > 1 && paginatedInvestments.length > 0 && (
                  <PaginationButtons
                    pageCount={numberOfPages}
                    onPageChange={updatePage}
                  />
                )}
              </div>
            </>
          )}
          {child !== 'AIM_IHT_portfolios' && (
            <WarningMessage message={DEFAULT_WARN_MSG} />
          )}
          <TrendingOffersBox />
        </div>
      </ContentComponent>
    </div>
  );
};

export default InvestmentDrillDown;
