import React, { useEffect, useRef, useState } from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import ButtonComponent from '../../components/Buttons/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as MailIcon } from '../../images/icons/icon-secure-messages.svg';
import './TinyMCE.css';
import { ReactComponent as AttachmentIcon } from '../../images/icons/icon-paperclip.svg';
import { Editor } from '@tinymce/tinymce-react';
import FileDescriptor from '../../dto/FileDescriptor';
import { useDependencyInjector } from '../../context/DependencyInjector';
import ErrorToast from '../../components/Toast/ErrorToast';
import { IToast } from '../../components/Toast/Toast';
import fileDownload from 'js-file-download';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SecureMessageCreate } from '../../dto/SecureMessage';
import ErrorBanner from '../../components/Error/ErrorBanner';
import { ReactComponent as PencilIcon } from '../../images/icons/icon-pencil.svg';

import { Editor as TinyMCEEditor } from 'tinymce';
import { Button } from 'private-wc-ui';

interface BannerNotice {
  msg: string;
  show: boolean;
}

function NewMessage() {
  const nav = useNavigate();

  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const { fileUploadService, documentService, secureMessageService } = useDependencyInjector();

  const [uploadedFiles, setUploadedFiles] = React.useState<FileDescriptor[]>([]);

  const [errorToast, setErrorToast] = useState<IToast | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [noticeBanner, setNoticeBanner] = useState<BannerNotice | null>(null);

  const [isDraftSubmit, setDraftSubmit] = useState(false);

  const [initialMsg, setInitialMsg] = useState('');
  const [initialSubject, setInitialSubject] = useState('');


  const loadDraftMutation = useMutation({
    mutationFn: () => {
      return secureMessageService.getSecureMessage(searchParams.get('draftId')!);
    },
    onSuccess: (data) => {
      // we have to attempt both here as there is a timing issue
      setInitialSubject(data.subject);
      formik.setFieldValue('subject', data.subject);
      setInitialMsg(data.body);
      formik.setFieldValue('message', data.body);
    },
  });

  const loadDraftAttachmentsMutation = useMutation({
    mutationFn: () => {
      return secureMessageService.getSecureMessagesFiles(searchParams.get('draftId')!);
    },
    onSuccess: (data) => {
      setUploadedFiles(prevState => prevState.concat(data));
    },
  });


  // used for when it's a draft
  useEffect(() => {
    if (searchParams.has('draftId')) {
      loadDraftMutation.mutate();
      loadDraftAttachmentsMutation.mutate();
    }
  }, [searchParams]);


  useEffect(() => {
    if (errorToast?.show) {
      const timeOut = setTimeout(() => setErrorToast(null), 6000);
      return () => clearTimeout(timeOut);
    }
  }, [errorToast]);

  useEffect(() => {
    if (noticeBanner?.show) {
      const timeOut = setTimeout(() => setNoticeBanner(null), 6000);
      return () => clearTimeout(timeOut);
    }
  }, [noticeBanner]);

  const downloadFile = async (fileDesc: FileDescriptor) => {
    const response = await documentService.getDocument(fileDesc.id);
    fileDownload(response.media as Blob, response.fileName);
  };

  const deleteMutation = useMutation({
    mutationFn: (file: FileDescriptor) => {
      return fileUploadService.removeUploadFiles(file);
    },
    onSuccess: (data) => {
      removeAttachment(data);
    },
    onError: (() => {
      const msg = { message: 'An error occurred deleting the file upload. Please try again.', show: true } as IToast;
      setErrorToast(msg);
    }),
  });

  const mutation = useMutation({
    mutationFn: (msg: SecureMessageCreate) => {
      if (searchParams.has('draftId')) {
        return secureMessageService.updateMessage(msg);
      }
      return secureMessageService.createSecureMessage(msg);
    },
    onSuccess: (data) => {
      setUploadedFiles([]);
      if (isDraftSubmit) {
        setNoticeBanner({ msg: 'Secure Message draft saved', show: true });
        setDraftSubmit(false);
      } else {
        setNoticeBanner({ msg: 'Secure Message Successfully sent.', show: true });
      }
      formik.resetForm();
      queryClient.invalidateQueries(['messages', 'Inbox']);
      queryClient.invalidateQueries(['messages', 'Sent']);
      queryClient.invalidateQueries(['messages', 'Drafts']);
      queryClient.invalidateQueries(['messages', 'Deleted']);
      setInitialMsg('');
      setInitialSubject('');
    },
    onError: (() => {
      const msg = { message: 'An creating the secure message. Please try again.', show: true } as IToast;
      setErrorToast(msg);
      setDraftSubmit(false);
    }),
  });


  const removeAttachment = (fileToDelete: FileDescriptor) => {
    setUploadedFiles(prevState => prevState.filter((file) => file !== fileToDelete));
  };


  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;

    // lets set max of 5 files to be attached to one message
    if (selectedFiles && (selectedFiles.length + uploadedFiles.length > 5)) {
      setErrorToast({ message: 'The max number of files that can be uploaded is 5.', show: true });
      return;
    }

    //
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (file.size > 10 * 1024 * 1024) {
        setErrorToast({ message: 'The maximum file size is 10MB.', show: true });
        return;
      }
    }

    try {
      const result = await fileUploadService.uploadFiles(selectedFiles);
      setUploadedFiles(prevState => prevState.concat(result));
    } catch (e) {
      setErrorToast({ message: 'An error occurred during the file upload. Please try again.', show: true });
    }
  };

  const onEditorChange = (content: string, editor: TinyMCEEditor) => {
    formik.setFieldValue('message', content);
  };


  const formik = useFormik({
    initialValues: { subject: initialSubject, message: initialMsg },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      subject: yup.string().required('A subject is required').min(5, 'A message must be a minimum of 5 characters'),
      message: yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      const fileIds = [] as string[];

      uploadedFiles.forEach(
        (value, index) => {
          fileIds.push(value.id);
        },
      );

      const msg = {
        id: searchParams.has('draftId') ? searchParams.get('draftId') : null,
        subject: values.subject,
        body: values.message,
        folder: 30,
        attachmentIds: fileIds,
      } as SecureMessageCreate;

      mutation.mutate(msg);
    },
  });


  return (
    <ContentComponent
      title="Secure messages"
      secondaryTitle="New message"
      subtitle="To: Wealth Club">
      <div className="w-[100%] h-fit mb-12 ">
        <div
          className="flex flex-row justify-end mb-2"
        >
          <div className="uppercase text-bond underline cursor-pointer select-none w-auto text-center text-sm font-bold" onClick={() => {
            nav('/inbox');
          }}>
            Return to Inbox
          </div>
        </div>

        {noticeBanner && noticeBanner.show && (
          <ErrorBanner severe={false} text={noticeBanner.msg} />
        )}

        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col">
            <div>
              <label>
                <h5>Subject</h5>
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                value={formik.values.subject == null ? '' : formik.values.subject}
                onChange={formik.handleChange}
                className="h-[40px] mt-3 w-full p-3 border-2 focus:outline-midas focus:shadow-2xl transition-all duration-500 border-midasLight mb-4"
              ></input>
              <Editor
                apiKey={'x0kb2cseqtpj6x79hcf7wgvo4ep7xasj4833gn6wj192tb05'}
                value={formik.values.message}
                initialValue={formik.initialValues.message}
                onEditorChange={onEditorChange}
                onBlur={props => {
                  formik.setTouched({ ...formik.touched, ['message']: true });
                }}
                init={{
                  content_css: '/TinyMCE.css',
                  body_css: 'mce-content-body',
                  content_style: `
                    /* Regular */
                    @font-face {
                      font-family: 'Proxima Nova';
                      src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_regular-webfont.woff2') format('woff2'),
                           url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_regular-webfont.woff') format('woff');
                      font-weight: 400;
                      font-style: normal;
                    }
                    
                    /* Italic */
                    @font-face {
                      font-family: 'Proxima Nova';
                      src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_regular_italic-webfont.woff2') format('woff2'),
                           url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_regular_italic-webfont.woff') format('woff');
                      font-weight: 400;
                      font-style: italic;
                    }
                    
                    /* Bold */
                    @font-face {
                      font-family: 'Proxima Nova';
                      src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_bold-webfont.woff2') format('woff2'),
                           url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_bold-webfont.woff') format('woff');
                      font-weight: bold;
                      font-style: normal;
                    }
                    
                    /* Bold Italic */
                    @font-face {
                      font-family: 'Proxima Nova';
                      src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_bold_italic-webfont.woff2') format('woff2'),
                           url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_bold_italic-webfont.woff') format('woff');
                      font-weight: bold;
                      font-style: italic;
                    }
                
                    body {
                      font-family: 'Proxima Nova', sans-serif !important;
                    }
                  `,
                  placeholder: 'Write your message here...',
                  height: 250,
                  menubar: false,
                  textcolor_rows: '4',
                  toolbar: 'bold italic',
                  statusbar: false,
                }}
              />
              <div className="w-full min-h-[100px]  border-2 border-midasLight flex flex-col p-4 ">
                <input
                  type={'file'}
                  ref={fileInputRef}
                  multiple={true}
                  accept={
                    'image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.xlsx, .xls, .csv'
                  }
                  onChange={onChange}
                  className={'w-0 h-0 block'}
                />
                <div
                  className={
                    'flex flex-col gap-2 justify-start md:flex-row relative uppercase'
                  }>

                  <div className={'flex flex-row gap-2'}>
                    <AttachmentIcon
                      className={'h-[20px] w-auto cursor-pointer text-bond fill-bond'}
                      onClick={
                        () => {
                          fileInputRef.current?.click();
                        }
                      }
                    />
                    <div>
                      <h5
                        className="text-bond uppercase underline cursor-pointer flex flex-row " onClick={
                        () => {
                          fileInputRef.current?.click();
                        }
                      }>
                        Add Attachment(S)
                      </h5>
                      <p className="text-xs md:absolute md:top-8 ">
                        Max 10MB/.pdf .png .jpeg .doc .docx .csv .xls .xlsx
                      </p>
                    </div>
                  </div>

                  <hr className="border-1 border-vistaWhite mt-4 min-w-[100%] md:hidden"></hr>

                  {uploadedFiles.map((value, index) => {
                    return (
                      <>
                        <p
                          className={`text-downy underline truncate cursor-pointer lowercase`}
                          onClick={async () => {
                            await downloadFile(value);
                          }}
                          key={index}>
                          {value.name}
                        </p>
                        <p
                          className={'cursor-pointer'}
                          onClick={() => {
                            deleteMutation.mutate(value);
                          }}>
                          x
                        </p>
                      </>
                    );
                  })}
                </div>
              </div>


            </div>
            <div className="flex flex-col gap-4 md:flex-row mt-5 align-middle items-center">

              <Button
                className={'lg:w-[15%] group'}
                label={
                  <div className={'flex flex-row gap-2 justify-center align-middle items-center uppercase text-tuxedo group-hover:text-white'}>
                    <MailIcon className={'w-[24px] h-[24px] group-hover:fill-white'} />
                    <span>Send message</span>
                  </div>
                }
                theme={'action'}
                type={'submit'}
                disabled={formik.isSubmitting}
              >
              </Button>

              <Button className={'lg:w-[15%] group'}
                      label={
                        <div className={'flex flex-row gap-2 justify-center align-middle items-center uppercase text-vistaWhite group-hover:text-tuxedo'}>
                          <PencilIcon className={'w-[24px] h-[24px] fill-vistaWhite group-hover:fill-tuxedo'} />
                          <span>Save as draft</span>
                      </div>
                      }
                      theme={'secondary'}
                      type={'button'}
                      disabled={isDraftSubmit}
                      onClick={() => {
                        setDraftSubmit(true);
                        const fileIds = [] as string[];

                        uploadedFiles.forEach(
                          (value, index) => {
                            fileIds.push(value.id);
                          },
                        );
                        const msg = {
                          id: searchParams.has('draftId') ? searchParams.get('draftId') : null,
                          subject: formik.values.subject,
                          body: formik.values.message,
                          folder: 20,
                          attachmentIds: fileIds,
                        } as SecureMessageCreate;

                        mutation.mutate(msg);
                      }}>
              </Button>


            </div>
            {formik.errors.subject && formik.touched.subject ? (
              <div style={{ color: 'red' }}>{formik.errors.subject}</div>
            ) : null}

            {formik.errors.message && formik.touched.message ? (
              <div style={{ color: 'red' }}>{formik.errors.message}</div>
            ) : null}
          </div>
        </form>
        <ErrorToast message={errorToast?.message ? errorToast.message : ''}
                    show={errorToast?.show == null ? false : errorToast.show} closeFn={() => {
          setErrorToast(null);
        }} />

      </div>
    </ContentComponent>
  );
}

export default NewMessage;
