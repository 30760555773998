import React, { useEffect, useState } from 'react';
import smIcon from '../../images/icons/icon-featured.svg';
import ButtonComponent from '../Buttons/Button';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useNavigate } from 'react-router';
import { Button } from 'private-wc-ui';

interface NotificationProps {
  notification: boolean;
  setNotification: (value: React.SetStateAction<boolean>) => void;
}

function NotificationBanner(props: NotificationProps) {
  const { secureMessageService } = useDependencyInjector();
  const [message, setMessage] = useState<string>('');
  const navigation = useNavigate();

  const { data } = useQuery({
    queryKey: ['message'],
    queryFn: async () => {
      return await secureMessageService.getMessageCount();
    },
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (data && data > 0) {
      props.setNotification(true);
      if (data > 1) {
        setMessage('You have unread secure messages');
      } else {
        setMessage('You have an unread secure message');
      }
    } else {
      props.setNotification(false);
    }
  }, [data]);

  return (
    <div
      className={`${
        props.notification ? '' : 'hidden'
      } w-full h-[60px] bg-sand flex flex-row text-center align-middle items-center justify-between xl:pl-8 md:pl-4`}>
      <div className="flex flex-row w-[50%] align-middle items-center">
        <object
          className="md:inline max-h-8 select-none h-auto w-auto hidden"
          type="image/svg+xml"
          data={smIcon}></object>
        <h4 className="ml-[2%] select-none">{message}</h4>
      </div>
      <Button
        style={{
          width: 'auto',
          height: '70%',
          position: 'relative',
          marginRight: '2%',
        }}
        label={'View Messages'}
        theme={'primary'}
        onClick={() => navigation('/Inbox')}
      />
    </div>
  );
}

export default NotificationBanner;