import React from 'react';
import InvestmentPreview from './InvestmentPreview';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import ErrorBanner from '../Error/ErrorBanner';
import PortfolioHeader from './PortfolioHeader';

export interface OverviewProp {
  humanCategory?: string;
}

const InvestmentOverview = (props: OverviewProp) => {
  const { investmentService } = useDependencyInjector();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['account', props.humanCategory],
    queryFn: async () => {
      return await investmentService.getSubCatOverview(props.humanCategory!.replaceAll(" ","_"));
    },
    enabled: props.humanCategory != undefined
  });

  if (isLoading) {
    return (
      <div className="w-full mt-8 bg-vistaWhite  border-2 border-midas animate-pulse h-[200px]"></div>
    );
  }

  if (!isLoading && isError) {
    return (
      <div className="w-full mt-8 bg-vistaWhite border-2 border-midas h-[200px]">
        <ErrorBanner severe={true} text="An error occured loading portfolio" />
      </div>
    );
  }

  if (data && (!data.overview || data.overview.length == 0)) {
    return (
      <ErrorBanner
        severe={false}
        text={`You have no ${props.humanCategory}. Browse our currently available offers below`}
      />
    );
  }


  return (
    <div className="w-full pb-3 h-auto bg-vistaWhite mt-[2%] flex flex-col border-2 border-midas">
      <PortfolioHeader
                       value={data != undefined ? investmentService.getTotalInvested(data) : 0} />

      <div className="grid grid-cols-1 md:grid-cols-2 p-4 gap-4">
        {data &&
          data.overview.map((value, index) => {
            return (
              <InvestmentPreview
                key={index}
                name={value.investmentCategory.replaceAll('_', ' ')}
                value={value.totalValue}
                link={value.url}
              ></InvestmentPreview>
            );
          })}
      </div>
    </div>
  );
};

export default InvestmentOverview;
