import React, { CSSProperties, useEffect, useState } from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useQuery } from '@tanstack/react-query';
import LoadingBlock from '../../components/Loading/LoadingBlock';
import ErrorBanner from '../../components/Error/ErrorBanner';
import { CommissionSummary } from '../../dto/CommissionSummary';
import useWindowSize from '../../hooks/useWindowSize';
import { IColumnType, Table } from 'private-wc-ui';
import { formatMoney } from '../../utils/FormattingUtils';

function ChargesPage() {

  const { commissionService } = useDependencyInjector();


  const [commissionPeriod, setCommissionPeriod] = useState<string>();

  const { width } = useWindowSize();


  const { data: commissionData, isLoading: commissionIsLoading, isError: commissionIsError } = useQuery(
    {
      queryKey: ['commission'],
      queryFn:
        async () => {
          return await commissionService.getCommission();
        },
    },
  );


  const { data, isLoading, isError } = useQuery({
    queryKey: ['commission-years'],
    queryFn:
      async () => {
        return await commissionService.getCommissionYears();
      },
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setCommissionPeriod(data[0]);
    }

  }, [data]);

  interface TableData extends CommissionSummary  {
      bgColour?: CSSProperties["color"];
  }

  const columns: IColumnType<TableData>[] = [
    {
      key: 'name',
      title: 'Investment',
    },
    {
      key: 'type',
      title: 'Type',
    },
    {
      key: 'month',
      title: 'Month',
    },
    {
      key: 'commission',
      title: 'Commission received',
      render: (_, tableData) => {
        return <span>{formatMoney(tableData.value, tableData.currency)}</span>;
      },
    },

  ];


  const getCommissionToRender = (period: string | undefined): Array<CommissionSummary> => {
    if (commissionData) {
      return commissionData.filter(value => {
        return value.period === period;
      });
    }
    return [];

  };


  return (
    <div>
      <ContentComponent
        title="Account settings"
        subtitle="Commission and charges">

        <div className={'mb-16'}>
          <p className={'text-sm'}>Below are details of the third party payments Wealth Club received for
            investments
            generating ongoing commission. This report shows any commission received within the 12 month
            period shown
            which has been reconciled to our accountancy system – note this maybe subject to change.
            <br />
            <br />
            These figures do not include any rebates due to you. These are shown on your annual rebate
            statement. For
            total costs and charges, please see your provider’s annual costs and charges statement and Key
            Information
            Document where available.</p>


          {isLoading &&
            <div className={'w-full h-1/2'}>
              <LoadingBlock />
            </div>
          }

          {isError &&
            <div className={'w-full h-1/2'}>
              <ErrorBanner text={'An error occurred fetching commission. Please try again later.'}
                           severe={true} />
            </div>}

          {data && data.length > 0 &&
            <div className={'mt-6'}>
              <select
                className={`mt-2 bg-white mb-4 border-2 fade-in border-midasLight font-sans focus:outline-midasLight p-3 shadow-md md:w-auto w-full rounded`}
                onChange={event => {
                  setCommissionPeriod(event.target.value);
                }}
                value={commissionPeriod}

              >
                {data.map((value, index) =>
                  <option value={value} key={index}>{value}</option>)}

              </select>

              {commissionIsLoading &&
                <LoadingBlock />
              }

              {
                commissionIsError &&
                <ErrorBanner text={'An error occurred fetching commission. Please try again later.'}
                             severe={true} />
              }


              {commissionData && width > 1000 &&

                <Table data={getCommissionToRender(commissionPeriod) as TableData[]} columns={columns} style={{width:"100%"}} />
              }

              {
                commissionData && width < 1000 && <div className={'flex flex-col gap-4'}>
                  {getCommissionToRender(commissionPeriod).map((value, index) => {
                    return <div key={index}
                                className="w-full bg-grey-2  h-auto flex flex-col text-sm">
                      <h5 className="text-white text-lg bg-bond p-2">{value.name}</h5>
                      <div className="w-full flex flex-row ml-2 mt-2">
                        <h5>Type:</h5>
                        <div className="font-normal ml-2">
                          {value.type}
                        </div>
                      </div>
                      <hr className="border-1 w-full mt-2 mb-2 border-vistaWhite" />
                      <div className="w-full flex flex-row ml-2 mt-2">
                        <h5 className="">Month:</h5>
                        <div className="font-normal ml-2">
                          {value.month}
                        </div>
                      </div>
                      <hr className="border-1 w-full mt-2 mb-2 border-vistaWhite" />
                      <div className="w-full flex flex-row ml-2 mt-2">
                        <h5 className="">Commission Received:</h5>
                        <div className="font-normal ml-2">
                          {formatMoney(value.value, value.currency)}
                        </div>
                      </div>
                    </div>
                  })}
                </div>

              }


            </div>
          }

          {
            data && data.length === 0 &&
            <div>
              <h6 className={'title text-sm mt-6'}> We have not received any ongoing commissions from
                investments
                you have made with us.</h6>
            </div>

          }


        </div>


      </ContentComponent>
    </div>
  )
    ;
}

export default ChargesPage;
