import React from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import AddressForm from '../../components/ContactDetails/AddressForm';
import ContactDetailsForm from '../../components/ContactDetails/ContactDetailsForm';
import PreferenceForm from '../../components/ContactDetails/PreferenceForm';

function ContactDetails() {
  return (
    <div>
      <ContentComponent
        title="Account settings"
        subtitle="Update contact details">
        <div className="w-[100%] h-fit mb-12">
          <div className="grid grid-cols-1 gap-6 p-4 md:grid-cols-2 md:gap-8 ">
            <div className="w-full ">
              <AddressForm />
            </div>
            <div className={'text-left w-full'}>
              <ContactDetailsForm />
              <PreferenceForm />
            </div>

            <div>
            </div>
          </div>

        </div>
      </ContentComponent>
    </div>
  );
}

export default ContactDetails;
