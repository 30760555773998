import { useFormik } from 'formik';
import React from 'react';
import BankDetails from '../../dto/BankDetails';
import ButtonComponent from '../Buttons/Button';
import LoginInput from '../Inputs/Input';
import { maskSortCode } from '../../utils/BankHelperUtils';
import { ChangeBankDetailForm } from './BankDetailsTypes';
import { isDisabledForViewAs } from '../../utils/HelperUtils';
import useToken from '../../hooks/useToken';
import { Button } from 'private-wc-ui';

interface UpdateDetailsProps {
  data: BankDetails;
  onSubmit: (data: ChangeBankDetailForm) => void;
  validate: (values: ChangeBankDetailForm) => void;
}


function UpdateDetails(props: UpdateDetailsProps) {

  const token = useToken();

  const initialFormValues: ChangeBankDetailForm = {
    accountNumber: props.data.accountNumber ? props.data.accountNumber : '',
    rollNumber: props.data.rollNumber ? props.data.rollNumber : '',
    sortCode: props.data.sortCode ? maskSortCode(props.data.sortCode) : '',
  };


  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    onSubmit: props.onSubmit,
    validate: props.validate,
  });


  const handleSortCodeOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const cleanedString = val.replace(' ', '').replace(/-/g, '');
    if (cleanedString.length > 6) {
      return;
    }
    const result = maskSortCode(cleanedString);
    if (result != '') {
      formik.setFieldValue('sortCode', result);
    } else {
      formik.setFieldValue('sortCode', val);
    }
  };

  return (
    <div className="fade-in">
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex gap-2 flex-col md:w-2/3 2xl:w-1/3">
          <label
            className="font-sans font-bold text-tuxedo text-[14px]"
            htmlFor="accountNumber">
            Account number
          </label>
          <LoginInput
            type={'text'}
            id={'accountNumber'}
            name={'accountNumber'}
            maxLength={8}
            minLength={8}
            value={formik.values.accountNumber}
            onChange={formik.handleChange}
            disabled={props.data.validationStatus == 'Pending' || isDisabledForViewAs(token)}
            error={
              formik.errors.accountNumber != undefined &&
              formik.touched.accountNumber
            }
          />
          {formik.errors.accountNumber && formik.touched.accountNumber ? (
            <div style={{ color: 'red' }}>{formik.errors.accountNumber}</div>
          ) : null}
          <label
            className="font-sans font-bold text-tuxedo text-[14px] mt-4"
            htmlFor="newSortCode">
            Sort code
          </label>
          <LoginInput
            type={'text'}
            id={'sortCode'}
            name={'sortCode'}
            maxLength={8}
            minLength={8}
            value={formik.values.sortCode}
            disabled={props.data.validationStatus == 'Pending' || isDisabledForViewAs(token)}
            onChange={handleSortCodeOnChange}
            error={
              formik.errors.sortCode != undefined && formik.touched.sortCode
            }
          />
          {formik.errors.sortCode && formik.touched.sortCode ? (
            <div style={{ color: 'red' }}>{formik.errors.sortCode}</div>
          ) : null}
          <label
            className="font-sans font-bold text-tuxedo text-[14px] mt-4"
            htmlFor="rollNumber">
            Building society roll number
          </label>
          <LoginInput
            type={'text'}
            id={'rollNumber'}
            name={'rollNumber'}
            value={formik.values.rollNumber}
            onChange={formik.handleChange}
            disabled={props.data.validationStatus == 'Pending' || isDisabledForViewAs(token)}
            error={
              formik.errors.rollNumber != undefined && formik.touched.rollNumber
            }
          />
          {formik.errors.rollNumber && formik.touched.rollNumber ? (
            <div style={{ color: 'red' }}>{formik.errors.rollNumber}</div>
          ) : null}
          <Button
            label={'Submit bank details'}
            theme={'primary'}
            type={'submit'}
            style={{ marginTop: '10px' }}
            disabled={props.data.validationStatus == 'Pending' || isDisabledForViewAs(token) || formik.isSubmitting}
          />


        </form>
      </div>
    </div>
  );
}

export default UpdateDetails;
