import React, { useMemo } from 'react';
import { formatMoney, formatNum } from '../../../../utils/FormattingUtils';
import MobileUnderlying from './MobileUnderlying';
import { NonCustodyHolding } from '../../../../dto/Holding';
import { translateHoldingPhase } from '../../../../utils/HelperUtils';
import { useParams } from 'react-router-dom';
import { formatStringToDate } from '../../../../utils/DateUtils';
import useTableConfig from '../../../../hooks/useTableConfig';
import { TableConfig } from '../../../../config/Config';
import { sortByInvestmentName, sortInvestmentsByPhaseAndInvDate } from '../../../../utils/SortUtils';
import { Tooltip } from 'react-tooltip';
import { useDependencyInjector } from '../../../../context/DependencyInjector';
import { useQuery } from '@tanstack/react-query';

interface UnderlyingProps {
  topLevelID: string;
  data: NonCustodyHolding[];
  setHoldingHistoryId: (id: string) => void;
  currentHistoryId: string | null;
}

function UnderlyingInvestments(props: UnderlyingProps) {

  const config = useTableConfig();

  const { child, cat } = useParams();

  const retrieveConfig = (child: string | undefined, cat: string | undefined): TableConfig => {
    return child === undefined ? config.getConfig(cat) : config.getConfig(child);
  };


  const tableConfig = useMemo(() => retrieveConfig(child, cat), [child, cat]);
  const { investmentService } = useDependencyInjector();

  const { accountId } = useParams();
  const tableHeader = props.data.filter(
    (f) => f.parentHolding == props.topLevelID,
  ).sort(sortByInvestmentName);

  if (cat){
    return <></>
  }


  if (window.innerWidth > 700) {

    return (
      <div>
        {tableHeader.map((value, index) => {
          const children = props.data.filter(
            (f) => f.parentHolding == value.id,
          ).sort(sortInvestmentsByPhaseAndInvDate);

          const { data: countData } = useQuery({
            queryKey: ['history-count', value.id, accountId],
            queryFn: async () => {
              if (value.id) {
                return await investmentService.getHoldingHistoryCount(value.id);
              }
              if (accountId) {
                return await investmentService.getHoldingHistoryCountDfm(accountId);
              }
              return { holdingHistoryCount: 0 };
            },
            enabled: !!value.id || !!accountId,
          });

          const historyCount = countData?.holdingHistoryCount ?? 0;


          return (
          <table className="w-full bg-sand mt-6 table-auto" key={index} hidden={accountId != undefined}>
              <thead className="h-[5vh] bg-bond">
              <tr className="text-white text-left">
                <th className={`w-[20%] ${tableConfig.levelTwo && tableConfig.levelTwo.investment.name ? 'visible' : 'hidden'}`}></th>
                <th className={`${tableConfig.levelTwo && tableConfig.levelTwo.phase ? 'visible' : 'hidden'}`}>Status</th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.value ? 'visible' : 'hidden'}`}>Value</th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.cost ? 'visible' : 'hidden'}`}>Cost</th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.growth ? 'visible' : 'hidden'}`}>Growth</th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.growthPercentage ? 'visible' : 'hidden'}`}>Growth (%)</th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.units ? 'visible' : 'hidden'}`}>Units</th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.unitPrice ? 'visible' : 'hidden'}`}>Unit<br/>price</th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.invDate ? 'visible' : 'hidden'}`}>Date<br/>invested
                </th>
                <th className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.lastValuedAt ? 'visible' : 'hidden'}`}>Date<br/>valued
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td
                  className={`p-2 font-bold ${tableConfig.levelTwo && tableConfig.levelTwo.investment.name ? 'visible' : 'hidden'}`}>{value.investment.name}</td>
                <td
                  className={`${tableConfig.levelTwo && tableConfig.levelTwo.phase ? 'visible' : 'hidden'}`}>{translateHoldingPhase(value.phase)}</td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.value ? 'visible' : 'hidden'}`}>
                  <Tooltip id={`${value.id}-tooltip-value-tooltip`} />
                  <a data-tooltip-id={`${value.id}-tooltip-value-tooltip`} data-tooltip-content={`${value.value}`}> {formatMoney(
                    value.value,
                    value.currency
                  )}</a>

                </td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.cost ? 'visible' : 'hidden'}`}>
                  {formatMoney(
                    value.cost,
                    value.currency
                  )}
                </td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.growth ? 'visible' : 'hidden'}`}>
                  {formatMoney(
                    value.growth,
                    value.currency
                  )}
                </td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.growthPercentage ? 'visible' : 'hidden'}`}>
                  {formatNum(
                    value.growthPercentage,
                  )}
                </td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.units ? 'visible' : 'hidden'}`}>
                  <Tooltip id={`${value.id}-tooltip-units-tooltip`} />
                  <a data-tooltip-id={`${value.id}-tooltip-units-tooltip`} data-tooltip-content={`${value.units}`}>   {formatNum(value.units)}</a>
                </td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo &&  tableConfig.levelTwo.unitPrice ? 'visible' : 'hidden'}`}>
                  <Tooltip id={`${value.id}-tooltip-unit-price-tooltip`} />
                  <a data-tooltip-id={`${value.id}-tooltip-unit-price-tooltip`} data-tooltip-content={`${value.unitPrice}`}>{formatMoney(value.unitPrice, value.currency)}</a>
                </td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.invDate ? 'visible' : 'hidden'}`}>
                  {formatStringToDate(value.invDate)}
                </td>
                <td className={`text-right pr-2 ${tableConfig.levelTwo && tableConfig.levelTwo.lastValuedAt ? 'visible' : 'hidden'}`}>
                  {formatStringToDate(value.lastValuedAt)}
                </td>

                {historyCount > 0 && (
                  <td className={'text-right pr-2'}>
                    {value.id === props.currentHistoryId ? (
                      <a
                        onClick={() => {
                          props.setHoldingHistoryId(value.id);
                        }}
                        className={'text-sm text-bond underline hover:cursor-pointer font-bold'}
                      >
                        Close history
                      </a>
                    ) : (
                      <a
                        className={'text-sm text-bond underline hover:cursor-pointer font-bold'}
                        onClick={() => {
                          props.setHoldingHistoryId(value.id);
                        }}
                      >
                        View history
                      </a>
                    )}
                  </td>
                )}

              </tr>
              {children.filter(hold => hold.invDate != null || hold.value > 0).map((value, index) => {
                return (
                  <tr key={index}>
                    <td
                      className={`p-2 ${
                        tableConfig.levelTwo &&
                        tableConfig.levelTwo.investment.name
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      {value.investment.name}
                    </td>
                    <td
                      className={`${
                        tableConfig.levelTwo && tableConfig.levelTwo.phase
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      {translateHoldingPhase(value.phase)}
                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo && tableConfig.levelTwo.value
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      <a
                        data-tooltip-id={`${value.id}-tooltip-value-tooltip`}
                        data-tooltip-content={`${value.value}`}>
                        {' '}
                        {formatMoney(value.value, value.currency)}
                      </a>
                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo && tableConfig.levelTwo.cost
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      {formatMoney(value.cost, value.currency)}
                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo && tableConfig.levelTwo.growth
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      {formatMoney(value.growth)}
                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo &&
                        tableConfig.levelTwo.growthPercentage
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      {formatNum(value.growthPercentage)}
                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo && tableConfig.levelTwo.units
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      <a
                        data-tooltip-id={`${value.id}-tooltip-units-tooltip`}
                        data-tooltip-content={`${value.units}`}>
                        {formatNum(value.units)}
                      </a>
                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo && tableConfig.levelTwo.unitPrice
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      <a
                        data-tooltip-id={`${value.id}-tooltip-unit-price-tooltip`}
                        data-tooltip-content={`${value.unitPrice}`}>
                        {formatMoney(value.unitPrice, value.currency)}
                      </a>

                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo && tableConfig.levelTwo.invDate
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      {formatStringToDate(value.invDate)}
                    </td>
                    <td
                      className={`text-right pr-2 ${
                        tableConfig.levelTwo &&
                        tableConfig.levelTwo.lastValuedAt
                          ? 'visible'
                          : 'hidden'
                      }`}>
                      {formatStringToDate(value.lastValuedAt)}
                    </td>
                    <td className={'text-right pr-2'}>
                      {value.id === props.currentHistoryId ? (
                        <a
                          onClick={() => {
                            props.setHoldingHistoryId(value.id);
                          }}
                          className={
                            'text-sm text-bond underline hover:cursor-pointer'
                          }>
                          Close history
                        </a>
                      ) : (
                        <a
                          className={`${
                            translateHoldingPhase(value.phase) === 'Pending'
                              ? 'hidden'
                              : 'visible'
                          } text-sm text-bond underline font-bold hover:cursor-pointer `}
                          onClick={() => {
                            props.setHoldingHistoryId(value.id);
                          }}>
                          View history
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  } else {
    return <MobileUnderlying data={props.data} topLevelID={props.topLevelID} currentHistoryId={props.currentHistoryId}
                             setHoldingHistoryId={props.setHoldingHistoryId} />;
  }
}

export default UnderlyingInvestments;