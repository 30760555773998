import { createContext, useContext } from 'react';

export type DropDowns = 'My portfolio' | 'Account settings' | null;

export interface DropDownContextType {
  openDropDown: DropDowns;

  setDropDown: (dropDown: DropDowns) => void;
}

const useDropDownContext = () => {
  return useContext(DropDownContext);
};

const DropDownContext = createContext<DropDownContextType>(
  {} as DropDownContextType,
);

export { DropDownContext, useDropDownContext };
