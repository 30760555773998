import { useQuery } from '@tanstack/react-query';
import React from 'react';
import PortfolioHeader from '../Investments/PortfolioHeader';
import InvestmentPreview from '../Investments/InvestmentPreview';
import ErrorBanner from '../Error/ErrorBanner';
import { useDependencyInjector } from '../../context/DependencyInjector';

function DashboardPortfolio() {
  const {investmentService} = useDependencyInjector()

  const { isLoading, isError, data } = useQuery({
    queryKey: ['accountOverview'],
    queryFn: investmentService.getInvestmentOverview,
  });

  if (!isLoading) {
    return (
      <div className="w-full h-auto mt-8 md:mt-[2%] bg-vistaWhite  border-2 border-midas relative">
        <PortfolioHeader
          value={
            !isLoading && !isError && data ? investmentService.getTotalInvested(data) : 0
          }
        />
        <div className={'md:grid md:grid-cols-2 gap-4 p-4 flex flex-col'}>
          {data?.overview.map((over) => {
            return (<InvestmentPreview key={over.investmentCategory}
                                       link={over.url}
                                       name={over.investmentCategory.replaceAll("_"," ")}
                                       value={over.totalValue}

            />);
          })}
        </div>


      </div>
    );
  }

  if (!isLoading && isError) {
    return (
      <div className="w-full mt-8 bg-vistaWhite border-2 border-midas h-[200px]">
        <ErrorBanner severe={true} text="An error occured loading portfolio" />
      </div>
    );

  }

  return (
    <div className="w-full mt-8 bg-vistaWhite  border-2 border-midas animate-pulse h-[200px]"></div>
  );

}

export default DashboardPortfolio;


