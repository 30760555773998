import React, { useEffect, useState } from 'react';
import InterestsForm, {
  InterestsVal,
} from '../components/ContactDetails/InterestsForm';
import PauseMembershipForm from '../components/ContactDetails/PauseMembershipForm';
import { useDependencyInjector } from '../context/DependencyInjector';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerCircularSplit } from 'spinners-react';
import BlueBackgroundScreen from '../components/Screen/BlueBackgroundScreen';
import { Button } from 'private-wc-ui';
import HowOftenForm from '../components/ContactDetails/HowOftenForm';
import {
  ContactFrequencyPreferences,
  ContactPreferences,
} from '../dto/ContactPreferences';
import { OptOutMutation } from '../components/ContactDetails/Mut';
import moment from 'moment';
import { isDisabledForViewAs } from '../utils/HelperUtils';
import logo from '../images/wealth-club-portal-logo.svg';
import HowToContactForm from '../components/ContactDetails/HowToContactForm';
import PermPopUp from '../components/ContactDetails/PermPopUp';

type PreferencesState = {
  loaded: boolean;
  mustLogin: boolean;
  isError: boolean;
  enableApiCalls: boolean;
};

const defaultStartState = {
  loaded: false,
  mustLogin: false,
  isError: false,
  enableApiCalls: false,
} as PreferencesState;

function UnAuthedPreferences() {
  const { userService, unsubService, clientService } = useDependencyInjector();

  const queryClient = useQueryClient();

  const nav = useNavigate();

  const { wcid } = useParams();

  const [state, setState] = useState<PreferencesState>(defaultStartState);

  function userExistsForceLogin() {
    setState({
      mustLogin: true,
      enableApiCalls: false,
      isError: false,
      loaded: true,
    });
  }

  function userDoesNotExistUnAuthAccess() {
    setState({
      mustLogin: false,
      enableApiCalls: true,
      isError: false,
      loaded: true,
    });
  }

  function errorLoadingUser() {
    setState({
      mustLogin: false,
      enableApiCalls: false,
      isError: true,
      loaded: true,
    });
  }

  useEffect(() => {
    if (wcid) {
      userService
        .hasUserAssociated(wcid)
        .then((value) => {
          if (value) {
            userExistsForceLogin();
          } else {
            userDoesNotExistUnAuthAccess();
          }
        })
        .catch(() => {
          errorLoadingUser();
        });
    }
  }, [wcid]);

  const {
    data: contactPrefs,
    isLoading: isLoadingContactPrefs,
    isError: isErrorContactPrefs,
  } = useQuery({
    queryKey: ['contact-prefs'],
    queryFn: async () => {
      return await unsubService.getContactPreferences(wcid!);
    },
    enabled: state.enableApiCalls,
  });

  const contactPrefMutation = useMutation({
    mutationFn: (prefs: ContactPreferences) => {
      return unsubService.updateContactPreferences(prefs, wcid!);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['contact-prefs'], data);
      queryClient.invalidateQueries(['preferences-freq']);
    },
  });

  const {
    data: interestData,
    isLoading: interestLoading,
    isError: interestError,
  } = useQuery({
    queryKey: ['preferences-unauthed'],
    queryFn: async () => {
      return await unsubService.getProductInterests(wcid!);
    },
    enabled: state.enableApiCalls,
  });

  const interestMutation = useMutation({
    mutationFn: (interestsVal: InterestsVal) => {
      const cloneData = new Map(interestData);
      cloneData.set(interestsVal.name, interestsVal.value);
      return unsubService.updateInterests(cloneData, wcid!);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['preferences-unauthed'], data);
    },
  });

  const freqMutation = useMutation({
    mutationFn: (val: ContactFrequencyPreferences) => {
      // ensure that at least one check box is checked
      if (val.monthly && val.asap && val.weekly) {
        val.asap = true;
      }
      return unsubService.updateContactFrequency(val, wcid!);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['preferences-freq'], data);
    },
  });

  const {
    data: freqData,
    isLoading: freqLoading,
    isError: freqError,
  } = useQuery({
    queryKey: ['preferences-freq'],
    queryFn: async () => {
      return await unsubService.getContactFrequency(wcid!);
    },
    enabled: state.enableApiCalls,
  });

  const { data: clientData } = useQuery({
    queryKey: ['client-salutation'],
    queryFn: async () => {
      return await clientService.getClientFormalName(wcid!);
    },
    enabled: state.enableApiCalls,
  });

  const mutation = useMutation({
    mutationFn: (val: OptOutMutation) => {
      let date = null;
      if (val.snooze) {
        date = moment(new Date()).add(parseInt(val.snooze), 'M').toDate();
      }
      return unsubService.updateTempOptOut(date, wcid!);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['preferences-opt-out']);
    },
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ['preferences-opt-out'],
    queryFn: async () => {
      return await unsubService.getTempOptOut(wcid!);
    },
    enabled: state.enableApiCalls,
  });

  const mutationResub = useMutation({
    mutationFn: (val: OptOutMutation) => {
      let date = null;
      if (val.snooze) {
        date = moment(new Date()).add(parseInt(val.snooze), 'M').toDate();
      }
      return unsubService.updateTempOptOut(date,wcid!);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['preferences-opt-out']);
    },
  });

  if (!state.loaded) {
    return (
      <BlueBackgroundScreen>
        <SpinnerCircularSplit color={'#AD9B5E'} size={30} thickness={175} />
      </BlueBackgroundScreen>
    );
  }

  if (state.isError) {
    return (
      <BlueBackgroundScreen>
        <div
          className={
            'bg-vistaWhite p-6 rounded flex flex-col gap-6 w-full sm:w-[60%]'
          }>
          <p>Your link appears to be broken. Please try again.</p>
        </div>
      </BlueBackgroundScreen>
    );
  }

  if (state.mustLogin) {
    return (
      <BlueBackgroundScreen>
        <div className={'bg-vistaWhite p-6 rounded flex flex-col gap-6'}>
          <p>You must login to access to access your marketing preferences.</p>
          <Button
            theme={'primary'}
            label={'Login'}
            onClick={() => {
              nav('/Login');
            }}
          />
        </div>
      </BlueBackgroundScreen>
    );
  }
if (data === undefined) {
  return (
    <BlueBackgroundScreen>
      <div
        className={
          'bg-vistaWhite p-6 rounded flex flex-col gap-6 w-full sm:w-[60%]'
        }>
        <p>An unexpected error occurred please try again.</p>
      </div>
    </BlueBackgroundScreen>
  )
}

  if (data?.snooze === null ) {
    return (
      <BlueBackgroundScreen>
        <div
          className={
            'bg-white p-8 rounded flex flex-col gap-6 w-full text-left'
          }>
          <div className={'sm:hidden bg-bond'}>
            <img
              src={logo}
              className="lg:w-[328px] h-auto w-[75%] m-12 md:mb-10 md:m-0 "
              alt=""></img>
          </div>

          <h5>
            {clientData}, please review and update your marketing preferences.
          </h5>

          <HowToContactForm
            data={contactPrefs}
            isError={isErrorContactPrefs}
            isLoading={isLoadingContactPrefs}
            mutation={contactPrefMutation}
          />
          <hr className="border-1 border-midas mt-3"></hr>
          <HowOftenForm
            data={freqData}
            isError={freqError}
            isLoading={freqLoading}
            mutation={freqMutation}
          />
          <hr className="border-1 border-midas mt-3"></hr>
          <InterestsForm
            data={interestData}
            isError={interestError}
            isLoading={interestLoading}
            mutation={interestMutation}
          />
          <hr className="border-1 border-midas mt-3"></hr>
          <PauseMembershipForm mutation={mutation} />
          <div className={`rounded-xl w-auto h-auto pt-2 bg-white  fade-in `}>
            <p className="text-xs mt-2 cursor-default select-none">
              <span
                onClick={() => {
                  mutation.mutate({ snooze: '999999' });
                }}
                className={`text-xs underline text-center cursor-pointer`}>
                Permanently stop marketing
              </span>
              : In order to fulfil our contractual obligations towards you we’ll
              still send you important notifications that relate to your account
              or the investments you hold. It may take up to 24 hours for our
              systems to register this change.
            </p>
          </div>
        </div>
      </BlueBackgroundScreen>
    );
  }

  return (
    <BlueBackgroundScreen>
      <div
        className={'bg-white p-8 rounded flex flex-col gap-6 w-full text-left'}>
        <div className={'sm:hidden bg-bond'}>
          <img
            src={logo}
            className="lg:w-[328px] h-auto w-[75%] m-12 md:mb-10 md:m-0 "
            alt=""></img>
        </div>

        <h5>
          {clientData}, please review and update your marketing preferences.
        </h5>
        <div className={'flex flex-col items-center justify-center'}>
          <PermPopUp date={data.snooze} mutation={mutationResub} />
        </div>
      </div>
    </BlueBackgroundScreen>
  );
}

export default UnAuthedPreferences;
