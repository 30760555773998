import React, { useState } from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import ErrorBanner from '../../components/Error/ErrorBanner';
import PasswordForm from '../../components/ChangePassword/PasswordForm';

interface PasswordNotification {
  message: string;
  isError: boolean;
  isDisplayed: boolean;
}

function PasswordPage() {
  const [notification, setNotification] = useState<PasswordNotification>(
    {} as PasswordNotification,
  );

  const setNotif = (notification: PasswordNotification) => {
    setNotification(notification);
  };

  return (
    <div>
      <ContentComponent title="Account settings" subtitle="Update password">
        <div className="w-full h-fit mb-12">
          {notification.isDisplayed && (
            <ErrorBanner
              severe={notification.isError}
              text={notification.message}
            />
          )}
          <p className="text-sm mt-6 w-full">
            <h4 className="mb-2">Choose your new password</h4>
            Use the form below to choose your new password:
            consider one you don&#39;t already use for other services.
          </p>

          <PasswordForm setNotification={setNotif} />

        </div>
      </ContentComponent>
    </div>
  );
}

export default PasswordPage;
