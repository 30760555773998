import React, { useEffect } from 'react';
import { ReactComponent as Close } from '../../images/icons/icon-close.svg';

type SidePanelProps = {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
};

const SidePanel = (props: SidePanelProps) => {
  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [props.open]);

  const escapeHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      props.close();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escapeHandler, false);
    return () => {
      document.removeEventListener('keydown', escapeHandler, false);
    };
  }, []);

  return (
    <>
      <div
        onClick={props.close}
        className={`${
          props.open ? 'visible' : 'hidden'
        } w-screen absolute h-screen z-[999999999] bg-vistaWhite bg-opacity-75 `}>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={`absolute  xl:fixed xl:top-0 xl:right-0 z-[999999999] bg-opacity-100 xl:shadow-xl h-screen p-4 xl:w-[45%] fade-in w-full xl:ml-0 overflow-y-scroll transition-all duration-700 bg-white bottom-0 ${
            props.open ? 'xl:right-0 visible' : 'xl:-right-[45%] hidden'
          }`}
          tabIndex={-1}>
          <div className={'pt-24 md:pt-0 h-auto'}>
            <button onClick={props.close} className={'float-right'}>
              <Close
                title="Close"
                className="h-4 cursor-pointer slow-fade-in duration-300"
              />
            </button>
            <div className={'mt-8 overflow-'}>{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanel;