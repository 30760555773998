import React from 'react';
import ButtonComponent from '../Buttons/Button';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useFormik } from 'formik';
import * as yup from 'yup';

import moment from 'moment';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { OptOutMutation } from './Mut';
import useToken from '../../hooks/useToken';
import { isDisabledForViewAs } from '../../utils/HelperUtils';
import { Button } from 'private-wc-ui';
import { InterestsVal } from './InterestsForm';




export interface PauseMembershipFormProps {
  mutation:  UseMutationResult<void, unknown, OptOutMutation, unknown>
}


function PauseMembershipForm(props:PauseMembershipFormProps) {


  const auth = useToken()


  const formik = useFormik({
    initialValues: { snooze: '1' },
    validationSchema: yup.object().shape({
      snooze: yup.string().required('A break length is required'),
    }),
    onSubmit: async (values) => {
      props.mutation.mutate(values);
    },
  });

  return (
    <div className="w-full h-full flex flex-col">
      <p className="text-sm fade-in">
        <b>Put your Wealth Club membership on hold for...</b>
      </p>
      <form onSubmit={formik.handleSubmit}>

        <select
          className={`w-full p-2 mt-2 bg-white mb-4 border-2 fade-in border-vistaWhite focus:outline-midasLight rounded ${formik.errors.snooze != undefined &&
          formik.errors.snooze.length != 0 &&
          formik.touched.snooze ? 'border-[#ff0000]' : 'border-midasLight'}`}
          value={formik.values.snooze == null ? '' : formik.values.snooze}
          disabled={(auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
          onChange={formik.handleChange}
          name={'snooze'}
          id={'snooze'}
        >
          <option value="1">1 Month</option>
          <option value="2">2 Months</option>
          <option value="3">3 Months</option>
          <option value="4">4 Months</option>
          <option value="5">5 Months</option>
          <option value="6">6 Months</option>
          <option value="7">7 Months</option>
          <option value="8">8 Months</option>
          <option value="9">9 Months</option>
          <option value="10">10 Months</option>
          <option value="11">11 Months</option>
          <option value="12">12 Months</option>
        </select>
        {formik.errors.snooze && formik.touched.snooze ? (
          <div style={{ color: 'red' }}>{formik.errors.snooze}</div>
        ) : null}

        <Button
          disabled={formik.isSubmitting || (auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth)}
          className="block"
          style={{ width: 'auto' }}
          label="Pause membership"
          theme={'primary'}
          type={'submit'}
        />
      </form>

    </div>
  );
}

export default PauseMembershipForm;
