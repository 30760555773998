import React from 'react';
import FileTableComponent from '../../../DocumentCentre/FileTableComponent';
import FileDescriptor from '../../../../dto/FileDescriptor';
import MobileTableSort from "../../MobileTableSort";
import MobileFileTableComponent from "../../../DocumentCentre/MobileFileTableComponent";
import useWindowSize from "../../../../hooks/useWindowSize";

interface DocTableProps {
  documents: FileDescriptor[];
}

function DocTable(props: DocTableProps) {

  const { width } = useWindowSize();

  return (
      <>
        {width > 1000 && props.documents.length>0 && (
            <>
            <FileTableComponent files={props.documents} />
            </>
        )}

        {width < 1000 && props.documents.length>0 && (
            <>
            <MobileFileTableComponent files={props.documents} />
            </>
        )}
      </>

  );
}

export default DocTable;
