import React from 'react';
import { ReactComponent as DeleteIcon } from '../../images/icons/icon-delete.svg';
import useToken from '../../hooks/useToken';
import { guard } from '../../utils/HelperUtils';

interface DeleteBtnProps {
  secMessageId: string;
  deleteSecureMessage: (id: string) => void;
  page: number;
}

function DeleteButton(props: DeleteBtnProps) {
  const auth = useToken();

  return (

    <div>
      <DeleteIcon
        onClick={async () => {
          {
            guard(auth.token.as === undefined, () => {
              props.deleteSecureMessage(props.secMessageId);
            });
          }
        }}
        title="Delete Message"
        className={`h-[20px] cursor-pointer fill-bond fade-in hover:animate-pulse`}
      ></DeleteIcon>

    </div>
  );
}

export default DeleteButton;
