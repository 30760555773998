import {SecureMessage, SecureMessageAttachment, SecureMessageCreate} from "../dto/SecureMessage";
import api from "./api";
import FileDescriptor from "../dto/FileDescriptor";

interface ISecureMessageService {
    getSecureMessages(folder: string): Promise<Array<SecureMessage>>;

    getMessageCount(): Promise<number>;

    getSecureMessage(messageId: string): Promise<SecureMessage>;

    getSecureMessagesOnThread(threadId: string): Promise<Array<SecureMessage>>;

    getSecureMessageAttachments(messageId: string): Promise<Array<SecureMessageAttachment>>;
    // to be used when you want the raw file e.g. for drafts
    getSecureMessagesFiles(messageId: string): Promise<Array<FileDescriptor>>;

    setMessageAsRead(messageId: string): Promise<void>;

    createSecureMessage(msg: SecureMessageCreate): Promise<string>;

    attachFileToSecureMessage(msgId: string, attachments: Array<FileDescriptor>): Promise<void>;

    removeAttachment(msgId: string, attachments: SecureMessageAttachment): Promise<void>;

    deleteSecureMessage(msgId: string): Promise<void>;

    updateMessage(secureMessage: SecureMessageCreate): Promise<string>;
}

export class SecureMessageService implements ISecureMessageService {
    getMessageCount(): Promise<number> {
        return new Promise((resolve, reject) => {
            api.get("/client/messages/count").then(res => {
                const response = res.data as number
                resolve(response)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    getSecureMessages(folder: string): Promise<Array<SecureMessage>> {
        return new Promise((resolve, reject) => {
            api.get("/client/messages?folder=" + folder).then(res => {
                const response = res.data as Array<SecureMessage>
                resolve(response)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    getSecureMessage(messageId: string): Promise<SecureMessage> {
        return new Promise((resolve, reject) => {
            api.get("/client/messages/" + messageId).then(res => {
                const response = res.data as SecureMessage
                resolve(response)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    getSecureMessagesOnThread(threadId: string): Promise<Array<SecureMessage>> {
        return new Promise((resolve, reject) => {
            api.get("/client/messages/thread/" + threadId).then(res => {
                const response = res.data as Array<SecureMessage>
                resolve(response)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    getSecureMessageAttachments(messageId: string): Promise<Array<SecureMessageAttachment>> {
        return new Promise((resolve, reject) => {
            api.get("/client/messages/" + messageId + "/attachments").then(res => {
                const response = res.data as Array<SecureMessageAttachment>
                resolve(response)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    setMessageAsRead(messageId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            api.patch("/client/messages/" + messageId, {read: true}).then(res => {
                resolve()
            }).catch(reason => {
                reject(reason)
            })
        })
    }


    createSecureMessage(msg: SecureMessageCreate): Promise<string> {
        return new Promise((resolve, reject) => {
            api.post("/client/messages", msg).then(res => {
                resolve(res.data.id)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    attachFileToSecureMessage(msgId: string, attachments: Array<FileDescriptor>): Promise<void> {
        return new Promise((resolve, reject) => {
            const ids: Array<string> = []
            for (let i = 0; i < attachments.length; i++) {
                const id = attachments[i].id
                if (id) {
                    ids.push(id)
                }
            }
            api.post("/client/messages/" + msgId + "/attachments", ids).then(res => {
                resolve()
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    deleteSecureMessage(msgId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            api.delete("/client/messages/" + msgId,).then(res => {
                resolve()
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    updateMessage(secureMessage: SecureMessageCreate): Promise<string> {
        return new Promise((resolve, reject) => {
            api.patch("/client/messages/" + secureMessage.id, {
                subject: secureMessage.subject,
                body: secureMessage.body,
                folder: secureMessage.folder,
                attachmentIds: secureMessage.attachmentIds,
            }).then(res => {
                resolve(secureMessage.id!)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    removeAttachment(msgId: string, attachments: SecureMessageAttachment): Promise<void> {
        return new Promise((resolve, reject) => {
            api.delete("/client/messages/" + msgId + "/attachment/" + attachments.id,).then(res => {
                resolve()
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    getSecureMessagesFiles(messageId: string): Promise<Array<FileDescriptor>> {
        return new Promise((resolve, reject) => {
            api.get("/client/messages/" + messageId + "/attachments/files").then(res => {
                const response = res.data as Array<FileDescriptor>
                resolve(response)
            }).catch(reason => {
                reject(reason)
            })
        })
    }
}