import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PencilIcon } from '../../images/icons/icon-pencil.svg';
import SwitcherButton from './SwitcherButton';
import useToken from '../../hooks/useToken';

interface FolderSwitcherProps {
  folder: string;
  changeFolder: (folder: string) => void;

}

function FolderSwitcher(props: FolderSwitcherProps) {
  const nav = useNavigate();
  const auth = useToken()

  function onClickHandler(folder: string) {
    if (props.folder != folder) {
      props.changeFolder(folder);
    }
  }

  return (
    <div className="flex flex-row w-full md:mt-0 mt-4 relative">
      <div>
        <SwitcherButton
          thisFolder="inbox"
          activeFolder={props.folder}
          onClickHandler={onClickHandler}
        />
      </div>
      <div className="ml-4 md:ml-6">
        <SwitcherButton
          thisFolder="drafts"
          activeFolder={props.folder}
          onClickHandler={onClickHandler}
        />
      </div>
      <div className="ml-4 md:ml-6">
        <SwitcherButton
          thisFolder="sent"
          activeFolder={props.folder}
          onClickHandler={onClickHandler}
        />
      </div>
      <div className="ml-4 md:ml-6">
        <SwitcherButton
          thisFolder="deleted"
          activeFolder={props.folder}
          onClickHandler={onClickHandler}
        />
      </div>
      <div
        className={`absolute right-0 -mt-20 md:mt-0 flex-row align-middle justify-center items-center ${auth.token.as === undefined ? "visible flex":"hidden"}`}
        onClick={() => {
          nav('/new-message');
        }}>
        <PencilIcon className="h-[25px] mr-2 fill-bond" />
        <div className="text-bond text-sm font-normal cursor-pointer uppercase underline select-none">
          New message
        </div>
      </div>
    </div>
  );
}

export default FolderSwitcher;
