import React from 'react';
import { ReactComponent as DocumentIcon } from '../../images/icons/icon-document.svg';
import { formatDatabaseTime } from '../../utils/FormattingUtils';
import { useDependencyInjector } from '../../context/DependencyInjector';
import FileDescriptor from '../../dto/FileDescriptor';
import fileDownload from 'js-file-download';
import { formatStringToDate } from '../../utils/DateUtils';

export type MobileFileTableComponentProps = {
  files: Array<FileDescriptor>;
};

const MobileFileTableComponent = (props: MobileFileTableComponentProps) => {
  const { documentService } = useDependencyInjector();

  const downloadFile = async (fileDesc: FileDescriptor) => {
    const response = await documentService.getDocument(fileDesc.id!);
    fileDownload(response.media as Blob, response.fileName);
  };


  const renderRows = () => {
    const rows = [];

    for (let i = 0; i < props.files.length; i++) {
      rows.push(
          <div key={`document-${i}`} onClick={async () => {
              await downloadFile(props.files[i]);
          }}
              className="flex flex-row hover:underline cursor-pointer pt-3 align-middle">
              <DocumentIcon className="h-[25px] fill-tuxedo"/>
              <div
                  className="ml-2 text-sm text-bond mt-1 underline">
                {props.files[i].name + '.' + props.files[i].extension}
              </div>
              <div className="ml-3 text-sm mt-1" >{formatStringToDate(props.files[i].createDate)}</div>

          </div>
          ,
      );
    }
      return rows;
  };

    return (
        <div className="w-full h-auto text-left select-none mt-4">
                {renderRows()}
        </div>
      );
};

export default MobileFileTableComponent;
