import React, { useEffect, useState } from 'react';

interface PaginationProps {
  pageCount: number;
  onPageChange: (page: number) => void;
}

function PaginationButtons({ pageCount, onPageChange }: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  function renderBreak() {
    return (
      <div className="ml-4 pt-1 pb-1 pl-2 pr-2 text-center transition-all duration-300 select-none">
        <h4 className="text-bond">•</h4>
      </div>
    );
  }

  function renderNumber(i: number) {
    return (
      <div
        key={i}
        className={`${
          i === 1 ? '' : 'ml-4'
        } pt-1 pb-1 pl-2 pr-2 text-center transition-all duration-300 select-none cursor-pointer ${
          currentPage === i ? 'bg-sand' : 'hover:bg-vistaWhite active:bg-white'
        }`}
        onClick={() => setCurrentPage(i)}
      >
        <h4 className="cursor-pointer">{i}</h4>
      </div>
    );
  }

  function generateButtons(pageCount: number) {
    const buttons = [];
    for (let i = 1; i <= pageCount; i++) {
      if (pageCount <= 5) {
        buttons.push(renderNumber(i));
      } else if (
        // If more than 5 pages
        i === currentPage - 1 || // Display if 1 below current page
        i === currentPage + 1 || //  Display if 1 above current page
        i === currentPage || // Display current page
        i === 1 || // Always display page 1
        i === pageCount // Always display final page
      ) {
        if (i === 1) {
          buttons.push(renderNumber(i)); // Render page 1 button
          if (currentPage !== 1 && currentPage !== 2) { //if current page is not 1 or 2, a bullet point is put after page 1 button
            buttons.push(renderBreak());
          }
        } else if (i === pageCount) {
          //render final page button
          if (currentPage !== pageCount && currentPage !== pageCount - 1) {
            buttons.push(renderBreak());
          }
          buttons.push(renderNumber(i));
        } else {
          buttons.push(renderNumber(i));
        }
      }
    }
    return buttons;
  }

  return (
    <div className="flex flex-row justify-center mt-10 slow-fade-in">
      {generateButtons(pageCount)}
    </div>
  );
}

export default PaginationButtons;