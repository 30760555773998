import React from 'react';

function ReturnLink() {
  return (
    <div
      className="flex flex-row md:mt-3 w-full h-auto align-middle items-center"
      onClick={() => {
        window.location.href = 'https://www.wealthclub.co.uk/';
      }}>
      <object
        className="h-6 w-6 hover:cursor-pointer"
        type="image/svg+xml"
        data="/images/icons/icon-featured.svg"></object>
      <p className="underline text-sand uppercase font-sans text-[13px] ml-2 hover:cursor-pointer">
        Return to wealthclub.co.uk
      </p>
    </div>
  );
}

export default ReturnLink;
