import StatusComponent from "../Status/StatusBlock";
import React from "react";

export interface ApplicationStatusProps {
    status: number;
}

const ApplicationStatus = (props: ApplicationStatusProps) => {

    const getApplicationStatus = (status: number) => {
        switch (status) {
            case 1:
                return <StatusComponent title="Accepted" type="complete"/>;
            case 10:
            case 20:
                return <StatusComponent title="Pending" type="pending"/>;
            case 30:
                return <StatusComponent title="Returned" type="failed"/>;
            case 40:
                return <StatusComponent title="Cancelled" type="failed"/>;
            case 50:
                return (
                    <StatusComponent title="Transfered away" type="failed"/>
                );
        }
    }

    return (
        <>
            {getApplicationStatus(props.status)}
        </>

    )


}

export default ApplicationStatus;
