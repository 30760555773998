import React, { useEffect, useRef } from 'react';
import NavBar from '../../pages/Nav';
import Footer from '../Footer/Footer';
import MobileMenu from '../MobileNav/MobileMenu';
import useToken from '../../hooks/useToken';
import AccountViewSwitch from '../AccountView/AccountViewSwitch';
import useWindowSize from '../../hooks/useWindowSize';
import PasswordBanner from '../Passwords/PasswordBanner';
import { Link } from 'react-router-dom';

interface ContentProps {
  title: string;
  subtitle: string;
  jsxSubTitle?: React.ReactNode;
  secondaryTitle?: string;
  children?: React.ReactNode;
  actionBtn?: string;
  actionBtnLink?: string;
  increaseHeightForStickyButton?: boolean;
}

function ContentComponent(props: ContentProps) {
  const { token } = useToken();
  const windowHook = useWindowSize();

  const NAV_WIDTH = 325;

  const parentRef = useRef<HTMLDivElement | null>(null);

  const getWidth = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref.current) {
      return ref.current.clientWidth;
    }
    return 0;
  };

  const getNavBack = () => {
    const currentLocation = window.location.pathname;
    const splitAddress = currentLocation.split('/');
    const numberOfSlash = splitAddress.length - 1;

    if (numberOfSlash === 3) {
      return currentLocation.substring(0, currentLocation.lastIndexOf('/'));
    }

    return splitAddress.splice(0, splitAddress.length - 2).join('/').replace("dfm", "investment");
  };

  return (
    <>
      <PasswordBanner />
      <div className="flex md:flex-row flex-col w-full">
        <MobileMenu parent={props.title} />
        <NavBar parent={props.title} />

        <div
          className={
            'bg-vistaWhite overflow-x-hidden h-[100vh] lg:pt-6 lg:pb-6 lg:pl-20 lg:pr-20 md:pl-2 md:pr-2 pr-1 pl-1'
          }
          style={{
            width: `${
              windowHook.width > 768
                ? windowHook.width - NAV_WIDTH + 'px'
                : '100%'
            }`,
          }}>
          <div
            className="bg-white shadow-md overflow-hidden pt-8 relative"
            ref={parentRef}>
            <div
              className={'absolute'}
              style={{ width: getWidth(parentRef) + 'px' }}>
              <div className={'flex flex-row justify-between ml-[5%] w-[90%]'}>
                <div className={'pt-4'}>
                  <div
                    className="title text-sm text-bond cursor-default select-none"
                    id="content_title">
                    {props.title} {props.secondaryTitle ? '> ' : ''}
                    <Link
                      className={'cursor-pointer hover:underline'}
                      to={getNavBack()}>
                      {props.secondaryTitle}
                    </Link>
                  </div>
                  <div className="flex flex-row ">
                    <div className="cursor-default">
                      {props.jsxSubTitle != null && <>{props.jsxSubTitle}</>}
                      {props.jsxSubTitle == null && (
                        <h3
                          id="content_subtitle"
                          className="select-none md:w-[50%] whitespace-nowrap">
                          {props.subtitle}
                        </h3>
                      )}
                      {token?.asName && (
                        <p className="text-sm italic">
                          {'(Viewing as ' + token?.asName + ')'}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className={'hidden md:block relative z-[9999999]'}>
                  <AccountViewSwitch />
                </div>
              </div>
            </div>

            <hr className="border-1 border-midasLight w-[90%] ml-[5%] mt-[80px]"></hr>

            <div className="ml-[5%] mt-[1%] h-auto mr-[5%] min-h-[70vh]">
              {props.children}
            </div>
            <Footer />
          </div>

          {props.increaseHeightForStickyButton && <div className={'h-40'} />}
        </div>
      </div>
    </>
  );
}

export default ContentComponent;
