import axios from 'axios';
import { User, UserToken } from '../hooks/useToken';
import api from './api';
import ErrorMessage from '../errors/ErrorMessage';
import { getApiBaseUrl } from '../utils/HelperUtils';

interface IAuthenticationService {
  login(username: string, password: string): Promise<UserToken>;

  loginWithToken(token: string): Promise<UserToken>;

  logout(userToken: UserToken): Promise<void>;

  changePassword(currentPassword: string, newPassword: string): Promise<void>;

  requestPasswordReset(userName: string): Promise<void>;

  tokenIsValid(tokenId: string, userName: string): Promise<boolean>;

  changePasswordWithToken(
    tokenId: string,
    userName: string,
    newPassword: string,
  ): Promise<void>;

  requestMagicLink(userName: string, redirect: string): Promise<void>;

  performMagicLinkLogin(tokenId: string): Promise<UserToken>;

  setNewPassword(newPassword: string): Promise<void>;

  getNewPwdRequired(): Promise<boolean>;
}

class AuthenticationService implements IAuthenticationService {
  login(username: string, password: string): Promise<UserToken> {
    return new Promise((resolve, reject) => {
      axios
        .post(getApiBaseUrl() + '/auth/login', {
          username: username,
          password: password,
        })
        .then((response) => {
          const user: User = {
            email: response.data.user.email,
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            fullName: response.data.user.fullName,
            username: response.data.user.username,
          };
          const userToken: UserToken = {
            token: response.data.id,
            user: user,
            group: response.data.group,
            roles: response.data.roles,
          };
          resolve(userToken);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.timestamp
          ) {
            const errorMsg: ErrorMessage = {
              error: error.response.data.error,
              timeStamp: error.response.data.timestamp,
              status: error.response.data.status,
            };
            reject(errorMsg);
          } else {
            reject(null);
          }
        });
    });
  }

  loginWithToken(token: string): Promise<UserToken> {
    return new Promise((resolve, reject) => {
      axios
          .post(getApiBaseUrl() + '/auth/login_token', {
            token: token
          })
          .then((response) => {
            const user: User = {
              email: response.data.user.email,
              firstName: response.data.user.firstName,
              lastName: response.data.user.lastName,
              fullName: response.data.user.fullName,
              username: response.data.user.username,
            };
            const userToken: UserToken = {
              token: response.data.id,
              user: user,
              group: response.data.group,
              roles: response.data.roles,
            };
            resolve(userToken);
          })
          .catch((error) => {
            if (
                error.response &&
                error.response.data &&
                error.response.data.timestamp
            ) {
              const errorMsg: ErrorMessage = {
                error: error.response.data.error,
                timeStamp: error.response.data.timestamp,
                status: error.response.data.status,
              };
              reject(errorMsg);
            } else {
              reject(null);
            }
          });
    });
  }

  logout(userToken: UserToken): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(getApiBaseUrl() + +'/auth/logout', { ...userToken })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.timestamp
          ) {
            const errorMsg: ErrorMessage = {
              error: error.response.data.error,
              timeStamp: error.response.data.timestamp,
              status: error.response.data.status,
            };
            reject(errorMsg);
          } else {
            reject(null);
          }
        });
    });
  }

  changePassword(currentPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api
        .post('/auth/changePassword', {
          oldPassword: currentPassword,
          newPassword: newPassword,
        })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.timestamp
          ) {
            const errorMsg: ErrorMessage = {
              error: error.response.data.error,
              timeStamp: error.response.data.timestamp,
              status: error.response.data.status,
            };
            reject(errorMsg);
          } else {
            reject(null);
          }
        });
    });
  }

  changePasswordWithToken(
    tokenId: string,
    userName: string,
    newPassword: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(getApiBaseUrl() + `/auth/reset/change`, {
          resetToken: tokenId,
          username: userName,
          newPassword: newPassword,
        })
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  tokenIsValid(tokenId: string, userName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          getApiBaseUrl() +
            `/auth/reset/validate?id=${tokenId}&username=${userName}`,
        )
        .then((response) => {
          const data = response.data as { isValid: boolean };
          resolve(data.isValid);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  requestPasswordReset(userName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(getApiBaseUrl() + '/auth/reset', { userName: userName })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.timestamp
          ) {
            const errorMsg: ErrorMessage = {
              error: error.response.data.error,
              timeStamp: error.response.data.timestamp,
              status: error.response.data.status,
            };
            reject(errorMsg);
          } else {
            reject(error);
          }
        });
    });
  }

  requestMagicLink(userName: string, redirect: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(getApiBaseUrl() + '/auth/magic', {
          userName: userName,
          redirect: redirect,
        })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.timestamp
          ) {
            const errorMsg: ErrorMessage = {
              error: error.response.data.error,
              timeStamp: error.response.data.timestamp,
              status: error.response.data.status,
            };
            reject(errorMsg);
          } else {
            reject(error);
          }
        });
    });
  }

  performMagicLinkLogin(tokenId: string): Promise<UserToken> {
    return new Promise((resolve, reject) => {
      axios
        .post(getApiBaseUrl() + '/auth/login/magic', { magicToken: tokenId })
        .then((response) => {
          const user: User = {
            email: response.data.user.email,
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            fullName: response.data.user.fullName,
            username: response.data.user.username,
          };
          const userToken: UserToken = {
            token: response.data.id,
            user: user,
            group: response.data.group,
            roles: response.data.roles,
          };
          resolve(userToken);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setNewPassword(newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api
        .post('/auth/newPassword', {
          newPassword: newPassword,
        })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.timestamp
          ) {
            const errorMsg: ErrorMessage = {
              error: error.response.data.error,
              timeStamp: error.response.data.timestamp,
              status: error.response.data.status,
            };
            reject(errorMsg);
          } else {
            reject(null);
          }
        });
    });
  }

  getNewPwdRequired(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      api
        .get('/auth/pwdCheck')
        .then((response) => {
          resolve(response.data.forceNewPassword as boolean);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.timestamp
          ) {
            const errorMsg: ErrorMessage = {
              error: error.response.data.error,
              timeStamp: error.response.data.timestamp,
              status: error.response.data.status,
            };
            reject(errorMsg);
          } else {
            reject(null);
          }
        });
    });
  }
}

export default AuthenticationService;
