import InvestmentService from '../services/InvestmentService';
import AuthenticationService from '../services/Authentication';
import React, { createContext, useContext } from 'react';
import ClientService from '../services/ClientService';
import { AdminService } from '../services/AdminService';
import { SecureMessageService } from '../services/SecureMessageService';
import FileUploadService from '../services/FileUploadService';
import { TransactionService } from '../services/TransactionService';
import { DocumentService } from '../services/DocumentService';
import { PreferenceService } from '../services/PreferenceService';
import { ICommissionService } from '../services/CommissionService';
import { ISecurityService } from '../services/SecurityService';
import { ICtaService } from '../services/CtaService';
import { IUserService } from '../services/UserService';
import { IPreferenceUnsubService } from '../services/PrefServiceUnSub';

export interface ServicesContext {
  investmentService: InvestmentService;

  authService: AuthenticationService;

  clientService: ClientService;

  adminService: AdminService;

  secureMessageService: SecureMessageService;

  fileUploadService: FileUploadService;

  transactionService: TransactionService;

  documentService: DocumentService;

  preferenceService: PreferenceService;

  commissionService: ICommissionService;

  securityService: ISecurityService;

  ctaService: ICtaService;

  userService: IUserService;

  unsubService: IPreferenceUnsubService;
}

export function useDependencyInjector() {
  return useContext(DependencyInjectContext);
}

const DependencyInjectContext = createContext<ServicesContext>(
  {} as ServicesContext,
);

export default DependencyInjectContext;
