import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronRight } from '../../images/icons/icon-chevron-right.svg';

interface BoxProps {
  text: string;
  link: string;
}

function SettingLinkBox(props: BoxProps) {
  const nav = useNavigate();

  return (
    <div
      onClick={() => nav(props.link)}
      className="w-full h-[5vh] border-vistaWhite border-2 flex flex-row align-middle items-center mt-5">
      <h4 className="text-bond underline cursor-pointer ml-4 w-[80%]">
        {props.text}
      </h4>
      <ChevronRight className="fill-bond ml-10 w-[15%] border-l-2 border-vistaWhite" />
    </div>
  );
}

export default SettingLinkBox;
