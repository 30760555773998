import { useState } from 'react';


export interface RedirectHook{
  removeRedirect: () => void
  storeRedirect: (redirect: string) => void
  redirect: string | null;
}

export default function useRedirect() {
  const getRedirect = () => {
    return localStorage.getItem('redirect');
  };

  const [redirect, setRedirect] = useState<string | null>(getRedirect());

  const removeRedirect = () => {
    setRedirect(null);
    localStorage.removeItem('redirect');
  };

  const storeRedirect = (redirect: string) => {
    localStorage.setItem('redirect', redirect);
    setRedirect(redirect);
  };

  return {
    removeRedirect: removeRedirect,
    storeRedirect: storeRedirect,
    redirect: redirect,
  } as RedirectHook;
}
