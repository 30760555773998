import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../utils/FormattingUtils';

interface InvestmentProps {
  name: string;
  value: number;
  link: string;
  style?: React.CSSProperties;
  onclick?: () => void;
}

function InvestmentPreview(props: InvestmentProps) {
  const nav = useNavigate();

  return (
    <div
      style={props.style}
      className={`w-full h-[70px] border-2 border-midas bg-white flex flex-row align-middle items-center p-4 `}
      onClick={() => {
        window.scrollTo(0, 0);
        if (props.onclick) {
          props.onclick();
        }
        nav(props.link);
      }}>
      <div>
        <h5 className="text-bond underline cursor-pointer">{props.name}</h5>
        <p className="text-sm">
          Investment Value:{' '}
          {props.value && props.value > 0 ? formatMoney(props.value) : '£0.00'}
        </p>
      </div>
    </div>
  );
}

export default InvestmentPreview;
