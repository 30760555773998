import React from 'react';
import { ReactComponent as AccountLogo } from '../../images/icons/icon-profile-switch.svg';
import { ClientSearch } from '../../dto/ClientSearch';
import { UserToken } from '../../hooks/useToken';

interface CurrUserProps {
  data: ClientSearch[] | undefined;
  token: UserToken | null;
  accountDropDown:boolean;
}

function CurrentUser(props: CurrUserProps) {
  return (
    <div className="flex flex-row z-50 pt-2">
      <AccountLogo
        className={`md:w-5 h-auto mr-2 md:fill-bond ${props.accountDropDown ? 'fill-black':'fill-white'}`}
      />
      <h6
        className={`text-sm  md:text-bond cursor-default select-none ${props.accountDropDown ? 'text-black':'text-white'}`}>
        {props.token?.user.fullName}
      </h6>
    </div>
  );
}

export default CurrentUser;
