import React from 'react';
import { useEffect, useRef } from 'react';
import { ControllerConfig, PostcodeLookup } from '@ideal-postcodes/postcode-lookup';

const PostcodeLookupComponent = (props: any) => {
  const context = useRef<HTMLDivElement | null>(null);



  useEffect(() => {
    const config = {
      ...props,
      apiKey: 'ak_ijqszhstJK2b32G4yB7Px4BbLbwpc',
      context: context.current,
      inputClass:'h-[40px] p-3 border-2 border-midasLight focus:outline-midas focus:bg-sand focus:shadow-2xl transition-all duration-500 ',
      selectClass: 'p-2 bg-white border-2 shadow-md border-midas focus:outline-midas focus:bg-sand focus:shadow-2xl transition-all duration-500 rounded',
      buttonClass: 'wc-font-bold wc-text-sm wc-p-2 wc-text-tuxedo wc-transition-all wc-duration-500 wc-bg-downy wc-cursor-pointer hover:wc-bg-bond hover:wc-text-white active:wc-bg-bond wc-uppercase',
      buttonLabel: 'Search'
        } as ControllerConfig;
    PostcodeLookup.setup(config);
  }, []);

  return (
    <div ref={context} className={"flex flex-wrap w-full gap-2"}>


    </div>);
};

export default PostcodeLookupComponent
