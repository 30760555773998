import React from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import SectionHeader from '../../components/PageSection/SectionHeader';
import SettingLinkBox from '../../components/Settings/SettingLinkBox';

function SettingsPage() {
  return (
    <div>
      <ContentComponent title="Account settings" subtitle="Settings">
        <div className="w-[100%] h-fit mb-12">
          <div className="w-full">
            <div className="mt-10">
              <SectionHeader
                title="Manage your settings"
                text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia, molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum numquam blanditiis harum quisquam eius sed odit
              fugiat iusto fuga praesentium optio, eaque rerum! Provident
              similique accusantium nemo autem."
              />
              <SettingLinkBox
                text="Update contact details and preferences"
                link="/update-contact-details"
              />
              <SettingLinkBox text="Change Password" link="/change-password" />
              <SettingLinkBox
                text="Application history"
                link="/application-history"
              />
              <SettingLinkBox
                text="Verify bank account"
                link="/verify-bank-account"
              />
              <SettingLinkBox
                text="Commission and charges"
                link="/commission-charges"
              />
            </div>
          </div>
        </div>
      </ContentComponent>
    </div>
  );
}

export default SettingsPage;