import React, { CSSProperties } from 'react';
import { Transaction } from '../../dto/Transaction';
import { formatMoney } from '../../utils/FormattingUtils';
import { formatStringToDate } from '../../utils/DateUtils';
import { IColumnType, Table } from 'private-wc-ui';
import ApplicationStatus from "./ApplicationStatusSelector";

interface TableProps {
  data: Transaction[];
}

function TransactionTable(props: TableProps) {


  interface TableData extends  Transaction {
    bgColour?: CSSProperties["color"];
  }

  const columns: IColumnType<TableData>[] = [
    {
      key: 'businessReceived',
      title: 'Date Received',
      render: (_, tableData) => {
        return <span> {formatStringToDate(tableData.businessReceived)}</span>;
      },
    },
    {
      key: 'investmentName',
      title: 'Investment name',
    },
    {
      key: 'type',
      title: 'Type',
    },
    {
      key: 'amountInvested',
      title: 'Amount invested',
      render: (_, tableData) => {
        return <span data-cy={`amountInvested${tableData.amountInvested}`}>{tableData.amountInvested
          ? formatMoney(tableData.amountInvested, tableData.currency)
          : '£0'}</span>;
      },
    },
    {
      key: 'status',
      title: 'Status',
      render: (_, tableData) => {
        return  <ApplicationStatus status={tableData.status} />
      },
    },
  ];


  return (
    <div className={"mt-4"}>
      <Table
        columns={columns}
        data={props.data as TableData[]}
        className={'w-full'}
        style={{ width: '100%' }}

      />
    </div>
  );
}

export default TransactionTable;