import {CommissionSummary} from "../dto/CommissionSummary";
import api from "./api";
import {AxiosHeaders} from "axios";

export interface ICommissionService {

    getCommissionYears(): Promise<Array<string>>;

    getCommission(): Promise<Array<CommissionSummary>>;
}

export class CommissionService implements ICommissionService {
    getCommission(): Promise<Array<CommissionSummary>> {
        return new Promise((resolve, reject) => {
            api.get('/commission')
                .then((res) => {
                    const result = res.data as Array<CommissionSummary>;
                    resolve(result);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    getCommissionYears(): Promise<Array<string>> {
        return new Promise((resolve, reject) => {
            api.get('/commission/years')
                .then((res) => {
                    const result = res.data as Array<string>;
                    resolve(result);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });

    }

}