import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ModalProps } from './ModalProps';
import { ReactComponent as DeleteIcon } from '../../images/icons/icon-delete.svg';


const ConfirmationModal: React.FC<ModalProps> = ({
                                       isOpen,
                                       description,
                                       onConfirm,
                                       onCancel,
                                     }) => {

  const [showModal, setShowModal] = useState(isOpen);


  useEffect(() => {
      setShowModal(isOpen);
  }, [isOpen]);

  if (!showModal) return null;



  return ReactDOM.createPortal(
    <div id="deleteModal" tabIndex={-1} aria-hidden="true"
         className="fixed inset-0 flex items-center justify-center z-[100000] bg-grey-2 bg-opacity-80 ">
      <div className="relative p-4 w-full max-w-md h-full md:h-auto bg-opacity-0">
        <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button type="button" onClick={onCancel}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                 >
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <DeleteIcon className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" ></DeleteIcon>


          <p className="mb-4 text-gray-500 dark:text-gray-300">{description}</p>
          <div className="flex justify-center items-center space-x-4">
            <button  type="button" onClick={onCancel}
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900">
              No, cancel
            </button>
            <button onClick={onConfirm}
                    className="py-2 px-3 text-sm font-medium bg-beret text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none">
              Yes, I&apos;m sure
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default ConfirmationModal;