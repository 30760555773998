import React, { useState } from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import UpdateDetails from '../../components/BankDetails/UpdateDetails';
import { AxiosError } from 'axios';
import BankDetails, { BankType } from '../../dto/BankDetails';
import ErrorBanner from '../../components/Error/ErrorBanner';
import LoadingBlock from '../../components/Loading/LoadingBlock';
import { submitNewBankDetails, validateBankFormInputs } from '../../utils/BankHelperUtils';
import { ChangeBankDetailForm } from '../../components/BankDetails/BankDetailsTypes';
import ErrorToast from '../../components/Toast/ErrorToast';

function BankDetailsPage() {
  const { clientService } = useDependencyInjector();

  const [error, setError] = useState(false)

  const [amlFailed, setAmlFailed] = useState(false);

  const [success,setSuccess] = useState(false);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['bankDetails-nominated'],
    queryFn:
      async () => {
        try {
          return await clientService.getBankDetails(BankType.Main);
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              const details = {
                accountNumber: null,
                rollNumber: null,
                sortCode: null,
                validationStatus: null
              } as BankDetails;
              return details;
            }
          }
          throw error;
        }
      },
  });

  const submitBankDetails = (data:ChangeBankDetailForm) => {
    submitNewBankDetails(data, clientService, BankType.Main)
      .then(r =>{
        setAmlFailed(r.amlStatus === "Failed")
        setSuccess(r.amlStatus == "Verified")
         refetch()

      })
      .catch(() =>
        setError(true)
      )
  }


  if (isLoading){
    return <div><ContentComponent title="Account settings" subtitle="Nominated bank account"> <LoadingBlock /></ContentComponent></div>;
  }

  if (isError){
    return <div><ContentComponent title="Account settings" subtitle="Nominated bank account">   <ErrorBanner text={'An error occurred loading bank details. Please try again.'} severe={true} /></ContentComponent></div>;
  }

  return (
    <div>
      <ContentComponent title="Account settings" subtitle="Nominated bank account">

        <ErrorToast show={error} message={"An error occurred updating bank account details. Please try again."} closeFn={()=>{setError(!error)}} />

        {
          (data && data.accountNumber == null && data.rollNumber == null && data.sortCode == null) &&
          <>
          <ErrorBanner text={'No bank details on record'} severe={false} />
          <div className={'text-sm font-normal'}>These details, once verified, will be used for payments due to you from Wealth Club, e.g. exit proceeds. The
          nominated bank account must be in your name.</div>
          </>
        }

        {
          (data && data.validationStatus == "Active") &&
          <>
            <ErrorBanner text={'Verified bank details'} severe={false} />
            <div className={'text-sm font-normal'}>The following bank account has been verified. You can submit a new one, but can only have one nominated bank account at any one time.</div>
          </>
        }
        {
          (data && data.validationStatus == "Pending") &&
          <>
            <ErrorBanner text={'Bank details awaiting verification'} severe={false} />
            <div className={'text-sm font-normal'}>Your nominated bank account is currently being verified. This normally involved checking the account exists and is in your name. You should have received an email with instructions on next steps. Please contact us on 0117 929 0511 if you have any questions.</div>
          </>
        }

        <div className="w-[100%] h-fit mb-12 mt-4">
          {data && <UpdateDetails
            data={data}
            onSubmit={submitBankDetails}
            validate={validateBankFormInputs}
          />}

          {success &&

            <div className={'text-sm font-normal'}>Thank you. Your bank details have been successfully submitted and verified.</div>}

          {amlFailed &&
            <div className={'text-sm font-normal'} style={{ color: 'red' }}>Thank you. Your bank details have been submitted but unfortunately could not be
              automatically verified. You will shortly receive an email with further instructions. Please contact us on
              0117 929 0511 if you have any questions.</div>}

        </div>
      </ContentComponent>
    </div>
  );
}

export default BankDetailsPage;
