import React from 'react';

interface StatusProps {
  title: string;
  type: 'complete' | 'pending' | 'failed';
  style?: React.CSSProperties;
  onclick?: () => void;
}

function StatusComponent(props: StatusProps) {
  function bg() {
    let bg_colour;
    if (props.type === 'complete') {
      bg_colour = 'bg-downy';
    } else if (props.type === 'pending') {
      bg_colour = 'bg-vistaWhite';
    } else if (props.type === 'failed') {
      bg_colour = 'bg-beret';
    } else {
      bg_colour = 'bg-white';
    }
    return bg_colour;
  }

  return (
    <div
      onClick={props.onclick}
      style={props.style}
      className={`${bg()} p-3 font-sans text-tuxedo transition-all duration-200 select-none text-center`}>
      {props.title}
    </div>
  );
}

export default StatusComponent;
