import api from './api';
import FileDescriptor from '../dto/FileDescriptor';

interface IFileUploadService {
  uploadFiles(files: FileList): Promise<Array<FileDescriptor>>;

  /**
   * returns the deleted fileDescriptor
   * @param file
   */
  removeUploadFiles(file: FileDescriptor): Promise<FileDescriptor>;
}

class FileUploadService implements IFileUploadService {
  uploadFiles(files: FileList): Promise<Array<FileDescriptor>> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      api.post('/file/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(res => {
        const response = res.data as Array<FileDescriptor>;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  removeUploadFiles(file: FileDescriptor): Promise<FileDescriptor> {
    return new Promise((resolve, reject) => {
      api.delete('/file/' + file.id).then(() => {
        resolve(file);
      }).catch(reason => {
        reject(reason);
      });
    });
  }
}

export default FileUploadService;
