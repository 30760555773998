import React, { useEffect, useRef, useState } from 'react';
import useToken from '../../hooks/useToken';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import AccountDropdown from './AccountDropdown';
import CurrentUser from './CurrentUser';
import SwitchText from './SwitchText';

function AccountViewSwitch() {
  const { token, setToken } = useToken();
  const { clientService } = useDependencyInjector();
  const [accountDropdown, setAccountDropdown] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);


  const { isLoading, isError, data } = useQuery({
    queryKey: ['clients'],
    queryFn: async () => {
      return await clientService.getViewableClients();
    },
    enabled: shouldRender,
  });

  useEffect(() => {
    if (token) {
      if (token.group != 'Company' || token.as) {
        setShouldRender(true);
      } else {
        setShouldRender(false);
      }
    }
  }, [token]);

  return (
    <div className={`md:w-[180px] p-2  ${accountDropdown ? 'md:bg-grey-2 rounded-lg bg-white text-black' : ''} `}>
      <div className={`flex flex-col justify-start`}>
        <CurrentUser data={data} token={token} accountDropDown={accountDropdown} />

        <SwitchText
          accountDropdown={accountDropdown}
          data={data}
          setAccountDropdown={setAccountDropdown}
          setToken={setToken}
          token={token}
        />

        {accountDropdown && data?.length != 0 && (
          <div className={"rounded-b-lg"}>
          <AccountDropdown
            data={data}
            isLoading={isLoading}
            setToken={setToken}
            token={token}
          />
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountViewSwitch;
