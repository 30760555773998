import React from 'react';

interface SectionHeaderProps {
  title: string;
  text?: string;
}

function SectionHeader(props: SectionHeaderProps) {
  return (
    <div className="w-full">
      <h4>{props.title}</h4>
      <hr className="border-1 border-midasLight  min-w-[100%] "></hr>
      {props.text && <p className="text-sm hidden md:block">{props.text}</p>}
    </div>
  );
}

export default SectionHeader;
