import React from 'react';
import PageTitle from '../PageTitles/PageTitle';

import { NonCustodyHolding } from '../../dto/Holding';
import UnderlyingLoader from './SidePanelBlocks/investments/UnderlyingLoader';
import RelatedDocs from './SidePanelBlocks/docs/RelatedDocs';
import { useParams } from 'react-router-dom';
import {sortByInvestmentName, sortInvestmentsByPhaseAndInvDate} from "../../utils/SortUtils";

type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

const renderRelatedDocs = (holding: NonCustodyHolding, data: NonCustodyHolding[]) => {
  const sortedIDs : string[] = []; // Array of IDs of investments in sorted order
  const parentHoldings = data.filter(
      (f) => f.parentHolding == holding.id,
  ).sort(sortByInvestmentName);
  /* Start with the parent holdings that have been sorted alphabetically
  * then the underlying investments that are sorted the same way as on the investment side panel */
  for (const parentHolding of parentHoldings)
  {
    sortedIDs.push(parentHolding.id);
    sortedIDs.push(
        ...data.filter(
            (f) => f.parentHolding == parentHolding.id,
        ).sort(sortInvestmentsByPhaseAndInvDate).map(h => h.id)
    );
  }  
  
  return (
    <div>
      <PageTitle
        title={'Important Documents'.toUpperCase()}
        subtitle={holding.investment.name}></PageTitle>

      <RelatedDocs holding={holding} sortedIDs={sortedIDs} />
    </div>
  );
};

const renderInvestmentDetails = (holding: NonCustodyHolding, data: NonCustodyHolding[]) => {
  const {parent, wrapper, accountId, cat } = useParams();

  const getTitleText = () => {
    if (parent) {
      return parent.replaceAll('_', ' ');
    }
    if (cat) {
      return cat.replaceAll('_', ' ');
    }
    return '';
  };

  return (
    <div className="mt-10">
      <PageTitle
        title={'Investment Details'.toUpperCase()}
        subtitle={accountId != undefined ? getTitleText() :holding.investment.name}></PageTitle>

      <UnderlyingLoader data={data} holding={holding} />
    </div>
  );
};

export const renderSideContent = (
  content: SideBarContent,
  data: NonCustodyHolding[] | undefined,
) => {
  if (content == null) return;

  if (content.openType == 'INVESTMENT') {
    return renderInvestmentDetails(content.holding, data!);
  } else {
    return renderRelatedDocs(content.holding, data!);
  }
};
