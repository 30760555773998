import React, { Ref } from 'react';
import { ClientSearch } from '../../dto/ClientSearch';
import { UserToken } from '../../hooks/useToken';

interface DropdownProps {
  isLoading: boolean;
  data: ClientSearch[] | undefined;
  token: UserToken | null;
  setToken: (userToken: UserToken) => void;
}

function AccountDropdown(props: DropdownProps) {
  const userClickSwitch = (executeAs: string, name: string) => {
    if (props.token) {
      const cloneAuth = { ...props.token, as: executeAs, asName: name };
      props.setToken(cloneAuth);
      window.location.reload();
    }
  };

  const switchBackToMyself = () => {
    if (props.token) {
      const user = {
        ...props.token.user,
        fullName: props.token.user.fullName,
      };
      const cloneAuth = { ...props.token, user: user };
      delete cloneAuth['as'];
      delete cloneAuth['asName'];
      props.setToken(cloneAuth);
      // need a complete refresh
      window.location.reload();
    }
  };


  return (

      <div>
        {!props.isLoading &&
          props.data &&
          props.data.map((value, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  userClickSwitch(value.id, value.fullName);
                }}
                className={`hover:bg-sand cursor-pointer flex flex-row align-middle items-center pt-2 pb-1 hover:shadow-md ${
                  value.id == props.token?.as ? 'hidden' : ''
                }`}>
                <div className="md:text-sm text-black md:text-tuxedo ">
                  {value.fullName}
                </div>
              </div>
            );
          })}
        {!props.isLoading &&
          props.data &&
          props.data.length > 0 &&
          props.token?.as && (
            <div
              onClick={switchBackToMyself}
              className="hover:bg-sand cursor-pointer flex flex-row justify-start pt-2">
              <div className="md:text-sm md:mb-1 md:mt-0 text-black md:text-tuxedo">
                {props.token?.user.firstName +
                  ' ' +
                  props.token?.user.lastName}
              </div>
            </div>
          )}
      </div>


  );
}

export default AccountDropdown;
