import React, { useEffect, useState } from 'react';
import logo from '../images/wealth-club-portal-logo.svg';
import bgImage from '../images/login_backround.png';
import { Link, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import { SpinnerCircularSplit } from 'spinners-react';
import { useDependencyInjector } from '../context/DependencyInjector';
import ForgotPasswordFormChange from '../components/ChangePassword/ForgotPasswordFormChange';
import { Button } from 'private-wc-ui';


function ForgotPassword() {

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const [isInvalid, setIsInvalid] = useState<boolean | null>(null);

  const { authService } = useDependencyInjector();

  const nav = useNavigate();

  useEffect(() => {
    const id = searchParams.get('id');
    const userName = searchParams.get('username');
    if (id && userName) {
      authService.tokenIsValid(id, userName).then(value => {
        setIsInvalid(value);
        setLoading(false);
      }).catch(reason => {
        setIsInvalid(false);
        setLoading(false);
      });
    } else {
      setIsInvalid(false);
      setLoading(false);
    }

  }, []);


  return (
    <div
      className="flex justify-center align-middle items-center h-screen fade-in bg-center md:bg-cover"
      style={{ backgroundImage: `url(${bgImage})` }}>
      <div
        className="h-2/5 md:w-[75%] lg:w-[60vw] xl:w-[35vw] text-center md:mt-32 -mt-36 xl:-mt-36 flex flex-col justify-center align-middle items-center w-full">
        <img
          src={logo}
          className="lg:w-[328px] h-auto w-[75%] m-12 md:mb-10 md:m-0"
          alt=""></img>

        <div className="flex flex-col bg-white p-5 text-left w-[90vw] lg:w-[65vw] xl:w-[35vw]">

          {loading &&
            <div className={'w-full justify-center flex items-center flex-col gap-3'}><SpinnerCircularSplit
              color={'#BBAC79'}
              size={50}
              thickness={150}
            />
              <p>Validating reset token</p>
            </div>}
          {
            !isInvalid &&
            <p className={'text-center '}> Unfortunately the password reset link is no longer valid. Please try
              again.<br /><br />
              <Button
                label={'Reset your password'}
                theme={'primary'}
                onClick={() => {
                  {
                    nav('/login?forgot=true');
                  }
                }}
              />
            </p>
          }
          {
            isInvalid && <ForgotPasswordFormChange id={searchParams.get('id')!}
                                                   username={searchParams.get('username')!} />
          }
        </div>

      </div>
    </div>
  );
}

export default ForgotPassword;
