import React from 'react';
import { capitalizeFirstLetter } from '../../utils/FormattingUtils';

interface SwitcherProps {
  onClickHandler(folder: string): void;
  thisFolder: string;
  activeFolder: string;
}

function SwitcherButton(props: SwitcherProps) {
  return (
    <h5
      onClick={() => {
        props.onClickHandler(props.thisFolder);
      }}
      className={`select-none ${
        props.thisFolder == props.activeFolder
          ? 'underline text-bond'
          : 'font-normal text-grey-1 cursor-pointer'
      }`}>
      {capitalizeFirstLetter(props.thisFolder)}
    </h5>
  );
}

export default SwitcherButton;
