import React from 'react';
import Slider from '../Inputs/Slider';
import { UseMutationResult } from '@tanstack/react-query';
import { ContactPreferences } from '../../dto/ContactPreferences';
import LoadingBlock from '../Loading/LoadingBlock';
import ErrorBanner from '../Error/ErrorBanner';
import useToken from '../../hooks/useToken';
import { isDisabledForViewAs } from '../../utils/HelperUtils';

export interface HowToContactFormProps {
  data: ContactPreferences | undefined;
  isLoading: boolean;
  isError: boolean;
  mutation: UseMutationResult<
    ContactPreferences,
    unknown,
    ContactPreferences,
    unknown
  >;
  overrideDisabled?: boolean;
}

function HowToContactForm(props: HowToContactFormProps) {
  const auth = useToken();

  if (props.isLoading) {
    return <LoadingBlock />;
  }

  if (props.isError) {
    return (
      <ErrorBanner
        text={'An error occurred. Please try again.'}
        severe={true}
      />
    );
  }

  if (!props.data) {
    return null;
  }

  return (
    <div className="w-full h-full flex flex-col">
      <h5 className="text-sm h-[20%] ">How would you like to hear from us?</h5>
      <div>
        <Slider
          active={!props.data?.emailOptOut}
          onclick={() => {
            const update = {
              ...props.data,
              emailOptOut: !props.data?.emailOptOut,
            } as ContactPreferences;
            props.mutation.mutate(update);
          }}
          disabled={
            (auth.token != null && auth.token.group === 'Company') ||
            isDisabledForViewAs(auth)
          }
          title="Via email"
        />
        <Slider
          active={!props.data.phoneOptOut}
          onclick={() => {
            const update = {
              ...props.data,
              phoneOptOut: !props.data?.phoneOptOut,
            } as ContactPreferences;
            props.mutation.mutate(update);
          }}
          disabled={
            ((auth.token != null && auth.token.group === 'Company') || isDisabledForViewAs(auth))
          }
          title="Via phone"
        />
        <Slider
          active={!props.data.mailingOptOut}
          onclick={() => {
            const update = {
              ...props.data,
              mailingOptOut: !props.data?.mailingOptOut,
            } as ContactPreferences;
            props.mutation.mutate(update);
          }}
          disabled={
            (auth.token != null && auth.token.group === 'Company') ||
            isDisabledForViewAs(auth)
          }
          title="Via mail"
        />
      </div>
    </div>
  );
}

export default HowToContactForm;
