import React, { CSSProperties } from 'react';

export interface MobileUnderlyingItemProps {
  visible: boolean;
  label: string;
  value: string | number;
  textCol: CSSProperties['color'];
}

const MobileUnderlyingItem = (props: MobileUnderlyingItemProps) => {
  return (
    <div className={`w-full flex flex-row ${props.visible ? 'visible' : 'hidden'}`} style={{ color: props.textCol }}>
      <h5 style={{ color: props.textCol }}>{props.label}</h5>
      <div className="font-normal ml-2" style={{ color: props.textCol }}>
        {props.value}
      </div>
    </div>
  );
};

export default MobileUnderlyingItem;
