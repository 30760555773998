import moment from 'moment';


const formatStringToDate = (date: string) => {
  if (!date){
    return ""
  }
  const result = moment(date).format('DD/MM/YYYY');
  return result
}




export {formatStringToDate}