import React from 'react';

interface ErrorBannerProps {
  text: string;
  severe: boolean;
}

function ErrorBanner(props: ErrorBannerProps) {
  return (
    <div className="w-full h-[60px] bg-vistaWhite flex flex-row align-middle items-center fade-in mt-2 mb-2">
      <div
        className={`h-full w-[20px] ${
          props.severe ? 'bg-beret' : 'bg-downy'
        }`}></div>
      <h4 className="ml-[20px]">{props.text}</h4>
    </div>
  );
}

export default ErrorBanner;
