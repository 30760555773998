import React from 'react';

interface SliderProps {
  title: string;
  onclick: () => void;
  active: boolean;
  small?: boolean;
  disabled?: boolean;
}

function Slider(props: SliderProps) {
  return (
    <div className={`flex w-full ${props.small ? 'mt-3' : 'mt-4'}`}>
      <label
        htmlFor="asap"
        className={`flex align-middle items-center cursor-pointer ${props.disabled ? 'bg-gray-300 cursor-not-allowed opacity-50' : ''}`}>
        <div className="relative">
          <input type="checkbox" id="asap" className="sr-only" />
          <div
            className={`${
              props.active
                ? 'bg-midasLight'
                : 'bg-white border-2 border-vistaWhite'
            } w-14 ${props.small ? 'h-6' : 'h-8'} rounded-full `}
            onClick={() => {
              if (!props.disabled) {
                props.onclick();
              }
            }}></div>
          <div
            className={`dot absolute left-1 top-1 ${
              props.small ? 'h-4 w-4' : 'w-6 h-6'
            } rounded-full transition duration-300 ${
              props.active
                ? `${
                  props.small
                    ? 'bg-white translate-x-[200%]'
                    : 'bg-white translate-x-[100%]'
                }`
                : 'bg-vistaWhite'
            } ${props.disabled ? 'bg-gray-300 cursor-not-allowed opacity-50' : ''}`}
            onClick={() => {
              if (!props.disabled) {
                props.onclick();
              }
            }}></div>
        </div>
        <div className="ml-3">
          <p className="text-sm">{props.title}</p>
        </div>
      </label>
    </div>
  );
}

export default Slider;
