import React from 'react';
import ButtonComponent from '../Buttons/Button';
import { Button } from 'private-wc-ui';

interface CTABoxProps {
  title: string;
  subtitle: string;
  buttonText: string;
  onclick?: () => void;
}

function CTABox(props: CTABoxProps) {
  return (
    <div
      className="w-full md:w-[100%] h-auto pt-1 pb-2 md:mt-2 mb-3 flex flex-col"
      style={{
        backgroundImage: `url(/images/blue-swirl-bg.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}>
      <div className="w-[90%] h-[65%]">
        <h3 className="text-white ml-6 mt-6">{props.title}</h3>
        <p className="text-white text-xs ml-6">{props.subtitle}</p>
      </div>
      <div className="lg:w-[20%] w-[80%] ml-[10%] lg:ml-6 mt-4 mb-6">
        <Button
          style={{
            width: '100%',
            position: 'relative',
          }}
          label={props.buttonText}
          theme={'primary'}
          onClick={() => {
            {
              if (props.onclick) {
                props.onclick();
              }
            }
          }}
        />
      </div>
    </div>
  );
}

export default CTABox;
