import React, { useState } from 'react';
import { Button } from 'private-wc-ui';
import PasswordModal from './PasswordModal';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import useToken from '../../hooks/useToken';

const PasswordBanner = () => {
  const [showModal, setShowModal] = useState(false);

  const { authService } = useDependencyInjector();

  const auth = useToken();

  const { isLoading, data } = useQuery({
    queryKey: ['password-new-required'],
    staleTime: Infinity,
    queryFn: async () => {
      return await authService.getNewPwdRequired();
    },
    enabled: auth.token.as === undefined,
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div
        className={`${
          data ? 'visible' : 'hidden'
        } w-full flex flex-col md:flex-row justify-center gap-4 md:gap-6 items-center overflow-hidden bg-gray-50 px-6 py-2.5 bg-bond max-h-[280px] h-auto md:h-auto text-white`}>
        <div className={'p-2'}>
          <h5 className={'text-white'}>Welcome to Wealth Club Portal</h5>
          <br />
          <p className="text-sm text-white">
            You’re now logged in to your Wealth Club Portal where you can see all the
            investments you make through Wealth Club.
            <br />
            Portal username: <b>{auth.token.user.username}</b>
          </p>
          <br />
          <p className="text-sm text-white">
            To complete the Portal set up, please choose a password.
          </p>
        </div>
        <div className={'h-full'}>
          <Button
            label="Set password now"
            onClick={() => setShowModal(!showModal)}
            theme="primary"
          />
          <PasswordModal
            showPasswordModal={showModal}
            closeModal={() => {
              setShowModal(false);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PasswordBanner;
