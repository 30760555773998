import React, { useState } from 'react';
import ButtonComponent from '../Buttons/Button';
import { useFormik } from 'formik';
import LoginInput from '../Inputs/Input';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useRedirect from '../../hooks/useRedirect';
import { Button } from 'private-wc-ui';

interface ResetFormProps {
  setPasswordReset: (value: React.SetStateAction<boolean>) => void;
}

interface PasswordResetForm {
  userName: string;
}

interface Errors {
  userName?: string;
}


enum ResetType {
  Magic = 'MAGIC',
  Standard = 'STANDARD',
}

function PasswordReset(props: ResetFormProps) {

  const { authService } = useDependencyInjector();
  const navigate = useNavigate();

  const redirect = useRedirect();


  const handleMagicLink = (values: PasswordResetForm) => {
    const redirectUrl = redirect.redirect ? redirect.redirect : '';

    authService.requestMagicLink(values.userName, redirectUrl).then(value => {
      formik.setSubmitting(false);
      navigate({
        pathname: '/auth/conf',
        search: createSearchParams({
          msg_type: 'magic',
        }).toString(),
      });
    });
  };

  const handleReset = (values: PasswordResetForm) => {
    authService.requestPasswordReset(values.userName).then(value => {
      formik.setSubmitting(false);
      navigate({
        pathname: '/auth/conf',
        search: createSearchParams({
          msg_type: 'pwd',
        }).toString(),
      });
    });
  };


  const [submitType, setSubmitType] = useState<ResetType | null>();

  const initialValues: PasswordResetForm = { userName: '' };
  const formik = useFormik({
    initialValues: initialValues,
    validate: (values) => {
      const errors: Errors = {};
      if (values.userName.length == 0) {
        errors.userName = 'Please enter a valid username or email.';
      }

      return errors;
    },
    onSubmit: (values) => {
      if (submitType === ResetType.Magic) {
        handleMagicLink(values);
      } else {
        handleReset(values);
      }

    },
  });


  return (
    <div className="w-full h-full fade-in flex flex-col">
      <h4>Reset Your Password</h4>
      <p className="mt-2">
        To reset your password, please enter your username below and click
        &#39;Reset Password&#39;.
        <br /> <br /> You will receive an email with further instructions to
        complete the reset process. If you have any problems or have forgotten
        your username, please call 0117 929 0511 or email
        enquiries@wealthclub.co.uk
      </p>
      <h4 className="mt-4">Request &#39;Magic Link&#39;</h4>
      <p className="mt-2">
        Alternatively, we can send you an email containing a link which will
        immediately log you into the Portal.
        <br /> <br />
        This may be useful if you are an infrequent user of our Portal and do
        not remember your login details.
      </p>
      <form
        onSubmit={formik.handleSubmit}>
        <div className={'w-full'}>
          <LoginInput
            name={'userName'}
            value={formik.values.userName}
            onChange={formik.handleChange}
            error={
              formik.errors.userName != undefined &&
              formik.errors.userName.length != 0 &&
              formik.touched.userName
            }
            className={'w-full mt-2 mb-2'}
            placeholder={'username/email'}
            id={'userName'}
            type={'text'}></LoginInput>
          {formik.errors.userName && formik.touched.userName ? (
            <div style={{ color: 'red' }}>{formik.errors.userName}</div>
          ) : null}

        </div>

        <div className="w-full flex flex-row justify-between mt-4">
          <Button
            label="Reset Password"
            theme="primary"
            type={'submit'}
            style={{ width: '45%' }}
            disabled={formik.isSubmitting && submitType === ResetType.Standard}
            onClick={() => setSubmitType(ResetType.Standard)}
          />
          <Button
            label="Send Magic Link"
            theme="primary"
            style={{ width: '45%' }}
            type={'submit'}
            disabled={formik.isSubmitting && submitType === ResetType.Magic}
            onClick={() => setSubmitType(ResetType.Magic)}
          />
        </div>
      </form>
      <div className="w-full text-right mt-4">
        <a
          onClick={() => props.setPasswordReset(false)}
          href="#"
          className="text-sm text-bond text-center underline md:ml-[31%]">
          Return to login
        </a>
      </div>
    </div>
  );
}

export default PasswordReset;
