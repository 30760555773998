import React, { useMemo } from 'react';
import { NonCustodyHolding } from '../../../../dto/Holding';
import { formatMoney, formatNum } from '../../../../utils/FormattingUtils';
import { translateHoldingPhase } from '../../../../utils/HelperUtils';
import { useParams } from 'react-router-dom';
import { TableConfig } from '../../../../config/Config';
import useTableConfig from '../../../../hooks/useTableConfig';
import { formatStringToDate } from '../../../../utils/DateUtils';
import MobileUnderlyingItem from './MobileUnderlyingItem';
import { sortInvestmentsByPhaseAndInvDate } from '../../../../utils/SortUtils';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../../../context/DependencyInjector';

interface UnderlyingProps {
  topLevelID: string;
  data: NonCustodyHolding[];
  setHoldingHistoryId: (id: string) => void;
  currentHistoryId: string | null;
}

function MobileUnderlying(props: UnderlyingProps) {




  const { child, cat } = useParams();

  const retrieveConfig = (child: string | undefined, cat: string | undefined): TableConfig => {
    const config = useTableConfig();
    return child === undefined ? config.getConfig(cat) : config.getConfig(child);
  };


  const tableConfig = useMemo(() => retrieveConfig(child, cat), [child, cat]);
  const { investmentService } = useDependencyInjector();
  const { accountId } = useParams();


  const tableHeader = props.data.filter(
    (f) => f.parentHolding == props.topLevelID,
  );

  return (
    <>
      {tableHeader.map((value, index) => {
        const children = props.data.filter(
          (f) => f.parentHolding == value.id,
        ).sort(sortInvestmentsByPhaseAndInvDate);

        const { data: countData } = useQuery({
          queryKey: ['history-count', value.id, accountId],
          queryFn: async () => {
            if (value.id) {
              return await investmentService.getHoldingHistoryCount(value.id);
            }
            if (accountId) {
              return await investmentService.getHoldingHistoryCountDfm(accountId);
            }
            return { holdingHistoryCount: 0 };
          },
          enabled: !!value.id || !!accountId,
        });

        const historyCount = countData?.holdingHistoryCount ?? 0;

        return (
          <div key={index}>
            <div className="w-full bg-bond h-auto p-2 mt-2 flex flex-col text-sm">
              <h5
                className={`text-white text-lg ${tableConfig.levelTwo != null && tableConfig.levelTwo.investment.name ? 'visible' : 'hidden'}`}>{value.investment.name}</h5>
              <hr
                className={`border-1 w-full mt-2 mb-2 border-vistaWhite  ${tableConfig.levelTwo != null && tableConfig.levelTwo.investment.name ? 'visible' : 'hidden'}`} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.phase} label={'Phase:'}
                                    value={translateHoldingPhase(value.phase)} textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.value} label={'Value:'}
                                    value={formatMoney(value.value)} textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.cost} label={'Cost:'} value={formatMoney(value.cost)}
                                    textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.growth} label={'Growth:'}
                                    value={formatMoney(value.growth)} textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.growthPercentage} label={'Growth (%):'}
                                    value={formatNum(value.growthPercentage)} textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.units} label={'Units:'} value={formatNum(value.units)}
                                    textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.unitPrice} label={'Unit price:'}
                                    value={formatMoney(value.unitPrice)} textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.invDate} label={'Investment date:'}
                                    value={formatStringToDate(value.invDate)} textCol={'white'} />

              <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.lastValuedAt} label={'Last valued at:'}
                                    value={formatStringToDate(value.lastValuedAt)} textCol={'white'} />
              {historyCount > 0 && (
                <div className="w-full flex flex-row mt-2 mb-2">
                  {value.id === props.currentHistoryId ? <a onClick={() => {
                      props.setHoldingHistoryId(value.id);
                    }} className={'text-sm text-midas underline hover:cursor-pointer'}>Close History</a> :
                    <a
                      className={`text-sm text-midas underline hover:cursor-pointer font-bold ${translateHoldingPhase(value.phase) === 'Pending' ? 'hidden' : 'visible'}`}
                      onClick={() => {
                        props.setHoldingHistoryId(value.id);
                      }}>View History</a>}
                </div>)}
            </div>
            <div className="w-full bg-sand h-auto p-2 flex flex-col text-sm mb-8">
              {children.map((value, index) => {
                return (
                  <div key={index} className="w-full">
                    <h5
                      className={`text-base ${tableConfig.levelTwo != null && tableConfig.levelTwo.investment.name ? 'visible' : 'hidden'}`}>{value.investment.name}</h5>
                    <hr
                      className={`border-1 w-full mt-2 mb-2 border-tuxedo ${tableConfig.levelTwo != null && tableConfig.levelTwo.investment.name ? 'visible' : 'hidden'}`} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.phase} label={'Phase:'}
                                          value={translateHoldingPhase(value.phase)} textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.value} label={'Value:'}
                                          value={formatMoney(value.value)} textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.cost} label={'Cost:'}
                                          value={formatMoney(value.cost)} textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.growth} label={'Growth:'}
                                          value={formatMoney(value.growth)} textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.growthPercentage} label={'Growth (%):'}
                                          value={value.growthPercentage + ' %'} textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.units} label={'Units:'} value={value.units}
                                          textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo && tableConfig.levelTwo.unitPrice} label={'Unit price:'}
                                          value={value.unitPrice} textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo && tableConfig.levelTwo.invDate} label={'Investment date:'}
                                          value={formatStringToDate(value.invDate)} textCol={'black'} />

                    <MobileUnderlyingItem visible={tableConfig.levelTwo != null && tableConfig.levelTwo.lastValuedAt} label={'Last valued at:'}
                                          value={formatStringToDate(value.lastValuedAt)} textCol={'black'} />
                    <div className="w-full flex flex-row">
                      {value.id === props.currentHistoryId ? <a onClick={() => {
                          props.setHoldingHistoryId(value.id);
                        }} className={'text-sm text-midas underline hover:cursor-pointer'}>Close history</a> :
                        <a className={'text-sm text-midas underline hover:cursor-pointer font-bold'} onClick={() => {
                          props.setHoldingHistoryId(value.id);
                        }}>View history</a>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default MobileUnderlying;