import React, {useEffect, useState} from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import {useQuery} from '@tanstack/react-query';
import {useDependencyInjector} from '../../context/DependencyInjector';
import DropdownButton from '../../components/Buttons/DropdownButton';
import TrendingOffersBox from '../../components/PageSection/TrendingOffersBox';
import {base64ToArrayBuffer} from '../../utils/HelperUtils';
import ErrorBanner from '../../components/Error/ErrorBanner';
import TransactionTable from '../../components/TransactionHistory/TransactionTable';
import LoadingBlock from '../../components/Loading/LoadingBlock';
import moment from 'moment';
import useWindowSize from "../../hooks/useWindowSize";
import {formatStringToDate} from "../../utils/DateUtils";
import {formatMoney} from "../../utils/FormattingUtils";
import StatusComponent from "../../components/Status/StatusBlock";
import ApplicationStatus from "../../components/TransactionHistory/ApplicationStatusSelector";

interface QueryParams {
    startDate: Date,
    endDate: Date,
}


function ApplicationHistory() {
    const {clientService, transactionService} = useDependencyInjector();



    const [query, setQueryParams] = useState<QueryParams | null>();

    const { width } = useWindowSize();

    useEffect(() => {
        setQueryParams(calculateDateRange(getDefaultValue()));
    }, []);


    const {
        isLoading: yearLoaded,
        isError: yearError,
        data: minYear,
    } = useQuery({
        queryKey: ['years'],
        queryFn: clientService.getTransactionMinDate,
    });


    const {isLoading, isError, data} = useQuery({
        queryKey: ['transactions', query],
        queryFn: async () => {
            if (query?.startDate && query.endDate) {
                const start = moment(query?.startDate).format('YYYY-MM-DD');
                const end = moment(query?.endDate).format('YYYY-MM-DD');
                return await clientService.getAllTransactions(start, end);
            }
            return [];
        },
        enabled: !!query,
    });

    const calculateDateRange = (year: number): QueryParams => {
        const startDate = new Date(year - 1, 3, 6);
        const endDate = new Date(year, 3, 5);

        const queryParam = {
            startDate: startDate,
            endDate: endDate,

        } as QueryParams;

        return queryParam;
    };


    const getDefaultValue = () => {
        const tday = new Date();

        if ((tday.getDate() >= 6 && tday.getMonth() == 3) || (tday.getMonth() >= 4) ) {
            return tday.getFullYear() + 1;
        }
        return tday.getFullYear();

    };

    const renderOptions = (minDate: Date) => {
        const currentDate = new Date().getFullYear();

        const years = [];

        for (let i = minDate.getFullYear(); i <= currentDate; i++) {
            years.push(
                <option key={i} value={i}>
                    Tax year: {(i - 1)}/{i.toString().slice(-2)}
                </option>,
            );
        }
        // if we are later in the year we need add the current year on
        const tday = new Date();

        if ((tday.getDate() >= 6 && tday.getMonth() == 3) || (tday.getMonth() >= 4)) { // monthIndex is zero index so it is 3
            years.push(
                <option key={tday.getFullYear() + 1} value={tday.getFullYear() + 1} className={'tabular-nums'}>
                    Tax year: {tday.getFullYear()}/{(tday.getFullYear() + 1).toString().slice(-2)}
                </option>,
            );
        }

        return years.reverse();
    };

    type DropdownItems = {
        title: string;
        onClick: () => void;
    };

    const downloadReport = async (year: string) => {
        const result = await transactionService.downloadTransactionReport(year);
        const buff = base64ToArrayBuffer(result.content);
        const file = new File([buff], result.fileName, {type: result.mime});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = result.fileName;
        link.click();
    };

    function generateDrops() {
        const drops: Array<DropdownItems> = [];

        if (data?.length != 0 && query?.endDate) {
            drops.push({
                title: `Export All ${(query?.endDate.getFullYear() - 1)}/${query?.endDate.getFullYear().toString().slice(-2)} Tax Year Transactions`,
                onClick: async () => {
                    if (query?.endDate) {
                        await downloadReport(query?.startDate.getFullYear().toString() + '-' + (query?.endDate.getFullYear() - 2000).toString());
                    }
                },
            });
        }

        drops.push({
            title: 'Export All Transactions',
            onClick: async () => {
                await downloadReport('');
            },
        });
        return drops;
    }

    return (
        <ContentComponent title="Account settings" subtitle="Application history" increaseHeightForStickyButton={768 > width}>
            <div className="w-[100%] h-fit mb-12">
                <div className="flex items-center justify-center md:justify-start mt-6">
                    <select
                        className={`${
                            yearLoaded ? 'animate-pulse blur-sm' : ''
                        } p-3 bg-white border-2 shadow-md border-midasLight focus:outline-midasLight md:w-auto w-full rounded`}
                        onChange={(event) => {
                            const range = calculateDateRange(Number(event.target.value));
                            setQueryParams(range);
                        }}
                        defaultValue={getDefaultValue()}>
                        {minYear && renderOptions(minYear)}
                    </select>
                </div>
                {isError && !data && (
                    <div className="mt-8 mb-8">
                        <ErrorBanner
                            severe={true}
                            text="An unknown error occurred please try again."
                        />
                    </div>
                )}

                {data && !isError && !isLoading && data?.length === 0 && (
                    <div className="mt-8 mb-8">
                        <ErrorBanner
                            severe={false}
                            text={`You did not make any investments in ${query?.startDate.getFullYear()}/${query?.endDate.getFullYear().toString().slice(-2)}`}
                        />
                    </div>
                )}

                {(isLoading) && (
                    <div className="h-[20vh] mt-8 mb-8">
                        <LoadingBlock
                        />
                    </div>
                )}

                {data && width > 1000 && !isLoading && !isError && data?.length != 0 && (
                    <TransactionTable data={data}/>
                )}

                {data && width < 1000 && !isLoading && !isError && data?.length != 0 &&
                    data.map((value, index) => {
                        return <div key={index} className="w-full bg-grey-2  h-auto flex flex-col text-sm mt-4 mb-4 ">
                            <h5 className="text-white text-lg bg-bond p-2">{value.investmentName}</h5>
                            <div className="w-full flex flex-row ml-2 mt-2">
                                <h5>Date Received:</h5>
                                <div className="font-normal ml-2">
                                    {formatStringToDate(value.businessReceived)}
                                </div>
                            </div>
                            <hr className="border-1 w-full mt-2 mb-2 border-vistaWhite"/>
                            <div className="w-full flex flex-row ml-2 mt-2">
                                <h5 className="">Type:</h5>
                                <div className="font-normal ml-2">
                                    {value.type}
                                </div>
                            </div>
                            <hr className="border-1 w-full mt-2 mb-2 border-vistaWhite"/>
                            <div className="w-full flex flex-row ml-2 mt-2">
                                <h5 className="">Amount invested:</h5>
                                <div className="font-normal ml-2" data-cy={`amountInvestedMobile${value.amountInvested}`}>
                                    {formatMoney(value.amountInvested, value.currency)}
                                </div>
                            </div>
                            <hr className="border-1 w-full mt-2 border-vistaWhite"/>
                            <div className="w-full ">
                                <ApplicationStatus status={value.status}/>
                            </div>
                        </div>
                    })
                }

                <div className="w-full fixed bottom-0 mt-4 left-0 right-0 bg-white p-4 h-40 md:h-auto md:static md:p-0">
                    <DropdownButton
                        title="Export PDF"
                        styleType={'primary'}
                        drops={generateDrops()}
                        dataCy={"exportPdfButton"}
                    />
                </div>

                <TrendingOffersBox/>

            </div>
        </ContentComponent>
    );
}

export default ApplicationHistory;