import ReturnLink from '../components/ReturnLink/Return';
import React, {useEffect, useState} from 'react';
import logo from '../images/wealth-club-portal-logo.svg';
import bgImage from '../images/login_backround.png';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {useDependencyInjector} from '../context/DependencyInjector';
import LoginForm from '../components/Login/LoginForm';
import PasswordReset from '../components/Login/PasswordReset';
import useRedirect from '../hooks/useRedirect';
import {SpinnerCircularSplit} from 'spinners-react';
import ErrorMessage from "../errors/ErrorMessage";
import useToken from "../hooks/useToken";


function LoginComponent() {
    const {securityService} = useDependencyInjector();
    const {authService} = useDependencyInjector();

    const [passwordReset, setPasswordReset] = useState<boolean>(false);

    const [forceUserName, setForceUserName] = useState<string | null>();

    const loginParaText = "The Portal account is created the first time you invest through Wealth Club and shows you all the investments you've made.";

    const [searchParams ] = useSearchParams();

    const [loading, setLoading] = useState(false);

    const { setToken } = useToken();

    const redirect = useRedirect();

    const nav = useNavigate();


    /**
     * When forgot is in the search param ensure the component is rendered with the password reset screen showing
     */
    function initiateForgotPasswordFromSearchParams(params: URLSearchParams) {
        const forgot = params.get('forgot');
        if (params.has('forgot') && forgot === 'true') {
            setPasswordReset(true);
        } else {
            setPasswordReset(false);
        }
    }

    function handleForceUserName(params: URLSearchParams) {
        if (params.has('username')) {
            const username = params.get('username');
            if (username) {
                setForceUserName(username);
            }
        }
    }

    function removeRedirectSearchParams(params: URLSearchParams) {
        params.delete('username');
        params.delete('token');
        params.delete('redirect');
        nav('?' + createSearchParams(params).toString());
    }

    function handleLoginWithRedirect(params: URLSearchParams) {
        if (params.has('redirect')) {
            const url = params.get('redirect');
            if (url) {
                setLoading(true);
                securityService.validateUrlForRedirect(url)
                    .then(result => {
                        if (result) {
                            handleForceUserName(params);
                            redirect.storeRedirect(url);
                            removeRedirectSearchParams(params);
                        }
                    }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    function handleLoginWithToken(params: URLSearchParams) {
        if (params.has('token')) {
            const token = params.get('token');
            if (token) {
                setLoading(true);

                authService.loginWithToken(token)
                    .then((user) => {
                    setToken(user);
                    if (user.group == 'Company') {
                        scrollTo(0, 0);
                        nav('/Search');
                        return;
                    }
                    scrollTo(0, 0);
                    nav('/dashboard');
                })
                    .catch((reason) => {
                        console.log(reason as ErrorMessage);
                    });
            }
        }
    }


    useEffect(() => {
        initiateForgotPasswordFromSearchParams(searchParams);
        handleLoginWithRedirect(searchParams);
        handleLoginWithToken(searchParams)
    }, [searchParams]);


    return (
        <div
            className="flex justify-center align-middle items-center h-screen fade-in bg-center md:bg-cover"
            style={{backgroundImage: `url(${bgImage})`}}>
            <div
                className="h-2/5 md:w-[75%] lg:w-[60vw] xl:w-[35vw] text-center md:mt-32 -mt-12 xl:-mt-36 flex flex-col justify-center align-middle items-center w-full pt-2">
                <img
                    src={logo}
                    className="lg:w-[328px] h-auto w-[75%] m-12 md:mb-10 md:m-0"
                    alt=""></img>

                {loading && <SpinnerCircularSplit className={"w-10 h-10 "} color={'#BBAC79'} thickness={150}/>}

                {!loading &&
                    <div className="flex flex-col bg-white p-5 text-left w-[90vw] lg:w-[65vw] xl:w-[35vw]">
                        {passwordReset ? (
                            <PasswordReset setPasswordReset={setPasswordReset}/>
                        ) : (
                            <LoginForm setPasswordReset={setPasswordReset} forceUserName={forceUserName} loginParaText={loginParaText} loginCaptionText={"Log in"}/>
                        )}
                    </div>}

                <div className="md:w-full flex flex-row md:mt-6 justify-around h-72">
                    <div className="md:w-[40%] w-full mt-10 md:mt-1">
                        <ReturnLink/>
                    </div>
                    <div className="w-[30%] h-full hidden md:block">
                        <img
                            src={'https://wc-general-bucket.s3.eu-west-1.amazonaws.com/award-logos/2022-gia-best-investment-platform.svg'}
                            className="inline max-h-[100px] h-auto w-auto"></img>
                    </div>
                    <div className="w-[30%] h-full hidden md:block">
                        <img
                            src={'https://wc-general-bucket.s3.eu-west-1.amazonaws.com/award-logos/2023-gia-best-investment-platform.svg'}
                            className="inline max-h-[100px] h-auto w-auto"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;
