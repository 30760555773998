import bgImage from '../../images/login_backround.png';
import logo from '../../images/wealth-club-portal-logo.svg';
import React, { ReactNode } from 'react';



type PropsWithChildren<P> = P & { children?: ReactNode };


function BlueBackgroundScreen({children, ...props}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {


  return (
    <div
      className="flex md:justify-center md:align-middle md:items-center md:h-screen fade-in bg-center md:bg-cover"
      style={{ backgroundImage: `url(${bgImage})` }}>
      <div {...props}
        className="h-2/5 md:w-[75%] lg:w-[60vw] xl:w-[35vw] text-center md:mt-32 -mt-36 xl:-mt-36 flex flex-col justify-center align-middle items-center w-full">
        <img
          src={logo}
          className="lg:w-[328px] h-auto w-[75%] m-12 md:mb-10 md:m-0"
          alt=""></img>


        <div>
          {children}
        </div>
      </div>

    </div>

  )
}

export default BlueBackgroundScreen