import {
  ContactFrequencyPreferences,
  ContactPreferences,
} from '../dto/ContactPreferences';
import { OptOutSnooze } from '../components/ContactDetails/Mut';
import axios from 'axios';
import moment from 'moment';
import { getApiBaseUrl } from '../utils/HelperUtils';

export interface IPreferenceUnsubService {
  getProductInterests(wcid: string): Promise<Map<string, boolean>>;

  updateInterests(
    data: Map<string, boolean>,
    wcid: string,
  ): Promise<Map<string, boolean>>;

  getContactPreferences(wcid: string): Promise<ContactPreferences>;

  updateContactPreferences(
    contactPreferences: ContactPreferences,
    wcid: string,
  ): Promise<ContactPreferences>;

  getContactFrequency(wcid: string): Promise<ContactFrequencyPreferences>;

  updateContactFrequency(
    data: ContactFrequencyPreferences,
    wcid: string,
  ): Promise<ContactFrequencyPreferences>;

  getTempOptOut(wcid: string): Promise<OptOutSnooze>;

  updateTempOptOut(date: Date | null, wcid: string): Promise<void>;
}

export class UnsubService implements IPreferenceUnsubService {
  getContactFrequency(wcid: string): Promise<ContactFrequencyPreferences> {
    return new Promise((resolve, reject) => {
      axios
        .get(getApiBaseUrl() + '/unsubscribe/contact/freq?id=' + wcid)
        .then((res) => {
          const result = res.data as ContactFrequencyPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getContactPreferences(wcid: string): Promise<ContactPreferences> {
    return new Promise((resolve, reject) => {
      axios
        .get(getApiBaseUrl() + '/unsubscribe/contact?id=' + wcid)
        .then((res) => {
          const result = res.data as ContactPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getProductInterests(wcid: string): Promise<Map<string, boolean>> {
    return new Promise((resolve, reject) => {
      axios
        .get(getApiBaseUrl() + '/unsubscribe/interests?id=' + wcid)
        .then((res) => {
          const results = new Map(Object.entries(res.data)) as Map<
            string,
            boolean
          >;
          resolve(results);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getTempOptOut(wcid: string): Promise<OptOutSnooze> {
    return new Promise((resolve, reject) => {
      axios
        .get(getApiBaseUrl() + '/unsubscribe/opt?id=' + wcid)
        .then((res) => {
          const mut = { snooze: null } as OptOutSnooze;
          if (res.data['tempOptOut'] === null) {
            resolve(mut);
            return;
          }
          const date = moment(res.data['tempOptOut'], 'YYYY-MM-DD').toDate();
          mut.snooze = date;
          resolve(mut);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateContactFrequency(
    data: ContactFrequencyPreferences,
    wcid: string,
  ): Promise<ContactFrequencyPreferences> {
    return new Promise((resolve, reject) => {
      axios
        .patch(getApiBaseUrl() + '/unsubscribe/contact/freq?id=' + wcid, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          const result = res.data as ContactFrequencyPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateContactPreferences(
    contactPreferences: ContactPreferences,
    wcid: string,
  ): Promise<ContactPreferences> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          getApiBaseUrl() + '/unsubscribe/contact?id=' + wcid,
          contactPreferences,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((res) => {
          const result = res.data as ContactPreferences;
          resolve(result);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateInterests(
    data: Map<string, boolean>,
    wcid: string,
  ): Promise<Map<string, boolean>> {
    const requestData = JSON.stringify(Object.fromEntries(data));
    return new Promise((resolve, reject) => {
      axios
        .put(
          getApiBaseUrl() + '/unsubscribe/interests?id=' + wcid,
          requestData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((res) => {
          const results = new Map(Object.entries(res.data)) as Map<
            string,
            boolean
          >;
          resolve(results);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  updateTempOptOut(date: Date, wcid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          getApiBaseUrl() + '/unsubscribe/opt?id=' + wcid,
          { tempOptOut: date ? moment(date).format('YYYY-MM-DD') : null },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((res) => {
          resolve();
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}
