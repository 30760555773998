import React from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import CTABox from '../../components/CallToAction/CTABox';
import { useParams } from 'react-router-dom';
import InvestmentOverview from '../../components/Investments/InvestmentOverview';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { CtaLocation } from '../../services/CtaService';
import WarningMessage from "../../components/Warning/WarningMessage";
import {DEFAULT_WARN_MSG} from "../../utils/Constants";


function InvestmentTemplate() {
  const { parent } = useParams();

  const {ctaService} = useDependencyInjector()


  const {  data } = useQuery({
    queryKey: ['investmentCta'],
    staleTime:Infinity,
    queryFn:
      async () => {
        try {
          return await ctaService.getCta(CtaLocation.PORTFOLIO_INVESTMENT);
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              return undefined;
            }
          }
          throw error;
        }
      },
  });



  return (
    <div>
      <ContentComponent title={parent ? "My portfolio > " + parent.replaceAll('_', ' ') : ''} subtitle={''}>
        <div className="w-[100%] h-fit mb-12">
          <InvestmentOverview
            humanCategory={parent}
          />

          <WarningMessage message={DEFAULT_WARN_MSG}/>

          <div className="w-full mt-10">
            <div className="flex md:flex-row flex-wrap min-h-[40%] h-auto mt-[2%] mb-[2%] justify-around flex-col">
              {data != undefined &&
                <CTABox
                  title={data.header}
                  subtitle={data.body}
                  buttonText={data.buttonLabel}
                  onclick={() => {
                    window.location.href = data?.buttonUrl;
                  }}
                />
              }
            </div>
          </div>
        </div>
      </ContentComponent>
    </div>
  );
}

export default InvestmentTemplate;
