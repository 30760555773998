import React from 'react';
import Authentication from '../../services/Authentication';
import useToken from '../../hooks/useToken';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from '../../images/icons/icon-logout.svg';
import { useDropDownContext } from '../../context/DropDownContext';
import { useCookies } from 'react-cookie';

function LogoutComponent() {
  const { token, removeToken } = useToken();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  const drop = useDropDownContext();
  const [cookies, setCookie] = useCookies(['logout_now']);

  return (
    <div
      title="Logout"
      className="flex flex-row align-middle items-center"
      onClick={() => {
        const auth = new Authentication();
        if (token) {
          drop.setDropDown(null);
          auth
            .logout(token)
            .finally(async () => {
                await queryClient.invalidateQueries();
                removeToken();
                setCookie("logout_now", 1,{ path: '/' });
                nav('/Login');
              },
            );
        }
      }}>
      <LogoutIcon
        title="Logout"
        className="fill-[#b5a464] h-6 w-6"
      />
      <p
        className="underline text-sand uppercase font-sans text-[13px] ml-2 hover:cursor-pointer">
        Log out
      </p>
    </div>
  );
}

export default LogoutComponent;
