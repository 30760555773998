import React from 'react';
import InterestsForm, { InterestsVal } from './InterestsForm';
import HowToContactForm from './HowToContactForm';
import HowOftenForm from './HowOftenForm';
import PauseMembershipForm from './PauseMembershipForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import PermPopUp from './PermPopUp';
import LoadingBlock from '../Loading/LoadingBlock';
import ErrorBanner from '../Error/ErrorBanner';
import { OptOutMutation } from './Mut';
import moment from 'moment/moment';
import useToken from '../../hooks/useToken';
import { isDisabledForViewAs } from '../../utils/HelperUtils';
import {
  ContactFrequencyPreferences,
  ContactPreferences,
} from '../../dto/ContactPreferences';

function PreferenceForm() {
  const { preferenceService } = useDependencyInjector();

  const queryClient = useQueryClient();

  const auth = useToken();

  const {
    data: contactPrefs,
    isLoading: isLoadingContactPrefs,
    isError: isErrorContactPrefs,
  } = useQuery({
    queryKey: ['contact-prefs'],
    queryFn: async () => {
      return await preferenceService.getContactPreferences();
    },
  });

  const contactPrefMutation = useMutation({
    mutationFn: (prefs: ContactPreferences) => {
      return preferenceService.updateContactPreferences(prefs);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['contact-prefs'], data);
      queryClient.invalidateQueries(['preferences-freq']);
    },
  });

  const {
    data: interestData,
    isLoading: interestLoading,
    isError: interestError,
  } = useQuery({
    queryKey: ['preferences'],
    queryFn: async () => {
      return await preferenceService.getProductInterests();
    },
  });

  const interestMutation = useMutation({
    mutationFn: (interestsVal: InterestsVal) => {
      const cloneData = new Map(interestData);
      cloneData.set(interestsVal.name, interestsVal.value);
      return preferenceService.updateInterests(cloneData);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['preferences'], data);
    },
  });

  const {
    data: freqData,
    isLoading: freqLoading,
    isError: freqError,
  } = useQuery({
    queryKey: ['preferences-freq'],
    queryFn: async () => {
      return await preferenceService.getContactFrequency();
    },
  });

  const snoozeMutation = useMutation({
    mutationFn: (val: OptOutMutation) => {
      let date = null;
      if (val.snooze) {
        date = moment(new Date()).add(parseInt(val.snooze), 'M').toDate();
      }
      return preferenceService.updateTempOptOut(date);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['preferences-opt-out']);
    },
  });

  const freqMutation = useMutation({
    mutationFn: (val: ContactFrequencyPreferences) => {
      // ensure that at least one check box is checked
      if (val.monthly && val.asap && val.weekly) {
        val.asap = true;
      }
      return preferenceService.updateContactFrequency(val);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['preferences-freq'], data);
    },
  });

  const mutation = useMutation({
    mutationFn: (val: OptOutMutation) => {
      let date = null;
      if (val.snooze) {
        date = moment(new Date()).add(parseInt(val.snooze), 'M').toDate();
      }
      return preferenceService.updateTempOptOut(date);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['preferences-opt-out']);
    },
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ['preferences-opt-out'],
    queryFn: async () => {
      return await preferenceService.getTempOptOut();
    },
  });

  const mutationResub = useMutation({
    mutationFn: (val: OptOutMutation) => {
      let date = null;
      if (val.snooze) {
        date = moment(new Date()).add(parseInt(val.snooze), 'M').toDate();
      }
      return preferenceService.updateTempOptOut(date);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['preferences-opt-out']);
    },
  });

  if (isLoading) {
    return <LoadingBlock />;
  }

  if (isError) {
    return (
      <ErrorBanner
        text={
          'An error occurred loading your marketing preferences. Please try again.'
        }
        severe={true}
      />
    );
  }

  if (data?.snooze === null) {
    return (
      <div className={'bg-white text-left w-full  rounded-xl shadow-lg p-8'}>
        <h4 className="mb-4">Marketing Preferences</h4>
        <div
          className={
            ' xl:grid xl:grid-cols-2 xl:gap-y-4 flex flex-col items-center justify-center gap-8 '
          }>
          <HowToContactForm
            data={contactPrefs}
            isError={isErrorContactPrefs}
            isLoading={isLoadingContactPrefs}
            mutation={contactPrefMutation}
          />
          <HowOftenForm
            data={freqData}
            isError={freqError}
            isLoading={freqLoading}
            mutation={freqMutation}
          />
          <InterestsForm
            data={interestData}
            isError={interestError}
            isLoading={interestLoading}
            mutation={interestMutation}
          />
          <PauseMembershipForm mutation={snoozeMutation} />
        </div>

        <div className={`rounded-xl w-auto h-auto pt-2 bg-white  fade-in `}>
          <p className="text-xs mt-2 cursor-default select-none">
            <span
              onClick={(event) => {
                if (
                  !(
                    (auth.token != null && auth.token.group === 'Company') ||
                    isDisabledForViewAs(auth)
                  )
                ) {
                  mutation.mutate({ snooze: '999999' });
                }
              }}
              className={`text-xs underline text-center cursor-pointer`}>
              Permanently stop marketing
            </span>
            : In order to fulfil our contractual obligations towards you we’ll
            still send you important notifications that relate to your account
            or the investments you hold. It may take up to 24 hours for our
            systems to register this change.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={'flex flex-col items-center justify-center'}>
      <PermPopUp date={data.snooze} mutation={mutationResub} />
    </div>
  );
}

export default PreferenceForm;
