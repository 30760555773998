import React from 'react';

type PageTitleProps = {
  title: string;
  subtitle?: string;
  hideHr?: boolean;
};

const PageTitle = (props: PageTitleProps) => {
  return (
    <div className={'w-[100%] pb-3'}>
      <h6 className={'title text-sm text-bond cursor-default select-none'}>
        {props.title}
      </h6>
      <h3 className={'select-none pt-1'}>{props.subtitle}</h3>
      {!props.hideHr && <hr className="border-1 border-midas mt-3"></hr>}
    </div>
  );
};

export default PageTitle;
