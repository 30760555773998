
import React, {useEffect, useState} from 'react';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {useDependencyInjector} from '../context/DependencyInjector';
import LoginForm from '../components/Login/LoginForm';
import PasswordReset from '../components/Login/PasswordReset';
import useRedirect from '../hooks/useRedirect';
import {SpinnerCircularSplit} from 'spinners-react';
import ErrorMessage from "../errors/ErrorMessage";
import useToken from "../hooks/useToken";


function AppLoginComponent() {
    const {securityService} = useDependencyInjector();
    const {authService} = useDependencyInjector();

    const [passwordReset, setPasswordReset] = useState<boolean>(false);
    const [forceUserName, setForceUserName] = useState<string | null>();
    const [loginParaText = "When you log in, your application is automatically prefilled with information you have previously provided, saving time."] = useState<string | null>();

    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);

    const { setToken } = useToken();

    const redirect = useRedirect();

    const nav = useNavigate();


    /**
     * When forgot is in the search param ensure the component is rendered with the password reset screen showing
     */
    function initiateForgotPasswordFromSearchParams(params: URLSearchParams) {
        const forgot = params.get('forgot');
        if (params.has('forgot') && forgot === 'true') {
            setPasswordReset(true);
        } else {
            setPasswordReset(false);
        }
    }

    function handleForceUserName(params: URLSearchParams) {
        if (params.has('username')) {
            const username = params.get('username');
            if (username) {
                setForceUserName(username);
            }
        }
    }

    function removeRedirectSearchParams(params: URLSearchParams) {
        params.delete('username');
        params.delete('token');
        params.delete('redirect');
        nav('?' + createSearchParams(params).toString());
    }

    function handleLoginWithRedirect(params: URLSearchParams) {
        if (params.has('redirect')) {
            const url = params.get('redirect');
            if (url) {
                setLoading(true);
                securityService.validateUrlForRedirect(url)
                    .then(result => {
                        if (result) {
                            handleForceUserName(params);
                            redirect.storeRedirect(url);
                            removeRedirectSearchParams(params);
                        }
                    }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    function handleLoginWithToken(params: URLSearchParams) {
        if (params.has('token')) {
            const token = params.get('token');
            if (token) {
                setLoading(true);

                authService.loginWithToken(token)
                    .then((user) => {
                        setToken(user);
                        if (user.group == 'Company') {
                            scrollTo(0, 0);
                            nav('/Search');
                            return;
                        }
                        scrollTo(0, 0);
                        nav('/dashboard');
                    })
                    .catch((reason) => {
                        console.log(reason as ErrorMessage);
                    });
            }
        }
    }


    useEffect(() => {
        initiateForgotPasswordFromSearchParams(searchParams);
        handleLoginWithRedirect(searchParams);
        handleLoginWithToken(searchParams)
    }, [searchParams]);


    return (
        <div className="flex justify-center align-middle items-center fade-in">
            <div
                className="text-center mt-0 flex flex-col justify-center align-middle items-center w-full pt-4 pl-2">

                {loading && <SpinnerCircularSplit className={"w-10 h-10 "} color={'#BBAC79'} thickness={150}/>}

                {!loading &&
                    <div className="flex flex-col bg-white p-0 text-left w-[90vw] lg:w-[65vw] xl:w-[35vw]">
                        {passwordReset ? (
                            <PasswordReset setPasswordReset={setPasswordReset}/>
                        ) : (
                            <LoginForm setPasswordReset={setPasswordReset} forceUserName={forceUserName} loginParaText={loginParaText} loginCaptionText={"Log in - faster application"}/>
                        )}
                    </div>}
            </div>
        </div>
    );
}

export default AppLoginComponent;
