import tableConfigData from '../config/TableColumnsConfig.json';
import { TableConfig } from '../config/Config';


function useTableConfig() {

  const getConfig = (key: string | undefined) => {
    const data = JSON.parse(JSON.stringify(tableConfigData));
    const DEFAULT_FALLBACK_KEY = 'default';
    if (key === undefined) {
      return data[DEFAULT_FALLBACK_KEY] as TableConfig;
    }

    if (data[key] != undefined) {
      return data[key] as TableConfig;
    }

    return data[DEFAULT_FALLBACK_KEY] as TableConfig;
  };
  return { getConfig };
}

export default useTableConfig;
