import React from 'react';
import { ReactComponent as DocumentIcon } from '../../images/icons/icon-document.svg';
import { formatStringToDate } from '../../utils/DateUtils';
import { useDependencyInjector } from '../../context/DependencyInjector';
import FileDescriptor from '../../dto/FileDescriptor';
import fileDownload from 'js-file-download';

export type FileTableComponentProps = {
  files: Array<FileDescriptor>;
};

const FileTableComponent = (props: FileTableComponentProps) => {
  const { documentService } = useDependencyInjector();

  const downloadFile = async (fileDesc: FileDescriptor) => {
    if (fileDesc.id) {
      try {
        const response = await documentService.getDocument(fileDesc.id);
        fileDownload(response.media as Blob, response.fileName);
      } catch (error) {
        console.error("Failed to download the document", error);
      }
    } else {
      console.warn("File ID is missing, cannot download the document");
    }
  };

  const renderRows = () => {
    const rows = [];

    for (let i = 0; i < props.files.length; i++) {
      rows.push(
        <tr className="text-tuxedo border-b border-[#f3f3f1]" key={`table-row-${i}`}>
          <td className="w-[30%]">
            <div
              onClick={async () => {
                await downloadFile(props.files[i]);
              }}
              className="flex flex-row items-center hover:underline cursor-pointer pt-3 align-middle">
              <DocumentIcon className="h-[25px] fill-tuxedo" />
              <p
                className="ml-2 text-sm text-bond underline truncate max-w-[150px] md:max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                title={props.files[i].name}
              >
                {props.files[i].name}
              </p>
            </div>
          </td>
          <td className="pt-3">
            {props.files[i].type}
          </td>
          <td className="pt-3">
            {formatStringToDate(props.files[i].createDate)}
          </td>
          <td className="pt-3">
            {'.' +
              props.files[i].extension +
              '/' +
              Math.round(props.files[i].size / 1000) / 10 +
              'MB'}
          </td>
        </tr>
      );
    }
    return rows;
  };

  return (
    <table className="w-full h-auto text-left select-none mt-4">
      <thead>
      <tr className="w-full">
        <th className="w-[20%] pb-4">Name of document</th>
        <th className="w-[20%] pb-4">Type/Tag</th>
        <th className="w-[20%] pb-4">Uploaded</th>
        <th className="w-[20%] pb-4">Format/Size</th>
      </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
};

export default FileTableComponent;