import React from 'react';
import ButtonComponent from '../Buttons/Button';
import CTABox from '../CallToAction/CTABox';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useQuery } from '@tanstack/react-query';
import { CtaLocation } from '../../services/CtaService';
import { AxiosError } from 'axios';

function TrendingOffersBox() {
  const {ctaService} = useDependencyInjector()


  const {  data } = useQuery({
    queryKey: ['investmentCta'],
    staleTime:Infinity,
    queryFn:
      async () => {
        try {
          return await ctaService.getCta(CtaLocation.PORTFOLIO_INVESTMENT);
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              return undefined;
            }
          }
          throw error;
        }
      },
  });



  return (
    <div className="flex md:flex-row flex-wrap min-h-[40%] h-auto mt-[2%] mb-[2%] justify-around flex-col">
      {data != undefined &&
        <CTABox
          title={data.header}
          subtitle={data.body}
          buttonText={data.buttonLabel}
          onclick={() => {
            window.location.href = data?.buttonUrl;
          }}
        />
      }
    </div>
  );
}

export default TrendingOffersBox;
