import React from 'react';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { OptOutMutation } from './Mut';
import moment from 'moment';
import { useDependencyInjector } from '../../context/DependencyInjector';

import ButtonComponent from '../Buttons/Button';
import { Button } from 'private-wc-ui';


interface PermPopUpProps {
  date: Date | null;
  mutation: UseMutationResult<void, unknown, OptOutMutation, unknown>
}

function PermPopUp(props: PermPopUpProps) {

  const checkIfPermOptOut = (date: Date | null) => {
    if (!date) {
      return false;
    }
    const given = moment(date);
    const current = moment().startOf('day');
    return moment.duration(given.diff(current)).asMonths() > 13;
  };


  return (


    <div className={'bg-white text-left w-full rounded-xl shadow-lg p-8'}>
      <h4 className="mb-4">Marketing preferences</h4>
      <div
        className={`w-full h-full flex justify-center align-middle items-center fade-in`}>
        <div
          className="h-[20%] w-[70%] flex flex-col justify-center align-middle items-center text-center gap-2">
          <h6>You have opted out of receiving marketing
            communications {checkIfPermOptOut(props.date) ? 'permanently' : ' until ' + moment(props.date).format('DD-MM-YYYY')}.</h6>

          <Button
            className="block"
            onClick={() => {
              props.mutation.mutate({ snooze: null });
            }}
            style={{ width: 'auto' }}
            label="Resubscribe"
            theme={'primary'}
            type={'button'}
          />
        </div>
      </div>

    </div>


  )
    ;
}

export default PermPopUp;
