import api from "./api";

export type fileAttachment = { mime: string; fileName: string; content: string }

interface ITransactionService {
    downloadTransactionReport(tax_year: string): Promise<fileAttachment>;
}

export class TransactionService implements ITransactionService {
    downloadTransactionReport(tax_year: string): Promise<fileAttachment> {
        return new Promise<fileAttachment>((resolve, reject) => {
            api.get("/client/transactions/download" + (tax_year == '' ? "" : "?tax_year=" + tax_year)).then(value => {
                resolve(value.data as fileAttachment)
            }).catch(reason => {
                reject(reason)
            })
        })
    }
}
