import axios from 'axios';
import { getApiBaseUrl } from '../utils/HelperUtils';

export interface IUserService {
  hasUserAssociated(wcid: string): Promise<boolean>;
}

export class UserService implements IUserService {
  hasUserAssociated(wcid: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .get(getApiBaseUrl() + '/user/' + wcid)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            resolve(false);
          } else {
            reject(error);
          }
        });
    });
  }
}
