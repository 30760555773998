import React from "react";

const AimIhtWarning = () => {

    return (
        <><b>IMPORTANT</b>: we do not currently have an up-to-date valuation from your provider. Please contact us for
            your latest valuation on <a href="tel:01179290511" className="font-bold text-bond">0117 9290511</a>. We are
            sorry for the inconvenience.
        </>
    )
}

export default AimIhtWarning