import React, { useMemo } from 'react';
import { Account } from '../../dto/Account';
import { NonCustodyHolding } from '../../dto/Holding';
import { formatMoney, formatNum } from '../../utils/FormattingUtils';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { guard } from '../../utils/HelperUtils';
import { formatStringToDate } from '../../utils/DateUtils';
import useTableConfig from '../../hooks/useTableConfig';
import { useParams } from 'react-router-dom';
import { TableConfig } from '../../config/Config';
import { Tooltip } from 'react-tooltip';

type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

interface InvestmentProps {
  holding: NonCustodyHolding;
  setSideBar: (value: React.SetStateAction<SideBarContent>) => void;
  key: number;
  childrenInvestments: NonCustodyHolding[];
  isDfm: boolean;
}

function MobileInvestmentBlock(props: InvestmentProps) {

  const config = useTableConfig();

  const { child, cat } = useParams();

  const retrieveConfig = (child: string | undefined, cat: string | undefined): TableConfig => {
    return child === undefined ? config.getConfig(cat) : config.getConfig(child);
  };


  const tableConfig = useMemo(() => retrieveConfig(child, cat), [child, cat]);


  return (
    <div>
      <div
        className={`w-full h-auto border-2 border-midasLight mt-4 flex flex-col p-4 ${
          props.holding.phase === 20 || props.holding.phase === null ? 'bg-white' : 'bg-[#eeedeb]'
        } `}>
        <h4
          className={`${
            props.holding.phase === 20
              ? `text-bond ${!props.isDfm ? 'cursor-pointer underline' : ''}`
              : 'text-tuxedo cursor-default'
          } ${tableConfig.levelOne.investment.name ? "visible" : "hidden"}`}
          onClick={() => {
            guard(!props.isDfm, () => {
              scrollTo(0, 0);
              props.setSideBar({
                holding: props.holding,
                openType: 'INVESTMENT',
              });
            });
          }}>
          {props.holding.investment.name}
        </h4>
        <hr className="border-1 border-midasLight mt-4 w-[95%] ml-[2.5%]"></hr>
        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.cost ? "visible" : "hidden"}`}>
          <h5 className="text-sm">Cost:</h5>
          <div className="font-normal ml-2 text-sm">
            {formatMoney(
              props.holding.cost,
            )}
          </div>
        </div>
        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.value ? "visible" : "hidden"}`}>
          <h5 className="text-sm">Value:</h5>
          <div className="font-normal ml-2 text-sm" data-cy="investmentValueMobile">
            <Tooltip id={`${props.holding.id}-tooltip-value-tooltip`} />
            <a data-tooltip-id={`${props.holding.id}-tooltip-value-tooltip`} data-tooltip-content={`${props.holding.value}`}>{formatMoney(props.holding.value, props.holding.currency)}</a>
          </div>
        </div>
        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.growth ? "visible" : "hidden"}`}>
          <h5 className="text-sm">Growth:</h5>
          <div className="font-normal ml-2 text-sm">
            {formatMoney(props.holding.growth)}
          </div>
        </div>
        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.growthPercentage ? "visible" : "hidden"}`}>
          <h5 className="text-sm">Growth (%):</h5>
          <div className="font-normal ml-2 text-sm">
            {formatNum(props.holding.growthPercentage)}
          </div>
        </div>

        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.units ? 'visible' : 'hidden'}`}>
          <h5 className="text-sm">Units:</h5>
          <h5 className="font-normal ml-2 text-sm">
            <Tooltip id={`${props.holding.id}-tooltip-units-tooltip`} />
            <a data-tooltip-id={`${props.holding.id}-tooltip-units-tooltip`} data-tooltip-content={`${props.holding.units}`}> {formatNum(props.holding.units)}</a>

          </h5>
        </div>
        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.unitPrice ? 'visible' : 'hidden'}`}>
          <h5 className="text-sm">Units price:</h5>
          <h5 className="font-normal ml-2 text-sm">
            <Tooltip id={`${props.holding.id}-tooltip-unit-price-tooltip`} />
            <a data-tooltip-id={`${props.holding.id}-tooltip-unit-price-tooltip`} data-tooltip-content={`${props.holding.unitPrice}`}>{formatNum(props.holding.unitPrice)}</a>
          </h5>
        </div>
        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.lastValuedAt ? 'visible' : 'hidden'}`}>
          <h5 className="text-sm">Last valued at:</h5>
          <h5 className="font-normal ml-2 text-sm">
            {formatStringToDate(props.holding.lastValuedAt)}
          </h5>
        </div>
        <div
          className={`flex flex-row align-middle items-center mt-2 ${tableConfig.levelOne.invDate ? 'visible' : 'hidden'}`}>
          <h5 className="text-sm">Investment Date:</h5>
          <div className="font-normal ml-2 text-sm">
            {formatStringToDate(props.holding.invDate)}
          </div>
        </div>
        <div
          className={`text-grey font-normal text-xs mt-4 ${
            props.holding.phase == 10 ? 'visible' : 'hidden'
          }`}>
          <i>
           Pending allocation.
          </i>
        </div>
        <div
          className={`text-grey font-normal text-xs mt-4 ${
            props.holding.phase == 30 ? 'visible' : 'hidden'
          }`}>
          <i>
            Closed.
          </i>
        </div>
        <div
          className={`text-grey font-normal text-xs mt-4 ${
            props.holding.phase == 40 ? 'visible' : 'hidden'
          }`}>
          <i>
            Archived.
          </i>
        </div>
        <div
          onClick={() => {
            scrollTo(0, 0);
            props.setSideBar({
              holding: props.holding,
              openType: 'INVESTMENT',
            });
          }}
          className={`w-full h-[5vh] bg-midasLight mt-4 flex items-center justify-center hover:cursor-pointer 
           ${props.isDfm ? 'hidden' : ''}`}>
          <h4 className="text-sm">View details</h4>
        </div>
        <div
          onClick={() => {
            scrollTo(0, 0);
            props.setSideBar({
              holding: props.holding,
              openType: 'DOCS',
            });
          }}
          className={`w-full h-[5vh] bg-midasLight mt-4 flex items-center justify-center hover:cursor-pointer 
           ${props.isDfm ? 'hidden' : ''}`}>
          <h4 className="text-sm">Related documents</h4>
        </div>
      </div>
    </div>
  );
}

export default MobileInvestmentBlock;