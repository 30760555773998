import React from 'react';
import { Navigate } from 'react-router-dom';
import useToken from '../hooks/useToken';

export interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
  const { token } = useToken();


  if (!token) {
    return <Navigate to="/login" replace />;
  }


  return props.children;
};
export default ProtectedRoute;
