
import { Token } from '../hooks/useToken';

export function passwordRegexValidator(password: string): boolean {
  const regex = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\\w\\s]).{8,}$/,
  );
  return regex.test(password);
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function getApiBaseUrl() {
  if (
    window.location.host.includes('localhost') ||
    window.location.host.includes('192.') ||
    window.location.host.includes('lux')
  ) {
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':8080' +
      '/app-portal/api'
    );
  } else {
    return window.location.protocol + '//' + window.location.host + '/api';
  }
}



export function isDisabledForViewAs(token: Token | null): boolean {
  if (token == null) {
    return false;
  }
  return (token.token != null && token.token.as != undefined);
}

export function guard(enabled:boolean, func:() => void){
  if (enabled){
    func()
  }
}

export function translateHoldingPhase(phase:number):string{
  switch (phase){
    case 20:
      return "Active"
    case 10:
      return "Pending"
    case 30:
      return "Closed"
    case 40:
      return "Archived"
    default:
      // fall back go with active
      return "Active"
  }
}
