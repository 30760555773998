import React from 'react';

interface ToastProps {
  message: string;
  show: boolean;
  closeFn?: () => void;
}

function ErrorToast(props: ToastProps) {

  return (
    <>
      {props.show && <div id="toast-simple" onClick={props.closeFn}
                           className={'absolute bottom-2 right-1 bg-beret flex items-center w-full justify-between text-gray-500 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 md:max-w-[15%] p-4 max-w-[80%]'}
                           role="alert">

        <div className="ps-4 text-sm font-normal">{props.message}</div>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
             onClick={props.closeFn}
             className="w-[25px] cursor-pointer">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>

      </div>}
    </>

  );
}

export default ErrorToast;

