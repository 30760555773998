import React from 'react';
import InvestmentTemplate from './InvestmentPageTemplate';

function PortfolioOverview() {
  return (
      <InvestmentTemplate></InvestmentTemplate>
  );
}

export default PortfolioOverview;
