import React from 'react';

export function AttachmentLoader() {
  return (
    <>
      <div className="border-2 border-vistaWhite w-full h-[15vh] mt-6 flex flex-row animate-pulse">
        <div className="flex flex-row ml-4 mt-3 md:mt-7 ">
          <div className="w-[60px] h-[60px] rounded-lg animate-pulse bg-vistaWhite blur-sm"></div>
          <div className="flex flex-col ml-2">
            <div className="w-[140px] h-[15px] rounded-lg animate-pulse bg-vistaWhite opacity-75 blur-sm"></div>
            <div className="w-[140px] h-[15px] mt-4 rounded-lg animate-pulse bg-vistaWhite blur-sm"></div>
            <div className="w-[140px] h-[15px] mt-4 rounded-lg animate-pulse bg-vistaWhite opacity-75 blur-sm"></div>
          </div>
        </div>
        <div className="flex flex-row ml-4 mt-8 hidden md:visible">
          <div className="w-[60px] h-[80px] rounded-lg animate-pulse bg-vistaWhite blur-sm"></div>
          <div className="flex flex-col ml-2">
            <div className="w-[140px] h-[15px] rounded-lg animate-pulse bg-vistaWhite opacity-75 blur-sm"></div>
            <div className="w-[140px] h-[15px] mt-4 rounded-lg animate-pulse bg-vistaWhite blur-sm"></div>
            <div className="w-[140px] h-[15px] mt-4 rounded-lg animate-pulse bg-vistaWhite opacity-75 blur-sm"></div>
          </div>
        </div>
        <div className="flex flex-row ml-4 mt-8 hidden md:visible">
          <div className="w-[60px] h-[80px] rounded-lg animate-pulse bg-vistaWhite blur-sm"></div>
          <div className="flex flex-col ml-2">
            <div className="w-[140px] h-[15px] rounded-lg animate-pulse bg-vistaWhite opacity-75 blur-sm"></div>
            <div className="w-[140px] h-[15px] mt-4 rounded-lg animate-pulse bg-vistaWhite blur-sm"></div>
            <div className="w-[140px] h-[15px] mt-4 rounded-lg animate-pulse bg-vistaWhite opacity-75 blur-sm"></div>
          </div>
        </div>
      </div>
    </>
  );
}
