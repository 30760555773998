import { NonCustodyHolding } from '../dto/Holding';
import { translateHoldingPhase } from './HelperUtils';
import moment from 'moment';

const sortInvestmentsByPhaseAndInvDate = (
  a: NonCustodyHolding,
  b: NonCustodyHolding,
) => {
  const ret = translateHoldingPhase(a.phase).localeCompare(
    translateHoldingPhase(b.phase),
  );
  if (ret == 0) {
    if (moment(a.invDate) > moment(b.invDate)) {
      return 1;
    } else if (moment(a.invDate) < moment(b.invDate)) {
      return -1;
    }
  }
  return ret;
};

const sortByInvestmentName = (a: NonCustodyHolding, b: NonCustodyHolding) => {
return a.investment.name.localeCompare(b.investment.name)
};

export { sortInvestmentsByPhaseAndInvDate, sortByInvestmentName };
