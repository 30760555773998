
import React, {useEffect, useState} from 'react';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import useRedirect from '../hooks/useRedirect';
import {SpinnerCircularSplit} from 'spinners-react';
import {useDependencyInjector} from "../context/DependencyInjector";
import NewUserForm from "../components/Login/NewUserForm";

function NewUserComponent() {
    const [newUserParaText = "This text also will be created by Marta, who will make it quite encouraging to log in.<br>\n" +
        "After you've completed the application form, we'll automatically create an account for you.\n" +
        "This will make completing any further applications much quicker, as you won't have to type your personal details again.\n" +
        "<br>\n" +
        "<br>\n" +
        "Please note: this investment is only available to UK residents able to self-certify as Sophisticated Investors or High Net Worth Individuals\n" +
        "before investing. If you are in any doubt, please seek advice. Lorem ipsum..."] = useState<string | null>();

    const {securityService} = useDependencyInjector();

    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);

    const redirect = useRedirect();

    const nav = useNavigate();


    function removeRedirectSearchParams(params: URLSearchParams) {
        params.delete('redirect');
        nav('?' + createSearchParams(params).toString());
    }

    function handleNewUser(params: URLSearchParams) {
        if (params.has('redirect')) {
            const url = params.get('redirect');
            if (url) {
                setLoading(true);
                securityService.validateUrlForRedirect(url)
                    .then(result => {
                        if (result) {
                            redirect.storeRedirect(url);
                            removeRedirectSearchParams(params);
                        }
                    }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }


    useEffect(() => {
        handleNewUser(searchParams);
    }, [searchParams]);


    return (
        <div className="flex justify-center align-middle items-center fade-in">
            <div
                className="text-center mt-0 flex flex-col justify-center align-middle items-center w-full pt-4 pl-2">

                {loading && <SpinnerCircularSplit className={"w-10 h-10 "} color={'#BBAC79'} thickness={150}/>}

                {!loading &&
                    <div className="flex flex-col bg-white p-0 text-left w-[90vw] lg:w-[65vw] xl:w-[35vw]">
                            <NewUserForm newUserParaText={newUserParaText}/>
                    </div>}
            </div>
        </div>
    );
}

export default NewUserComponent;
