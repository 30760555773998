import { CtaDto } from '../dto/CtaDto';
import api from './api';

export enum CtaLocation {
  DASHBOARD_ONE = 'DASHBOARD_ONE',
  DASHBOARD_TWO = 'DASHBOARD_TWO',
  PORTFOLIO_INVESTMENT = 'PORTFOLIO_INVESTMENT'
}

export interface ICtaService {
  getCta(location: CtaLocation): Promise<CtaDto>;
}


export class CtaService implements ICtaService {
  getCta(location: CtaLocation): Promise<CtaDto> {
    return new Promise((resolve, reject) => {
      api.get('/cta/' + location)
        .then(value => {
          resolve(value.data as CtaDto);
        }).catch(reason => {
        reject(reason);
      });
    });
  }
}