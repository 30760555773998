import React from 'react';
import { SecureMessage } from '../../dto/SecureMessage';
import { ReactComponent as ReplyIcon } from '../../images/icons/icon-reply.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/icon-delete.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/icon-close.svg';
import { useNavigate } from 'react-router-dom';

interface TopBarProps {
  secureMessage: SecureMessage | undefined;
  setSidebar: (arg: boolean) => void;
  deleteCurrentMessage: (secureMessageId: string) => void;
  folder: string;
}

function ViewTopBar(props: TopBarProps) {
  const nav = useNavigate();
  return (
    <div className="h-[4vh] mt-4 flex flex-row">
      {props.secureMessage && (
        <div className="w-[90%] flex flex-row gap-4">
          <div
            className={`flex flex-row items-center cursor-pointer ${
              props.folder == 'inbox' ? 'visible' : 'hidden'
            }`}
            onClick={() => {
              props.setSidebar(false);
              nav('/reply/' + props.secureMessage!.id);
            }}>
            <ReplyIcon className="h-[18px] fill-bond"></ReplyIcon>
            <div className="text-bond text-sm font-normal underline">Reply</div>
          </div>
          <div
            className={`flex flex-row items-center cursor-pointer ${
              props.folder == 'deleted' ? 'hidden' : ''
            }`}
            onClick={() => {
              if (props.secureMessage?.id) {
                props.deleteCurrentMessage(props.secureMessage!.id);
              }
            }}>
            <DeleteIcon className="h-[18px] cursor-pointer fill-bond"></DeleteIcon>
            <div className="text-bond text-sm font-normal underline">Delete</div>
          </div>
        </div>
      )}

      <div className="w-[10%] h-full" onClick={() => props.setSidebar(false)}>
        <CloseIcon
          title="Close Message"
          className="h-[18px] cursor-pointer fill-bond mt-[11%] ml-[50%]"></CloseIcon>
      </div>
    </div>
  );
}

export default ViewTopBar;
